import { Fragment, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Utah } from '../../../components/utah';
import { Tooltip } from 'flowbite-react';
import { HeroIcon } from '../../../components/hero-icon';
import { useCreate, useIndex } from '../../../shared/use-rest';
import { ChatAltIcon, TagIcon, UserCircleIcon } from '@heroicons/react/solid';
import { classNames } from '../../../shared/class-names';


export function AdminEvents() {
  const refreshWhenHidden = (process.env.ENV == 'dev' && process.env.ENABLE_REALTIME == 'true') || false
  const { data: events, mutate: eventsMutate } = useIndex('events', '', {refreshInterval: 250, refreshWhenHidden: refreshWhenHidden})

  return (
    <div className="p-6">
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-200 flex flex-row">
        <HeroIcon icon="SpeakerphoneIcon" className="h-5 mr-2 self-center" />
        Events
      </h5>
      <div className="flow-root mt-5">
        <ul role="list" className="-mb-8">
          {events.map((event, eventIdx) => (
            <li key={event.id}>
              <div className="relative pb-8">
                {eventIdx !== events.length - 1 ? (
                  <span
                    className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200  dark:bg-gray-600"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex items-start space-x-3">
                  {event.name === "PatientCommentCreate" ? (
                    <>
                      <div className="relative">
                        <img
                          className="h-10 w-10 rounded-full bg-gray-400 dark:bg-gray-600 flex items-center justify-center ring-8 ring-white"
                          src={event?.imageUrl}
                          alt=""
                        />

                        <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                          <ChatAltIcon
                            className="h-5 w-5 text-gray-400 dark:text-gray-700"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <div className="min-w-0 flex-1">
                        <div>
                          <div className="text-sm">
                            <div
                              className="font-medium text-gray-900 dark:text-gray-200"
                            >
                              {event.created_by?.name}
                            </div>
                          </div>
                          <p className="mt-0.5 text-sm text-gray-500 dark:text-gray-400">
                            Commented {event.date} on{" "}
                            <Link
                              className="text-gray-700"
                              to={`/patients/${event.meta.patient_id}`}
                            >
                              a patient
                            </Link>
                            <span className="whitespace-nowrap">
                              {" "}
                              at {event.created_at}
                            </span>
                          </p>
                        </div>
                      </div>
                    </>
                  ) : event.type === "assignment" ? (
                    <>
                      <div>
                        <div className="relative px-1">
                          <div className="h-8 w-8 bg-gray-100  dark:bg-gray-600 rounded-full ring-8 ring-white flex items-center justify-center">
                            <UserCircleIcon
                              className="h-5 w-5 text-gray-500 dark:text-gray-400  "
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="min-w-0 flex-1 py-1.5">
                        <div className="text-sm text-gray-500 dark:text-gray-400">
                          <a
                            href={event.person.href}
                            className="font-medium text-gray-900 dark:text-gray-200"
                          >
                            {event.person.name}
                          </a>{" "}
                          assigned{" "}
                          <a
                            href={event.assigned.href}
                            className="font-medium text-gray-900 dark:text-gray-200"
                          >
                            {event.assigned.name}
                          </a>{" "}
                          <span className="whitespace-nowrap">
                            {event.date}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : event.type === "tags" ? (
                    <>
                      <div>
                        <div className="relative px-1">
                          <div className="h-8 w-8 bg-gray-100  dark:bg-gray-600 rounded-full ring-8 ring-white flex items-center justify-center">
                            <TagIcon
                              className="h-5 w-5 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="min-w-0 flex-1 py-0">
                        <div className="text-sm leading-8 text-gray-500 dark:text-gray-400">
                          <span className="mr-0.5">
                            <a
                              href={event.person.href}
                              className="font-medium text-gray-900 dark:text-gray-200"
                            >
                              {event.person.name}
                            </a>{" "}
                            added tags
                          </span>{" "}
                          <span className="mr-0.5">
                            {event.tags.map((tag) => (
                              <Fragment key={tag.name}>
                                <a
                                  href={tag.href}
                                  className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm"
                                >
                                  <span className="absolute flex-shrink-0 flex items-center justify-center">
                                    <span
                                      className={classNames(
                                        tag.color,
                                        "h-1.5 w-1.5 rounded-full"
                                      )}
                                      aria-hidden="true"
                                    />
                                  </span>
                                  <span className="ml-3.5 font-medium text-gray-900 dark:text-gray-200">
                                    {tag.name}
                                  </span>
                                </a>{" "}
                              </Fragment>
                            ))}
                          </span>
                          <span className="whitespace-nowrap">
                            {event.date}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <div className="relative px-1">
                          <div className="h-8 w-8 bg-gray-100 dark:bg-gray-600 rounded-full ring-8 ring-white dark:ring-gray-600 flex items-center justify-center">
                            <UserCircleIcon
                              className="h-5 w-5 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="min-w-0 flex-1 py-1.5">
                        <div className="text-sm text-gray-500 dark:text-gray-400">
                          <a href="#" className="font-medium text-gray-900 dark:text-gray-200">
                            {event.name}
                          </a>
                          <span> by </span>
                          <div className="inline font-medium text-gray-900 dark:text-gray-200">
                            {event.created_by?.name || "Unknown User"}
                          </div>
                          <span className="whitespace-nowrap">
                            {" "}
                            at {event.created_at}
                          </span>
                          <pre>{JSON.stringify(event.meta, null, 2) }</pre>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
