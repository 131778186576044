import {
  DataGridPro,
  type GridCellParams,
  type GridColDef,
} from "@mui/x-data-grid-pro"
import { HeroIcon } from "../hero-icon"

interface Props {
  criteria: []
  questionIsExclusion: object
}

export function ScreenQualificationCriteriaTable(props: Props) {
  const columnDefaults: Partial<GridColDef> = {
    width: 150,
    headerClassName: "bg-gray-100 text-gray-500",
    display: "flex",
  }

  const columns: GridColDef[] = [
    {
      field: "status",
      headerName: "",
      ...columnDefaults,
      width: 50,
      renderCell: renderCellStatus,
      valueGetter: (value, row, column, apiRef) =>
        valueGetterStatus(row?.criterion, props.questionIsExclusion),
    },
    { field: "term", headerName: "Term", ...columnDefaults },
    {
      field: "evidence",
      headerName: "Evidence",
      ...columnDefaults,
      width: 380,
    },
    {
      field: "evidence_order",
      headerName: "Evidence Order",
      ...columnDefaults,
    },
    {
      field: "evidence_value",
      headerName: "Evidence Value",
      ...columnDefaults,
      width: 300,
    },
    {
      field: "evidence_date",
      headerName: "Reported Date",
      ...columnDefaults,
      width: 155,
    },
    { field: "found_date", headerName: "Found On", ...columnDefaults },
    {
      field: "data_source",
      headerName: "Data Source",
      ...columnDefaults,
      flex: 1,
    },
  ]

  return (
    <div className="w-full">
      <DataGridPro
        rows={props.criteria}
        getRowHeight={() => "auto"}
        autoHeight
        pagination
        columns={columns}
        disableDensitySelector={true}
        initialState={{
          columns: {
            columnVisibilityModel: {
              // Hide these columns by default, the other columns will remain visible
              evidence_order: false,
            },
          },
          filter: {
            filterModel: {
              items: [],
            },
          },
          sorting: {
            sortModel: [
              { field: "status", sort: "desc" },
              { field: "evidence_order", sort: "asc" },
            ],
          },
          pagination: {
            paginationModel: { pageSize: 5 },
          },
          density: "comfortable", // contact
        }}
        sx={{
          "& .MuiDataGrid-cell": {
            whiteSpace: "normal",
            wordWrap: "break-word",
            padding: "10px",
          },
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          ".dark & .MuiDataGrid-columnHeader": {
            backgroundColor: "rgb(55 65 81)",
            color: "#FFFFFF",
          },
        }}
      />
    </div>
  )
}

const valueGetterStatus = (criterion, questionIsExclusion) => {
  if (
    criterion?.evidence &&
    criterion?.question_id &&
    !questionIsExclusion(criterion.question_id)
  )
    return 3
  if (
    criterion?.evidence &&
    criterion?.question_id &&
    questionIsExclusion(criterion.question_id)
  )
    return 2
  if (criterion?.evidence && !criterion?.question_id) return 1
  if (!criterion?.evidence) return 0
}

const renderCellStatus = (params: GridCellParams) => (
  <div className="flex pl-2 justify-center">
    {params.value === 3 && (
      <HeroIcon icon="CheckCircleIcon" className={"h-4 text-green-800"} />
    )}
    {params.value === 2 && (
      <HeroIcon icon="ExclamationCircleIcon" className={"h-4 text-red-700"} />
    )}
    {params.value === 1 && (
      <HeroIcon
        icon="InformationCircleIcon"
        className={"h-4 text-yellow-400"}
      />
    )}
    {params.value === 0 && (
      <HeroIcon icon="QuestionMarkCircleIcon" className={"h-4 text-gray-400"} />
    )}
  </div>
)
