interface EditorButtonProps {
  title: string
  onClickHandler
  isDisabled?: boolean
  isActive?: boolean
}

export default function EditorButton({
  title,
  onClickHandler,
  isDisabled = false,
  isActive = false,
}: EditorButtonProps) {
  return (
    <>
      <button
        type="button"
        onClick={() => onClickHandler()}
        disabled={isDisabled}
        className={`${
          isActive
            ? "bg-topo-green-500  hover:bg-topo-green-600 focus:bg-topo-green-700  focus:ring-topo-green-800"
            : isDisabled
              ? "bg-gray-500 cursor-not-allowed"
              : "bg-topo-blue-500  hover:bg-topo-blue-600 focus:bg-topo-blue-700  focus:ring-topo-blue-800"
        } focus:outline-none focus:ring-2focus:ring-offset-2 text-white text-xs font-medium m-1 px-2.5 py-1.5 rounded-md`}
      >
        {title}
      </button>
    </>
  )
}
