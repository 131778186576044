import { useAuth0 } from "@auth0/auth0-react"
import { Dispatch, SetStateAction, useContext, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { AuthorizationContext } from "../../shared/authorization-provider"
import { event } from "../../shared/event"
import { useCreate, useIndex, useShow, useUpdate } from "../../shared/use-rest"
import { useKeyPress } from "../../shared/useKeyPress"

interface Props {
  contactId: string
  patientId: string
  screenId: string
  setOpen: Dispatch<SetStateAction<boolean>>
  setContactModalEditingId: Dispatch<SetStateAction<string>>
  refreshData: unknown
}

export function ContactModal(props: Props) {
  const { getAccessTokenSilently } = useAuth0()
  const { screenId } = useParams()
  const { practiceId } = useContext(AuthorizationContext)

  const { data: contact } = useShow("contacts", props.contactId)
  const {
    data: screens,
    isLoading: isLoadingScreens,
    isError: isErrorScreens,
    mutate: mutateScreens,
  } = useIndex("screens", `patient_id=${props.patientId}`)

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      screen_id: screenId,
      patient_id: props.patientId,
      contact_type: "phone_call",
      contact_reason: "Phone Screen",
      contact_length: "",
      disposition: "Connected/Sent",
      contacted_at: new Date().toISOString().slice(0, -1),
    },
  })

  useEffect(() => {
    if (screenId) {
      reset({ screen_id: screenId })
    }
  }, [screenId, reset])

  useEffect(() => {
    if (props.contactId) {
      reset({
        screen_id: contact.screen_id,
        contact_type: contact.contact_type,
        contact_reason: contact.contact_reason,
        disposition: contact.disposition,
        contact_length: contact.contact_length,
      })
    }
  }, [props.contactId, contact, reset])

  const closeModal = () => {
    props.setContactModalEditingId("")
    props.setOpen(false)
  }

  useKeyPress(["Escape"], closeModal)

  const onSubmit = async (data) => {
    data.practice_id = practiceId

    if (props.contactId) {
      await useUpdate(
        "contacts",
        props.contactId,
        data,
        getAccessTokenSilently,
      ).then((response) => {
        event({
          eventName: "ContactSave",
          meta: {
            patient_id: props.patientId,
            screen_id: props.screenId,
            contact_id: response?.data?.data?.id,
          },
          getAccessTokenSilently,
        })
      })
    } else {
      await useCreate("contacts", data, getAccessTokenSilently).then(
        (response) => {
          event({
            eventName: "ContactSave",
            meta: {
              patient_id: props.patientId,
              screen_id: props.screenId,
              contact_id: response?.data?.data?.id,
            },
            getAccessTokenSilently,
          })
        },
      )
    }
    props.setOpen(false)
    props.setContactModalEditingId("")
    props?.refreshData()
    reset()
  }

  return (
    <>
      <div
        className="fixed flex left-0 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full"
        id="edit-practice-modal"
      >
        <div className="relative w-full h-full max-w-2xl px-4 md:h-auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <!-- Modal content --> */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
              {/* <!-- Modal header --> */}
              <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
                <h3 className="text-xl font-semibold dark:text-white">
                  {props.contactId === "" ? "Log" : "Edit"} Contact
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                  onClick={() => closeModal()}
                  onKeyUp={() => closeModal()}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-label="close button"
                  >
                    <title id="svg-title">Close button</title>
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <div className="p-6 space-y-6">
                <div className="grid grid-cols-6 gap-6">
                  <input type="hidden" {...register("patient_id")} />
                  <input type="hidden" {...register("contacted_at")} />
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="screen"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Study
                    </label>
                    <select
                      {...register("screen_id")}
                      name="screen_id"
                      id="screen_id"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    >
                      <option />
                      {screens?.map((screen) => (
                        <option value={screen?.id}>
                          {screen?.study?.abbreviation}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="contact_type"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Contact Type
                    </label>
                    <select
                      {...register("contact_type")}
                      name="contact_type"
                      id="contact_type"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    >
                      <option value="phone_call">Phone Call</option>
                      <option value="in_person">In Person</option>
                      <option value="sms">SMS</option>
                      <option value="email">Email</option>
                      <option value="postcard_letter">Postcard/Letter</option>
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="contact_reason"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Reason For Contact
                    </label>
                    <select
                      {...register("contact_reason")}
                      name="contact_reason"
                      id="contact_reason"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    >
                      <option>Phone Screen</option>
                      <option>Scheduling</option>
                      <option>Remote Consent</option>
                      <option>Appointment Reminder</option>
                      <option>Rescheduling Appointment</option>
                      <option>Study Outreach</option>
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="disposition"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Result
                    </label>
                    <select
                      {...register("disposition")}
                      name="disposition"
                      id="disposition"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    >
                      <option />
                      <option>Connected/Sent</option>
                      <option>Left Voicemail</option>
                      <option>Didn't Connect</option>
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="contact_type"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Length of Contact (minutes)
                    </label>
                    <select
                      {...register("contact_length")}
                      name="contact_length"
                      id="contact_length"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    >
                      <option />
                      <option>{"<1"}</option>
                      <option>1-5</option>
                      <option>5-10</option>
                      <option>10+</option>
                    </select>
                  </div>
                </div>
                {/* <!-- Modal footer --> */}
                <div className="items-center pt-4 border-t border-gray-200 rounded-b dark:border-gray-700">
                  <button
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        onClick={() => closeModal()}
        onKeyUp={() => closeModal()}
        id="modal-backdrop"
        className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
      />
    </>
  )
}
