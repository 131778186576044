import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Avatar } from '../avatar';
import { Tooltip } from 'flowbite-react';
import { HeroIcon } from '../hero-icon';
import { useCreate, useIndex } from '../../shared/use-rest';
import { event } from '../../shared/event';
import { friendlyRelative, friendlyTimeDate } from '../../shared/friendly-dates';
import { formatRelative, parseISO } from 'date-fns';

interface Props {
  text: string,
  status: string
}

export function StudyStatusChip(props: Props) {
  let color = ''
  switch (props.status) {
    case 'Contracting':
      color = 'gray'
      break;
    case 'Enrolling':
      color = 'green'
      break;
    case 'Active':
      color = 'topo-navy'
      break;
    case 'Closed':
      color = 'red'
      break;

    default:
      color = 'blue'
      break;
  }

  return (
    <span
        className={`bg-${color}-100 text-${color}-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md whitespace-nowrap hover:cursor-default`}>
        { props.text || "Unknown" }
    </span>
  )
}
