import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowsProp,
  MuiEvent,
} from "@mui/x-data-grid"
import { GridToolbar } from "@mui/x-data-grid-pro"
import { useQueryClient } from "@tanstack/react-query"
import { useRef, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { ActionHeader } from "../../../../components/action-header"
import { ButtonLeadingIcon } from "../../../../components/button-leading-icon"
import { useIndex } from "../../../../shared/use-rest"
import { useIndexQuery } from "../../../../shared/use-rest-query"
import { StudyEditModal } from "../edit-modal"
import { StudyNewSlideover } from "../new-slideover"
import { PracticeUsersDisplay, Visits } from "./display"

export function PracticeUsersAdmin() {
  return (
    <>
      <ActionHeader title="Practice Users" />
      <PracticeUsersDisplay />
    </>
  )
}
