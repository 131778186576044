import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"
import Fuse from "fuse.js"
import { type Dispatch, Fragment, type SetStateAction, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import type { KeyedMutator } from "swr"
import SearchableMultiSelect from "../../../../components/searchable-multi-select"
import { snakeToTitle, toSnakeCase } from "../../../../shared/text-utils"
import { useKeyPress } from "../../../../shared/useKeyPress"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  refreshData?: KeyedMutator<{ data: [] }>
  questions: []
  addQuestion: (fullText: string, questionType: string) => void
  addStudyQuestion: (id) => void
}

function createQuestion(data) {
  // TODO useCreate
  // TODO create question with full_text, returns questionId
  // addQuestion(questionId)
}

export function QuestionsSlideover(props: Props) {
  const { control, handleSubmit, reset, register, getValues, setValue } =
    useForm({
      defaultValues: {
        categories: [],
      },
    })
  const { setOpen, open, refreshData, questions } = props
  const [filteredQuestions, setFilteredQuestions] = useState([])
  const [selectedQuestion, setSelectedQuestion] = useState(null)
  const [studyQuestionCategories, setStudyQuestionCategories] = useState([])
  const [addingQuestion, setAddingQuestion] = useState(false)
  const [fullText, setFullText] = useState("")
  const [questionType, setQuestionType] = useState("boolean")
  const [searchText, setSearchText] = useState("")
  const handleChangeFilterText = (event) => {
    const fuse = new Fuse(questions, {
      keys: ["full_text"],
    })
    const result = fuse.search(event.target.value)
    setSearchText(event.target.value)
    setFilteredQuestions(result)
  }

  const handleQuestionClick = (item) => {
    // props.addQuestion(item?.id)
    // toast.success('Saved')
    setSelectedQuestion(item)
    // setFullText(item.full_text)
    // handleChangeFilterText({target: {value: ''}})
  }

  const handleClickNewQuestion = () => {
    setAddingQuestion(true)
    setFullText(searchText)
    setFilteredQuestions([])
  }

  const handleSaveQuestion = async () => {
    const response = await props.addQuestion(fullText, questionType)
    const question = response.data.data
    toast.success("Question Saved")
    setSelectedQuestion(question)
    setAddingQuestion(false)
    refreshData()
  }

  const handleClickAddToStudy = async (data) => {
    data.categories.sort((a, b) => a.localeCompare(b))

    // Convert categories from 'Title Case' to 'snake_case'
    const categories = data.categories.map(toSnakeCase)

    props.addStudyQuestion({
      question_id: selectedQuestion?.id,
      categories,
    })
    setAddingQuestion(false)
    setSelectedQuestion(null)
    reset()
  }

  useKeyPress(["Escape"], () => props.setOpen(false))

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={props.setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-400 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-400 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-2xl">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="flex-1">
                    <div
                      className="
                      bg-topo-navy px-4 py-6 sm:px-6"
                    >
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-100">
                            Questions
                          </Dialog.Title>
                          <p className="text-sm text-gray-300">
                            Click a question below to add it to the study.
                          </p>
                        </div>
                        <div className="flex h-7 items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={() => props.setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {selectedQuestion && (
                      <form onSubmit={handleSubmit(handleClickAddToStudy)}>
                        <div className="p-6 space-y-4">
                          <div className="border rounded border-gray-100 p-4 hover:bg-gray-200 cursor-pointer">
                            {selectedQuestion.full_text}
                          </div>
                          {/* Change: Wrapped block in div with flex-col class */}
                          <div className="flex flex-col w-1/3 max-w-lg">
                            <div className="m-2">
                              Question Category:
                              <SearchableMultiSelect
                                control={control}
                                name="categories"
                                options={[
                                  "Inclusion Criteria",
                                  "Exclusion Criteria",
                                  "Phone Screen",
                                ]}
                                getValue={() => getValues("categories")}
                                setValue={(value) => {
                                  setValue("categories", value)
                                }}
                              />
                            </div>
                          </div>
                          <div className="pt-8">
                            <button
                              type="submit"
                              className="inline-flex justify-center rounded-md border border-transparent bg-topo-green py-2 px-4 mr-2 text-sm font-medium text-white shadow-sm hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2"
                            >
                              Add to Study
                            </button>
                            <button
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setSelectedQuestion(null)}
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      </form>
                    )}

                    {/* Divider container */}
                    {!false && (
                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <div className="space-y-4 px-4 sm:px-6 sm:py-5">
                          {!addingQuestion && !selectedQuestion && (
                            <div>
                              Search for an existing question:
                              <input
                                className="mb-4 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                type="text"
                                onChange={handleChangeFilterText}
                              />
                              <button
                                type="button"
                                onClick={() => handleClickNewQuestion()}
                                className="inline-flex justify-center rounded-md border border-transparent bg-topo-green py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2"
                              >
                                New Question
                              </button>
                            </div>
                          )}
                          {/* Divider container */}
                          {addingQuestion && (
                            <div className="sm:divide-y sm:divide-gray-200 sm:py-0">
                              <div className="space-y-4 sm:py-5">
                                Add a new question:
                                <textarea
                                  value={fullText}
                                  onChange={(e) => setFullText(e.target.value)}
                                  className="mb-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  type="text"
                                />
                                <div className="space-y-4 sm:py-5">
                                  Question Format:
                                  <select
                                    value={questionType}
                                    onChange={(e) =>
                                      setQuestionType(e.target.value)
                                    }
                                    id="patient-suffix"
                                    className="mb-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700"
                                  >
                                    <option value="boolean">
                                      Yes/No/Unknown
                                    </option>
                                    <option value="text">Free Text</option>
                                  </select>
                                </div>
                              </div>
                              <button
                                type="button"
                                onClick={handleSaveQuestion}
                                className="inline-flex justify-center rounded-md border border-transparent bg-topo-green py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2"
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => setAddingQuestion(false)}
                              >
                                Cancel
                              </button>
                            </div>
                          )}
                          {filteredQuestions?.map((result) => (
                            <div
                              key={result?.item?.id}
                              className="border rounded border-gray-100 p-4 hover:bg-gray-200 cursor-pointer"
                              onClick={() => handleQuestionClick(result.item)}
                              onKeyUp={() => handleQuestionClick(result.item)}
                            >
                              {result?.item?.full_text}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Action buttons */}
                  <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div className="flex justify-start space-x-3">
                      {/* <button
                        type="button"
                        onClick={handleClickAdd}
                        className="inline-flex justify-center rounded-md border border-transparent bg-topo-green py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2"
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => props.setOpen(false)}
                      >
                        Cancel
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
