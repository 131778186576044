import { Dispatch, SetStateAction, useState } from 'react'
import { Link } from 'react-router-dom'
import { HeroIcon } from '../hero-icon'
import { friendlyDateTime } from '../../shared/friendly-dates'

interface Props {
  patientId: string,
  patientAppointments: [],
  setNewAppointmentModalOpen: Dispatch<SetStateAction<boolean>>,
}

export function PatientInfoSidebarAppointments(props: Props) {

  return (
    <div className='p-4 mb-4 rounded-lg border border-gray-200 shadow-md text-gray-700 bg-white dark:bg-transparent dark:text-gray-200'>
      <div className={"sm:flex sm:space-x-4 xl:space-x-0"}>
        <div>
          <div className='flex justify-between'>
            <h3 className="mb-2 text-base font-bold text-gray-900 dark:text-gray-200">Upcoming Appointments</h3>
          </div>
        </div>
      </div>

      <div className='flex flex-row mb-4'>
        <button
            onClick={() => props.setNewAppointmentModalOpen(true)}
            className="text-white bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 rounded font-semibol text-xs px-2.5 py-1.5 text-center inline-flex items-center"
          >
            <HeroIcon
              icon="PlusCircleIcon"
              className="fill-white w-4 h-4 mr-2 rotate-90"
            />
            Add Appointment
        </button>
        <Link to={`/patients/${props.patientId}/appointments`}>
          <button
              className="text-white bg-topo-navy hover:bg-topo-navy focus:bg-topo-navy focus:outline-none focus:ring-2 focus:ring-topo-navy-800 focus:ring-offset-2 rounded font-semibol text-xs px-2.5 py-1.5 text-center inline-flex items-center ml-2"
            >
              <HeroIcon
                icon="ViewListIcon"
                className="fill-white w-4 h-4 mr-2"
              />
              View All
            </button>
        </Link>
      </div>

      { props.patientAppointments.length == 0 &&
        <div className='text-xs mb-2'>
          No Upcoming Appointments
        </div>
      }

      { props.patientAppointments.map((appointment, index) =>
        <div key={index} className='flex flex-row mb-4 border-b border-gray-200'>
          <div className=' w-full'>
            <div className='flex items-center w-full justify-between'>
              <div className='flex'>
              <div className='mr-1'><HeroIcon icon="CalendarIcon" outline className="h-5 rounded-md" /></div>
              <div className="text-sm font-semibold">{friendlyDateTime(appointment.start_dt)}</div>
              </div>
            </div>
            <div className="text-gray-600 dark:text-gray-300 text-xs mb-1 ml-6">{appointment.appointment_type}</div>
          </div>
        </div>
      )}
    </div>
  )
}
