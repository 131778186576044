import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Utah } from '../../../components/utah';
import { Tooltip } from 'flowbite-react';
import { HeroIcon } from '../../../components/hero-icon';
import { useCreate, useIndex } from '../../../shared/use-rest';
import { Avatar } from '../../../components/avatar';
import { friendlyTimeDate } from '../../../shared/friendly-dates';

export function AdminComments() {
  const { data: patientComments, mutate: patientCommentsMutate } = useIndex('patient_comments', '', {refreshInterval: 250, refreshWhenHidden: true})
  const [uniqueNames, setUniqueNames] = useState([])
  useEffect(() => {
    setUniqueNames([...new Map(patientComments.map(comment => [comment['created_by_user_id'], comment])).values()])
  }, [patientComments])

  const commentPatientText = (comment) => {
    if (comment.patient?.given_name && comment.patient?.family_name) {
      return `${comment.patient?.given_name} ${comment.patient?.family_name}`
    }
    else return `MRN: ${comment.patient?.mrn}`
  }

  return (
    <div className="mt-10 p-6 rounded-lg border border-gray-200 shadow-md">
    <h5 className="text-2xl font-bold tracking-tight flex flex-row"><HeroIcon icon="SpeakerphoneIcon" className='h-5 mr-2 self-center' />Comments</h5>
    <div className="px-7 mt-2">
      <div className="text-2xl font-semibold leading-none"></div>
      <div className="flex flex-col items-start justify-center mb-5 space">
        <div className="flex flex-row flex-wrap">
          <div className="flex items-center justify-start">
            {uniqueNames.reverse().map((comment) =>
              <div className="-mr-2">
                <Tooltip content={comment?.created_by?.name}>
                  <Avatar name={comment?.created_by?.name} className="border-2 border-white rounded-full h-7 w-7 dark:border-gray-800" />
                </Tooltip>
              </div>
            )}
            <div className="text-sm text-gray-500 dark:text-gray-400 ml-4">
              {patientComments[0] && (
                <>
                  Last comment by{" "}
                  <a className="font-medium text-green-700 no-underline cursor-pointer hover:underline dark:text-green-500">
                    {patientComments[0]?.created_by?.name}
                  </a>
                  {" "}at<span title={patientComments[0]?.created_at}> {friendlyTimeDate(patientComments[0]?.created_at)}{" "}</span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {patientComments.map((comment) => (
        <div className="flex flex-col space-y-2 mb-4">
          <div className="flex items-center space-x-3">
            <Avatar name={comment.created_by?.name} className="border-2 border-white rounded-full h-7 w-7 dark:border-gray-800" />
            <div className="flex-1 min-w-0">
              <p className="text-sm font-semibold text-gray-900 truncate dark:text-white">
                {comment.created_by?.name} commented on {commentPatientText(comment)}
              </p>
              <p title={comment.created_at} className="text-xs text-gray-500 truncate dark:text-gray-400">
              {friendlyTimeDate(comment.created_at)}
              </p>
            </div>
          </div>
          <ul className="pl-10 text-sm text-gray-900 list-outside dark:text-gray-400">
            <li>{comment.body}</li>
          </ul>
          <Link className='ml-10 ' to={`/patients/${comment.patient_id}`}>
            <button
             className="mr-4 inline-flex items-center justify-center h-6 px-3 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-1 focus:ring-green-900 sm:w-auto dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            >View Patient
            <HeroIcon icon="ChevronRightIcon" className="h5 w-5 text-blue-100" />
            </button>
          </Link>
        </div>
      ))}
    </div>
    </div>
  );
}
