import { Breadcrumb } from "flowbite-react"
import { Link, useLocation } from "react-router-dom"

export default function Breadcrumbs() {
  const location = useLocation()
  const pathnames = location.pathname.split("/").filter((path) => path)

  const uuidV4Regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

  const isValidUUIDV4 = (str) => uuidV4Regex.test(str)

  return (
    <Breadcrumb
      aria-label="Solid background breadcrumb example"
      className="bg-gray-50 px-5 py-3 dark:bg-gray-800"
    >
      {pathnames.length > 0 && pathnames[0] !== "home" ? (
        <Breadcrumb.Item key={"home"}>
          <Link to="/home">Home</Link>
        </Breadcrumb.Item>
      ) : null}
      {pathnames.map((name, index) => {
        const routePath = `/${pathnames.slice(0, index + 1).join("/")}`
        const isLast = index === pathnames.length - 1

        let displayName = name
        if (!isValidUUIDV4(name)) {
          displayName = name
            .split("-")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
        }
        return (
          <Breadcrumb.Item key={name}>
            {isLast ? (
              <>{displayName}</>
            ) : (
              <Link to={routePath}>{displayName}</Link>
            )}
          </Breadcrumb.Item>
        )
      })}
    </Breadcrumb>
  )
}
