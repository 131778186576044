import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  type GridToolbarProps,
} from "@mui/x-data-grid-pro"
import { HeroIcon } from "../components/hero-icon"

export const GridToolbarCustomColumnDetails = (params: GridToolbarProps) => {
  return (
    <GridToolbarContainer {...params}>
      <GridToolbarColumnsButton
        className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800"
        startIcon={
          <HeroIcon icon="EyeIcon" className={"h-5 w-7 ml-2 text-white"} />
        }
        sx={{
          borderRadius: "0.375rem",
          textTransform: "capitalize",
          fontWeight: 550,
          fontSize: "0.875rem",
          backgroundColor: "#353D78",
          border: "1px",
          py: 1.2,
          mt: -0.5,
          ml: 1,
          pr: 2,
          "&:hover": {
            color: "white",
            backgroundColor: "#2f366d",
          },
        }}
      />
    </GridToolbarContainer>
  )
}

export const GridToolbarCustom = (params: GridToolbarProps) => {
  return (
    <GridToolbarContainer {...params}>
      <GridToolbarExport
        {...params}
        sx={{
          borderRadius: "0.375rem",
          textTransform: "capitalize",
          fontWeight: 550,
          fontSize: "0.875rem",
          backgroundColor: "#353D78",
          py: 1.2,
          px: 1.5,
          mt: -0.5,
          "&:hover": {
            color: "white",
            backgroundColor: "#2f366d",
          },
        }}
      />
    </GridToolbarContainer>
  )
}
