import { useContext } from "react"
import { AuthorizationContext } from "../shared/authorization-provider"
import { useIndex } from "../shared/use-rest"

export function PracticeSponsors() {
  const { practiceId } = useContext(AuthorizationContext)
  const {
    data: sponsors,
    isLoading,
    isError,
    mutate,
  } = useIndex("practice_sponsors", `practice_id=${practiceId}`)

  // const data = [
  //   {
  //     name: 'Novo Nordis',
  //     location: 'Denmark, EU',
  //     study_indications: ['NASH', 'IBD'],
  //     study_types: ['Interventional', 'Observational']
  //   },
  //   {
  //     name: 'Exact Sciences',
  //     location: 'California, USA',
  //     study_indications: ['NASH', 'IBD'],
  //     study_types: ['Interventional', 'Observational']
  //   },
  //   {
  //     name: 'Altimmune',
  //     location: 'Israel',
  //     study_indications: ['NASH', 'IBD'],
  //     study_types: ['Interventional', 'Observational']
  //   },
  //   {
  //     name: 'Gilead',
  //     location: 'California, USA',
  //     study_indications: ['NASH', 'IBD'],
  //     study_types: ['Interventional', 'Observational']
  //   },
  // ]

  return (
    <>
      <h3 className="p-4 mb-4 text-xl font-bold text-gray-900 dark:text-white">
        Sponsors
      </h3>
      {/* Table */}
      <div className="flex flex-col p-4">
        <div className="overflow-x-auto rounded-lg">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600 dark:border dark:border-gray-700">
                <thead className="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th
                      scope="col"
                      className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400"
                    >
                      Sponsor Name
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400"
                    >
                      Location
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400"
                    >
                      Indications
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400"
                    >
                      Study Types
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-800">
                  {sponsors.map((sponsor, index) => (
                    <tr
                      key={sponsor.id}
                      className={
                        index % 2 !== 0 ? "bg-gray-50 dark:bg-gray-700" : ""
                      }
                    >
                      <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                        <span className="text-sm font-medium ">
                          {sponsor.name}
                        </span>
                      </td>
                      <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                        <span className="text-sm font-medium ">
                          {sponsor.location}
                        </span>
                      </td>
                      <td className="p-4 text-sm whitespace-nowrap dark:text-gray-400">
                        {sponsor.indications.map((indication) => (
                          <span
                            key={`${sponsor.id}_${indication}`}
                            className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md"
                          >
                            {indication}
                          </span>
                        ))}
                      </td>
                      <td className=" text-sm whitespace-nowrap mr-2 px-2.5 py-0.5 rounded-md">
                        {sponsor.study_types.map((study_type) => (
                          <span
                            key={`${sponsor.id}_${study_type}`}
                            className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md"
                          >
                            {study_type}
                          </span>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
