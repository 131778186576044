import morningOne from './morning_1.png'
import morningTwo from './morning_2.png'
import morningThree from './morning_3.png'
import afternoonOne from './afternoon_1.png'
import afternoonTwo from './afternoon_2.png'
import afternoonThree from './afternoon_3.png'
import eveningOne from './evening_1.png'
import eveningTwo from './evening_2.png'
import eveningThree from './evening_3.png'

export const groupedMorningImages = [
	morningOne,
	morningTwo,
	morningThree
];

export const groupedAfternoonImages = [
	afternoonOne,
	afternoonTwo,
	afternoonThree
];

export const groupedEveningImages = [
	eveningOne,
	eveningTwo,
	eveningThree
];

export default {
	groupedMorningImages,
	groupedAfternoonImages,
	groupedEveningImages
};
