import { useIndex } from "../shared/use-rest"
import { Patients } from "./patients"

import Board from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css'
import { useEffect, useState } from "react"
import { friendlyRelative } from "../shared/friendly-dates"
import { HeroIcon } from "../components/hero-icon"
import { Link } from "react-router-dom"



export function PatientCards() {
   const { data: patients, isLoading, isError, mutate } = useIndex('patients')
   const [cards, setCards] = useState([])
   const [board, setBoard] = useState({})
   const statuses = ['New', 'Pending', 'Screening', 'Ineligible', 'Eligible']


return (
 <>

   <div className="flex flex-col mt-2">
      <div className="overflow-x-auto">
         <div className="inline-block min-w-full align-middle"> 
               <div className="overflow-hidden shadow">
                  <div className="flex items-start justify-start px-4 mb-6 space-x-4">
                     {statuses.map((status) => 
                     <div className="min-w-kanban">
                           <div className="py-4 text-base font-semibold text-gray-900 dark:text-gray-300">{status}</div>
                           <div id="kanban-list-{{ .id }}" className="mb-4 space-y-4 min-w-kanban">
                           { status == 'New' && patients.map((patient) =>
                              <div className="flex flex-col max-w-md p-5 transform bg-white rounded-lg shadow cursor-move dark:bg-gray-800">
                                 <div className="flex items-center justify-between pb-4">
                                       <div className="text-base font-semibold text-gray-900 dark:text-white">{ patient.given_name } { patient.family_name }</div>

                                       <Link to={`/patients/${patient.id}`}>
                                       <button type="button" data-modal-toggle="kanban-card-modal" className="p-2 text-sm text-gray-500 rounded-lg dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700">
                                          <HeroIcon icon="ExternalLinkIcon" ></HeroIcon>
                                       </button>
                                       </Link>
                                 </div>

                                 <div className="flex flex-col">
                                       <div className="pb-4 text-sm font-normal text-gray-700 dark:text-gray-400">{patient.mrn}</div>

                                       <div className="flex justify-between">
                                          {/*<div className="flex items-center justify-start">
                                              {{- range (index .members) }}
                                                   <a href="#" data-tooltip-target="user_{{ $task_id }}_{{ .id }}" className="-mr-3">
                                                      <img className="border-2 border-white rounded-full h-7 w-7 dark:border-gray-800" src="/images/users/{{ .avatar }}" alt="{{ .name }}" />
                                                   </a>
                                                   <div id="user_{{ $task_id }}_{{ .id }}" role="tooltip" className="absolute z-50 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">{{ .name }}<div className="tooltip-arrow" data-popper-arrow></div>
                                                   </div>
                                             {{ end -}} 
                                          </div>*/}
                                          {/* {{ if eq .completed true }} */}
                                          <div className="flex items-center px-3 text-sm font-medium text-green-800 bg-green-100 rounded-lg dark:bg-green-200">
                                             <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                             Done
                                          </div>
                                          {/* {{ else }} */}
                                          <div className="flex items-center px-3 text-sm font-medium text-purple-800 bg-purple-100 rounded-lg dark:bg-purple-200">
                                             <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd"></path></svg>
                                               {friendlyRelative(patient.updated_at)}
                                          </div>
                                          {/* {{ end }} */}
                                       </div>
                                 </div>
                              </div>
                           )}
                           </div>

                           <button type="button" data-modal-toggle="new-card-modal" className="flex p-2 items-center justify-center w-full py-2 font-semibold text-gray-500 border-2 border-gray-200 border-dashed rounded-lg hover:bg-gray-100 hover:text-gray-900 hover:border-gray-300 dark:border-gray-800 dark:hover:border-gray-700 dark:hover:bg-gray-800 dark:hover:text-white">
                              {/* <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg> */}
                              No {status} Patients
                           </button>
                     </div>
                     )}
                  </div>
               </div>
         </div>
      </div>
   </div>

 </>
)}


