import { useAuth0 } from "@auth0/auth0-react"
import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"
import { ErrorMessage } from "@hookform/error-message"
import { TextField } from "@mui/material"
import {
  type Dispatch,
  Fragment,
  type SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { Controller, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import type { KeyedMutator } from "swr"
import { useParticles } from "../../particles-provider"
import { AuthorizationContext } from "../../shared/authorization-provider"
import { event } from "../../shared/event"
import { useCreate, useIndex } from "../../shared/use-rest"
import { useKeyPress } from "../../shared/useKeyPress"

interface Props {
  open?: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  refreshData?: KeyedMutator<object[]>
}
export function PatientQuickAdd(props: Props) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({ defaultValues: { dob: "", mrn: undefined } })
  const { practiceId } = useContext(AuthorizationContext)
  const { getAccessTokenSilently } = useAuth0()
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const { data: practiceStudiesData, isLoading: isLoadingPracticeStudies } =
    useIndex("practice_studies")
  const [practiceStudies, setPracticeStudies] = useState([])
  const { triggerConfetti } = useParticles()

  const initPracticeStudies = () => {
    const activeStudies = practiceStudiesData.filter(
      (practiceStudy) => practiceStudy?.study?.status !== "Closed",
    )
    if (practiceStudies.length === activeStudies.length) return
    setPracticeStudies(activeStudies)
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (isLoadingPracticeStudies) return
    initPracticeStudies()
    return () => {}
  }, [isLoadingPracticeStudies])

  useKeyPress(["Escape"], () => props.setOpen(false))
  const createScreen = async (studyId, patientId) => {
    const data = {
      study_id: studyId,
      patient_id: patientId,
      status: "chart_review",
    }
    await useCreate("screens", data, getAccessTokenSilently)
  }
  /* TODO: VALIDATE FORM FIRST, MRN REQUIRED */
  const onSubmitAndView = async (data) => {
    clearErrors()
    setSubmitting(true)
    let response = {}
    let errorResponse = ""
    const { studyId, ...patientData } = data
    if (patientData.dob === "") patientData.dob = null
    try {
      response = await useCreate(
        "patients",
        patientData,
        getAccessTokenSilently,
      )
    } catch (error) {
      setSubmitting(false)
      setError("root.createPatientsError", {
        type: "custom",
        message: "Error submitting. Check for MRN duplication",
      })
      errorResponse = error
      return
    }
    setSubmitting(false)
    const patientId = response?.data?.data?.id
    event({
      eventName: "PatientQuickAdd",
      meta: { patient_id: patientId },
      getAccessTokenSilently,
    })
    if (!errorResponse) reset()
    if (studyId) await createScreen(studyId, patientId)
    triggerConfetti()
    navigate(`/patients/${patientId}`)
  }
  const onSubmitAndAddNew = async (data) => {
    clearErrors()
    setSubmitting(true)
    let response = {}
    let errorResponse = ""
    const { studyId, ...patientData } = data
    if (patientData.dob === "") patientData.dob = null
    try {
      response = await useCreate(
        "patients",
        patientData,
        getAccessTokenSilently,
      )
    } catch (error) {
      setSubmitting(false)
      errorResponse = error
      setError("root.createPatientsError", {
        type: "custom",
        message: "Error submitting. Check for MRN duplication",
      })
      return
    }
    setSubmitting(false)
    const patientId = response?.data?.data?.id
    event({
      eventName: "PatientQuickAdd",
      meta: { patient_id: patientId },
      getAccessTokenSilently,
    })
    if (studyId) await createScreen(studyId, patientId)
    if (props.refreshData) props.refreshData()
    if (!errorResponse) reset()
    // TODO: Toast 🍞
    triggerConfetti()
  }
  const handleSaveAndView = async () => await handleSubmit(onSubmitAndView)()
  const handleSaveAndAddNew = async () =>
    await handleSubmit(onSubmitAndAddNew)()

  // useEffect(() => {
  //   console.dir(errors)

  //   return () => {
  //   }
  // }, [errors])

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog onClose={props.setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300 sm:duration-300"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-400 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-2xl">
                <form
                  className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-gray-800 dark:border-l dark:border-l-gray-600 dark:text-gray-200 shadow-xl"
                  onSubmit={handleSubmit(onSubmitAndView)}
                >
                  <input
                    {...register("practice_id")}
                    defaultValue={practiceId}
                    type="hidden"
                  />
                  <input
                    {...register("source")}
                    defaultValue="quick_add"
                    type="hidden"
                  />
                  <div className="flex-1">
                    {/* Header */}
                    <div
                      className="
                    bg-topo-blue px-4 py-6 sm:px-6 rounded-t-md"
                    >
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-white ">
                            {" "}
                            New Patient
                          </Dialog.Title>
                          <p className="text-sm text-gray-200">
                            Fill in the information below to add a new patient.
                          </p>
                        </div>
                        <div className="flex h-7 items-center">
                          <button
                            type="button"
                            className="text-gray-200 hover:text-gray-500"
                            onClick={() => props.setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Divider container */}
                    <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 dark:divide-gray-700 sm:py-0">
                      {/* Patient mrn */}
                      <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="patient-mrn"
                            className="block text-sm font-medium text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
                          >
                            Patient MRN
                            <span
                              title="Required"
                              className="p-0.5 text-red-800"
                            >
                              *
                            </span>
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            {...register("mrn", {
                              required: "MRN is required",
                            })}
                            required={true}
                            type="text"
                            id="patient-mrn"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700"
                          />
                          <div className="mt-2 text-sm text-red-500">
                            <ErrorMessage errors={errors} name="mrn" />
                          </div>
                        </div>
                      </div>
                      {/* Patient first (given) name */}
                      <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="patient-given-name"
                            className="block text-sm font-medium text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
                          >
                            Patient First Name
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            {...register("given_name")}
                            required={false}
                            type="text"
                            id="patient-given-name"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700"
                          />
                        </div>
                      </div>
                      {/* Patient last (family) name */}
                      <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="patient-given-name"
                            className="block text-sm font-medium text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
                          >
                            Patient Last Name
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            {...register("family_name")}
                            required={false}
                            type="text"
                            id="patient-family-name"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700"
                          />
                        </div>
                      </div>
                      {/* Patient suffix */}
                      <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="patient-suffix"
                            className="block text-sm font-medium text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
                          >
                            Patient Suffix
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <select
                            {...register("suffix", { disabled: false })}
                            id="patient-suffix"
                            className="mb-4 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700"
                          >
                            <option />
                            <option>SR</option>
                            <option>JR</option>
                            <option>III</option>
                            <option>IV</option>
                            <option>V</option>
                          </select>
                        </div>
                      </div>
                      {/* Patient dob */}
                      <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="patient-dob"
                            className="block text-sm font-medium text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
                          >
                            Patient DOB
                          </label>
                        </div>
                        <div className="sm:col-span-2 dark:text-gray-200">
                          <Controller
                            name="dob"
                            control={control}
                            defaultValue={""}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                required={false}
                                type="date"
                                sx={{
                                  display: "block",
                                  marginBottom: "1rem",
                                  padding: "0",
                                  paddingTop: "0.1rem",
                                  width: 250,
                                  boxShadow: "none",
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      {/* Patient phone */}
                      <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="patient-phone"
                            className="block text-sm font-medium text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
                          >
                            Patient Phone Number
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            {...register("phone")}
                            type="text"
                            id="patient-phone"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700"
                          />
                        </div>
                      </div>
                      {/* Patient email */}
                      <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="patient-email"
                            className="block text-sm font-medium text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
                          >
                            Patient Email
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            {...register("email")}
                            type="text"
                            id="patient-email"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700"
                          />
                        </div>
                      </div>
                      {/* Patient source */}
                      <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="patient-source"
                            className="block text-sm font-medium text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
                          >
                            Patient Source
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <select
                            {...register("source", { disabled: false })}
                            id="patient-source"
                            className="mb-4 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700"
                          >
                            <option />
                            <option>Provider Referral</option>
                            <option>Manual EMR Review</option>
                            <option>Sponsor Referral</option>
                          </select>
                        </div>
                      </div>
                      {/* Patient study */}
                      {
                        <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="patient-study"
                              className="block text-sm font-medium text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
                            >
                              Add to Study
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <select
                              {...register("studyId", { disabled: false })}
                              id="patient"
                              className="mb-4 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700"
                            >
                              <option />
                              {practiceStudies.map((practiceStudy) => (
                                <option
                                  key={practiceStudy?.study?.id}
                                  value={practiceStudy?.study?.id}
                                  title={practiceStudy?.study?.name}
                                >
                                  {practiceStudy?.study?.abbreviation}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  {/* Action buttons */}
                  <div className="flex-shrink-0 border-t border-gray-200 dark:border-gray-700 px-4 py-5 sm:px-6">
                    <div className="mb-2 text-sm text-red-500">
                      <ErrorMessage
                        errors={errors}
                        name="root.createPatientsError"
                      />
                    </div>
                    <div className="flex justify-start space-x-3">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-topo-green py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2"
                        onClick={handleSubmit(handleSaveAndView)}
                        disabled={submitting}
                      >
                        Save and View
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-topo-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-900 focus:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2"
                        onClick={handleSubmit(handleSaveAndAddNew)}
                        disabled={submitting}
                      >
                        Save and Add New
                      </button>
                      <button
                        type="button"
                        className="rounded-md border justify-end border-transparent bg-red-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => props.setOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
