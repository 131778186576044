import { useContext } from "react"
import { ScreenContext } from "../../pages/patient-screen"
import { HeroIcon } from "../hero-icon"

export function BooleanQuestion(props) {
  const { question } = props
  const { submitQuestionResponse } = useContext(ScreenContext)
  const value = question?.StudyQuestionResponse?.response?.value

  return (
    <div className="flex">
      <div className="flex w-36 items-center">
        <div
          className={`can-toggle can-toggle-rectangle ${
            question.StudyQuestion?.meta?.categories?.includes(
              "exclusion_criteria",
            )
              ? "can-toggle-reverse-color"
              : ""
          }`}
        >
          <div className="flex">
            <div
              className=""
              onClick={() =>
                submitQuestionResponse(question.StudyQuestion.id, true)
              }
              onKeyUp={() =>
                submitQuestionResponse(question.StudyQuestion.id, true)
              }
            >
              <HeroIcon
                outline={false}
                icon="CheckCircleIcon"
                extraClasses={`w-8 h-8 hover:text-green-400 hover:cursor-pointer ${
                  value === true ? "text-green-400" : ""
                }`}
              />
            </div>
            <div
              className=""
              onClick={() =>
                submitQuestionResponse(question.StudyQuestion.id, false)
              }
              onKeyUp={() =>
                submitQuestionResponse(question.StudyQuestion.id, false)
              }
            >
              <HeroIcon
                outline={false}
                icon="XCircleIcon"
                extraClasses={`w-8 h-8 hover:text-red-400 hover:cursor-pointer ${
                  value === false && value !== "" ? "text-red-400" : ""
                }`}
              />
            </div>
            {value === "unknown" && (
              <div
                className=""
                onClick={() =>
                  submitQuestionResponse(question.StudyQuestion.id, "")
                }
                onKeyUp={() =>
                  submitQuestionResponse(question.StudyQuestion.id, "")
                }
              >
                <HeroIcon
                  outline={false}
                  icon="QuestionMarkCircleIcon"
                  extraClasses={`w-8 h-8 hover:cursor-pointer ${
                    value === "unknown"
                      ? "text-yellow-400 hover:text-gray-400"
                      : ""
                  }`}
                />
              </div>
            )}
            {value !== "unknown" && (
              <div
                className=""
                onClick={() =>
                  submitQuestionResponse(question.StudyQuestion.id, "unknown")
                }
                onKeyUp={() =>
                  submitQuestionResponse(question.StudyQuestion.id, "unknown")
                }
              >
                <HeroIcon
                  outline={false}
                  icon="QuestionMarkCircleIcon"
                  extraClasses={`w-8 h-8 hover:cursor-pointer hover:text-yellow-400 ${
                    !!value || value === "" ? "text-gray-600 " : ""
                  }`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex-1">
        <div className="text-lg font-semibold">
          {question.Question.full_text}
        </div>
        <div className="text-base font-normal">
          {question.Question.short_text}{" "}
        </div>
      </div>
    </div>
  )
}
