import { markAsUntransferable } from "node:worker_threads"
import { Overlay, Map as PigeonMap } from "pigeon-maps"
import { maptiler } from "pigeon-maps/providers"
import { useState } from "react"
import { HeroIcon } from "./hero-icon"

const maptilerProvider = maptiler("3gnsVB6Y3eU0swY5l1NV", "topo")
// const maptilerProvider = maptiler(process.env.MAPTILER_API_KEY, 'topo')

interface Props {
  defaultZoom: number
  center?: {
    latitude: number
    longitude: number
    title?: string
    showTitle?: boolean
  }
  markers: [
    {
      latitude: number
      longitude: number
      title?: string
      showTitle?: boolean
    },
  ]
  markCenter?: boolean
}

export function MapWithMultipleMarkers(props: Props) {
  const markers = props.markers
  const mapProps = {
    defaultZoom: 3.75,
    markers: [],
    markCenter: false,
    center: { latitude: 37.0902, longitude: -95.7129 },
    ...props,
  }

  if (mapProps.markCenter) {
    markers.push(props.center)
  }

  const handleMarkerClick = (index) => {
    for (const marker of markers) {
      marker.showTitle = false
    }
    markers[index].showTitle = true
  }

  return (
    <div className="p-4">
      <PigeonMap
        provider={maptilerProvider}
        dprs={[1, 2]} // this provider supports HiDPI tiles
        height={400}
        defaultCenter={[mapProps.center?.latitude, mapProps.center?.longitude]}
        defaultZoom={mapProps.defaultZoom}
      >
        {markers?.map((marker, index) => (
          <Overlay
            key={marker.title}
            offset={[12, 24]} // matches the heroicon height and half the width
            anchor={[marker?.latitude, marker?.longitude]}
            // color={}
            // onClick={() => setHue(hue + 20)}
          >
            <div
              onClick={() => handleMarkerClick(index)}
              onKeyDown={() => handleMarkerClick(index)}
            >
              <HeroIcon icon="LocationMarkerIcon" />
              {marker?.showTitle ?? marker.title}
            </div>
          </Overlay>
        ))}
      </PigeonMap>
    </div>
  )
}
