import { useEffect, useState } from "react"
import { ScreenQuestion } from "./screen-question"

export function Screening({ questions, registerResponseField, criteria }) {
  const [flattenQuestions, setFlattenQuestions] = useState([])
  const [phoneScreenQuestions, setPhoneScreenQuestions] = useState([])
  const [phoneScreenInclusionQuestions, setPhoneScreenInclusionQuestions] =
    useState([])
  const [phoneScreenExclusionQuestions, setPhoneScreenExclusionQuestions] =
    useState([])
  const [inclusionCriteriaQuestions, setInclusionCriteriaQuestions] = useState(
    [],
  )
  const [exclusionCriteriaQuestions, setExclusionCriteriaQuestions] = useState(
    [],
  )

  useEffect(() => {
    const flattenQuestionList = []
    for (const question of questions) {
      if (question?.StudyQuestion?.meta?.categories) {
        for (const category of question.StudyQuestion.meta.categories) {
          let revisedCategory = category
          if (
            category === "phone_screen" &&
            question.StudyQuestion.meta.categories.includes(
              "inclusion_criteria",
            )
          ) {
            revisedCategory = "inclusion_criteria/phone_screen"
          } else if (
            category === "phone_screen" &&
            question.StudyQuestion.meta.categories.includes(
              "exclusion_criteria",
            )
          ) {
            revisedCategory = "exclusion_criteria/phone_screen"
          }

          flattenQuestionList.push({
            ...question,
            StudyQuestion: {
              ...question.StudyQuestion,
              category: revisedCategory,
            },
          })
        }
      }
    }
    setFlattenQuestions(flattenQuestionList)
  }, [questions])

  const renumberQuestions = (category) => {
    const sortedQuestions = flattenQuestions
      .filter((q) => q.StudyQuestion.category === category)
      .sort((a, b) => a.order - b.order)
      .map((q, index) => ({
        ...q,
      }))

    return sortedQuestions
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setPhoneScreenQuestions(renumberQuestions("phone_screen"))
    setPhoneScreenInclusionQuestions(
      renumberQuestions("inclusion_criteria/phone_screen"),
    )
    setPhoneScreenExclusionQuestions(
      renumberQuestions("exclusion_criteria/phone_screen"),
    )
    setInclusionCriteriaQuestions(renumberQuestions("inclusion_criteria"))
    setExclusionCriteriaQuestions(renumberQuestions("exclusion_criteria"))
  }, [flattenQuestions])

  return (
    <>
      {phoneScreenQuestions.length !== 0 && (
        <>
          <h4 className="text-lg font-bold">Phone Screen</h4>
          <div className="divide-y divide-gray-200 dark:divide-gray-700">
            {phoneScreenQuestions.map((question, index) => (
              <ScreenQuestion
                key={question?.StudyQuestion?.id}
                question={question}
                registerResponseField={registerResponseField}
                criteria={criteria}
              />
            ))}
          </div>
          <div className="ml-5">
            <h2 className="text-m font-bold">Inclusion Criteria</h2>
            <div className="divide-y divide-gray-200 dark:divide-gray-700">
              {phoneScreenInclusionQuestions.map((question, index) => (
                <ScreenQuestion
                  key={`inclusions${question?.StudyQuestion?.id}`}
                  question={question}
                  registerResponseField={registerResponseField}
                  criteria={criteria}
                />
              ))}
            </div>
            <h2 className="text-m font-bold">Exclusion Criteria</h2>
            <div className="divide-y divide-gray-200 dark:divide-gray-700">
              {phoneScreenExclusionQuestions.map((question, index) => (
                <ScreenQuestion
                  key={`exclusions${question?.StudyQuestion?.id}`}
                  question={question}
                  registerResponseField={registerResponseField}
                  criteria={criteria}
                />
              ))}
            </div>
          </div>

          <hr className="my-8 bg-gray-300 border-0 dark:bg-gray-700 h-1" />
        </>
      )}
      <h4 className="text-lg font-bold">Inclusion Criteria</h4>
      <p className="text-sm font-normal text-gray-500 dark:text-gray-200">
        The answer to <span className="font-medium">all</span> of these question
        must be <span className="text-green-500">yes</span> for the patient to
        be <span className="text-green-500">eligible</span> for the trial.
      </p>
      <div className="divide-y divide-gray-200 dark:divide-gray-700">
        {inclusionCriteriaQuestions.map((question, index) => (
          <ScreenQuestion
            key={question?.StudyQuestion?.id}
            question={question}
            registerResponseField={registerResponseField}
            criteria={criteria}
          />
        ))}
      </div>
      <h4 className="text-lg font-bold">Exclusion Criteria</h4>
      <p className="text-sm font-normal text-gray-500 dark:text-gray-200">
        If the answer to <span className="font-medium">any</span> of these
        questions is <span className="text-red-500">yes</span>, this patient is{" "}
        <span className="text-red-500">ineligible</span> for the trial.
      </p>
      <div className="divide-y divide-gray-200 dark:divide-gray-700">
        {exclusionCriteriaQuestions.map((question, index) => (
          <ScreenQuestion
            key={question?.StudyQuestion?.id}
            question={question}
            registerResponseField={registerResponseField}
            criteria={criteria}
          />
        ))}
      </div>
    </>
  )
}
