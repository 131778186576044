import { DotsHorizontalIcon, PlusIcon } from "@heroicons/react/solid"
import { snakeToTitle } from "../../shared/text-utils"
import { classNames } from "../../shared/class-names"
import { Avatar } from "../../components/avatar"
import { StudyScreenStatusChip } from "../../components/patients/study-screen-status-chip"
import { Link } from "react-router-dom"
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd'
import { generateUUID } from "../../shared/dom-utils"

interface Props {
  patient: {}
  studyId: string
  patientIndex: number
  statusIndex: number
}

export const PatientCard = (props: Props) => {
  const { patient, studyId, patientIndex, statusIndex } = props
  const uniqueId = (statusIndex * 10 + patientIndex)

  return (
    <Draggable draggableId={patient?.screens?.[0].id} index={uniqueId} key={generateUUID()}>
    {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
      let isDragging = snapshot.isDragging && !snapshot.isDropAnimating
      return (
          <div
            ref={provided.innerRef}
            className={classNames(
              'cursor-default flex flex-col w-full px-4 py-3 mb-2 bg-gray-100 dark:bg-gray-700 rounded focus:outline-none',
              {
                'shadow-modal': isDragging,
              }
            )}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="flex justify-between w-full cursor-default">
              <div className="flex flex-col">
                <span className="text-md font-normal text-gray-800 dark:text-gray-300 text-sm">
                {patient.given_name} {patient.family_name}
                </span>
                <span className="mt-1 text-sm font-medium text-gray-500 dark:text-gray-200 line-clamp-2 overflow-ellipsis">
                  {patient.screens?.map((screen) => screen.study.id == studyId && (
                    <div key={screen?.id} className="py-1">
                      <Link to={`/patients/${patient.id}/screens/${screen.id}`}>
                        <StudyScreenStatusChip text={screen.study.abbreviation} status={screen.status} />
                      </Link>
                    </div>
                  ))}
                </span>
              </div>
              <div className="flex-shrink-0">
                {/* {patient.screens?.[0]?.assigned_to ? (
                  <Avatar
                    name={patient?.screens?.[0]?.name}
                    // avatarUrl={issue.owner.avatar}
                  />
                ) : (
                  <Avatar />
                )} */}
              </div>
            </div>
            <div className="mt-2.5 flex items-center">
              {/* <PriorityMenu
                button={priorityIcon}
                id={'priority-menu-' + issue.id}
                filterKeyword={true}
                onSelect={(p) => updatePriority(p)}
              /> */}
            </div>
          </div>
      )
    }}
    </Draggable>
  )
}
