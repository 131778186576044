import { Transition } from "@headlessui/react"
import { Fragment, useEffect, useState } from "react"
import { HeroIcon } from "./hero-icon"

export function SaveSpinner(props) {
  const { timeout = 1000 } = props
  const { isShowing: isShowingProp = true } = props
  const [isShowing, setIsShowing] = useState(isShowingProp)

  useEffect(() => {
    const timeoutFn = setTimeout(() => {
      setIsShowing(false)
    }, timeout)

    return () => {
      clearTimeout(timeoutFn)
    }
  }, [timeout])

  return (
    <Transition
      as={Fragment}
      show={isShowing}
      unmount={false}
      enter="transition-opacity duration-[100ms]"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-[1000ms]"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="ml-2 text-2xl">
        <HeroIcon icon="CheckCircleIcon" extraClasses="text-green-400" />
      </div>
    </Transition>
  )
}
