import { useAuth0 } from "@auth0/auth0-react"
import {
  type Dispatch,
  type SetStateAction,
  useContext,
  useEffect,
} from "react"
import { useForm } from "react-hook-form"
import type { KeyedMutator } from "swr"
import SubmitButton from "../../../../components/submit-button"
import { AuthorizationContext } from "../../../../shared/authorization-provider"
import { useShow, useUpdate } from "../../../../shared/use-rest"
import { useKeyPress } from "../../../../shared/useKeyPress"
import { PRACTICE_TEAM_TYPES } from "./shared"

interface Props {
  id: string
  entity_key: string
  setOpen: Dispatch<SetStateAction<boolean>>
  refreshData: KeyedMutator<{ data: unknown[] }>
  open?: boolean
}

export function TeamEditModal(props: Props) {
  const entity = {
    label: "Team",
    key: props.entity_key,
  }
  props.open = props.open ? props.open : false
  const { register, handleSubmit, reset } = useForm()
  const { data } = useShow(`${entity.key}s`, props.id)
  const { getAccessTokenSilently } = useAuth0()
  const { practiceId } = useContext(AuthorizationContext)
  const [isLoading, setIsLoading] = useState(false)
  const onKeyPress = (event) => {
    props.setOpen(false)
  }

  useKeyPress(["Escape"], onKeyPress)

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    reset(data)
  }, [data])

  const onSubmit = (data) => {
    setIsLoading(true)
    if (PRACTICE_TEAM_TYPES.includes(data.team_type)) {
      data.practice_id = practiceId
    }
    useUpdate(
      `admin/${entity.key}s`,
      props.id,
      data,
      getAccessTokenSilently,
    ).then(
      () => {
        props.setOpen(false)
        props?.refreshData()
        reset()
      },
      (error) => {
        // console.dir(error);
      },
    )
    setIsLoading(false)
  }

  const fields = [
    { label: "Name", key: "name" },
    { label: "Description", key: "description" },
    { label: "Abbreviation", key: "abbreviation" },
    { label: "Xref Id", key: "xref_id" },
    {
      label: "Team Type",
      key: "team_type",
      options:
        props.entity_key === "practice_team_xref"
          ? [
              { label: "Clinical", key: "clinical" },
              { label: "Managers", key: "managers" },
              { label: "Site", key: "site" },
              { label: "Unblinded", key: "unblinded" },
            ]
          : [
              { label: "MDC", key: "mdc" },
              { label: "Regulatory", key: "regulatory" },
            ],
    },
    {
      label: "Vendor Code",
      key: "vendor_code",
      options: [
        { label: "Slack", key: "slack" },
        { label: "Monday.com", key: "monday_dot_com" },
      ],
    },
  ]

  return (
    <>
      <div
        className="fixed flex left-0 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full"
        id="add-team-modal"
      >
        <div className="relative w-full h-full max-w-2xl px-4 md:h-auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <!-- Modal content --> */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
              {/* <!-- Modal header --> */}
              <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
                <h3 className="text-xl font-semibold dark:text-white">
                  Edit {entity.label}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                  onClick={() => props.setOpen(false)}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Edit button</title>
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <div className="p-6 space-y-6">
                <div className="grid grid-cols-6 gap-6">
                  {fields.map((field) => (
                    <div key={field.key} className="col-span-6 sm:col-span-6">
                      <label
                        htmlFor={field.key}
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {field.label}
                      </label>
                      {!field.options && (
                        <input
                          {...register(field.key)}
                          type="text"
                          name={field.key}
                          id={field.key}
                          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      )}
                      {field.options && (
                        <select
                          {...register(field.key)}
                          name={field.key}
                          id={field.key}
                          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option key="default" value="" disabled selected>
                            Select an Option
                          </option>
                          {field.options.map((option) => (
                            <option key={option?.label} value={option?.key}>
                              {option?.label}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  ))}
                </div>
                {/* <!-- Modal footer --> */}
                <div className="flex justify-between pt-4 border-t border-gray-200 rounded-b dark:border-gray-700">
                  <div>
                    {isLoading ? (
                      <SubmitButton />
                    ) : (
                      <button
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        type="submit"
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        id="modal-backdrop"
        className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
      />
    </>
  )
}
