import { useEffect } from "react";
import { HeroIcon } from "../hero-icon";

export function HeaderInlineForm(props) {
  const { attributeName, handleSubmit, onSubmit, register, editing, setEditing, reset, value } = props

  const resetForm = () => reset({[attributeName]: value})

  const handleReset = () => {
    resetForm()
    setEditing('')
  }

  return (
    <form className={editing == attributeName ? 'flex' : 'hidden'} onSubmit={handleSubmit(onSubmit)}>
    <input
      {...register(attributeName)}
      type='text'
      id={attributeName}
      className="block h-6 mr-1 text-gray-900 dark:text-gray-100 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800"
      />
    <button type="submit" >
      <HeroIcon icon="CheckCircleIcon" className="text-green-400 h-6 hover:text-green-500 hover:cursor-pointer" />
    </button>
    <button onClick={handleReset} className="ml-1 mr-4">
      <HeroIcon icon="XCircleIcon" className="text-red-400 h-6 hover:text-red-500 hover:cursor-pointer" />
    </button>
  </form>
  )
}
