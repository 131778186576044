import { useAuth0 } from "@auth0/auth0-react"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { HeroIcon } from "../components/hero-icon"
import { event } from "../shared/event"
import { friendlyDate } from "../shared/friendly-dates"
import { useDelete, useIndex } from "../shared/use-rest"
import { usePatientContext } from "./patient"

export function PatientConditions() {
  const { patientId } = useParams()
  const { data: conditions, isLoading, isError, mutate } = useIndex('patient_conditions', `patient_id=${patientId}`)
  const { currentStudyScreenId, setNewConditionModalOpen } = usePatientContext()
  const { getAccessTokenSilently } = useAuth0()

  const deleteCondition = async (id) => {
    await useDelete('patient_conditions', id, getAccessTokenSilently)
    mutate()
  }

  useEffect(() => {
    event({eventName: 'PatientConditionsViewStart', meta: {patient_id: patientId, screen_id: currentStudyScreenId }, getAccessTokenSilently})
    return () => {
      event({eventName: 'PatientConditionsViewEnd', meta: {patient_id: patientId, screen_id: currentStudyScreenId }, getAccessTokenSilently})
    }
  }, [])
  return (
    <>
      <div className="p-2 bg-white rounded-lg border border-gray-200 shadow-md">
        <div className="flex justify-between items-center">
            <h3 className="p-4 mb-4 text-xl font-bold text-gray-900 dark:text-white">
              Conditions
            </h3>
            <button
              onClick={() => setNewConditionModalOpen(true)}
              className="h-10 text-white bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 rounded font-semibol text-xs px-2.5 py-1.5 text-center inline-flex items-center"
            >
              <HeroIcon
                icon="PlusCircleIcon"
                className="fill-white w-4 h-4 mr-2 rotate-90"
              />
              Add Condition
            </button>
          </div>
      {/* Table */}
      <div className="flex flex-col">
        <div className="overflow-x-auto rounded-lg">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                <thead className="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">
                      Description
                    </th>
                    <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">
                      Started At
                    </th>
                    <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">
                      Ended At
                    </th>
                    <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">
                      Created By
                    </th>
                    <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">

                    </th>

                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-800">
                  { conditions.map((condition, index) => (
                    <tr key={index} className={index % 2 != 0 ? 'bg-gray-50' : ''}>
                      <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                        <span
                          className="text-sm font-medium ">
                          { condition?.condition?.name }
                        </span>
                      </td>
                      <td className="p-4 text-sm whitespace-nowrap dark:text-gray-400">
                        { friendlyDate(condition.started_at) }
                      </td>
                      <td className="p-4 text-sm whitespace-nowrap dark:text-gray-400">
                        { friendlyDate(condition.ended_at) }
                      </td>
                      <td className="p-4 text-sm whitespace-nowrap dark:text-gray-400">
                        { condition.created_by_user_id }
                      </td>
                      <td className="p-4 text-sm whitespace-nowrap dark:text-gray-400">
                      <div onClick={() => deleteCondition(condition.id)}><HeroIcon icon="TrashIcon" className='h-5 hover:text-red-500 hover:cursor-pointer' outline></HeroIcon></div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

