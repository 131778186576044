import React, { useEffect, useRef, useState } from "react"
import { SubmitSpinner } from "./submit-spinner"

function SubmitButton({ classNameOverride = "" }) {
  const [loading, setLoading] = useState(true)
  const [buttonDimensions, setButtonDimensions] = useState({
    width: "71.75px",
    height: "40px",
  })

  const buttonRef = useRef(null)
  const defaultClassName =
    "text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
  const loadingClassName =
    "text-gray-500 bg-gray-500 cursor-not-allowed hover:bg-gray-500 dark:hover:bg-gray-500"

  function getDimensions(element) {
    const rect = element?.getBoundingClientRect()
    return {
      width: rect?.width || "71.75px",
      height: rect?.height || "40px",
    }
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setButtonDimensions(getDimensions(buttonRef.current))
  }, [])

  return (
    <div>
      <button
        ref={buttonRef}
        className={`${
          loading ? loadingClassName : ""
        } ${defaultClassName} ${classNameOverride}`}
        type="submit"
        disabled={loading}
        style={{
          height: buttonDimensions.height,
          width: buttonDimensions.width,
          position: "relative",
        }}
      >
        <span
          style={
            loading
              ? {
                  position: "absolute",
                  width:
                    typeof buttonDimensions.height === "number"
                      ? buttonDimensions.height - 10
                      : buttonDimensions.height,
                  height:
                    typeof buttonDimensions.height === "number"
                      ? buttonDimensions.height - 10
                      : buttonDimensions.height,
                  transform: "translate(-50%, -50%)",
                }
              : {}
          }
        >
          {loading ? <SubmitSpinner /> : "Save"}
        </span>
      </button>
    </div>
  )
}

export default SubmitButton
