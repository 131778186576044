import { Avatar } from "./avatar"

export function UserAvatar(props) {
  return (
    <Avatar
      className="border-2 border-white rounded-full h-9 w-9 dark:border-gray-800"
      name={props.currentUser?.name}
    />
  )
}
