import { useAuth0 } from "@auth0/auth0-react"
import { titleCase } from "change-case-all"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"
import { ActionHeader } from "../../../../components/action-header"
import { ButtonLeadingIcon } from "../../../../components/button-leading-icon"
import { friendlyDateTime } from "../../../../shared/friendly-dates"
import { snakeToTitle } from "../../../../shared/text-utils"
import {
  useCreate,
  useDelete,
  useIndex,
  useShow,
  useUpdate,
} from "../../../../shared/use-rest"
import { StudyBuilderHeader } from "./header"
import { StudyBuilderQuestion } from "./question"
import { QuestionsSlideover } from "./questions-slideover"

export function StudyBuilder() {
  const { studyId } = useParams()
  const { getAccessTokenSilently } = useAuth0()
  const [
    questionsWithCategoriesFlattened,
    setQuestionsWithCategoriesFlattened,
  ] = useState([])
  const [phoneScreenQuestions, setPhoneScreenQuestions] = useState([])
  const [phoneScreenInclusionQuestions, setPhoneScreenInclusionQuestions] =
    useState([])
  const [phoneScreenExclusionQuestions, setPhoneScreenExclusionQuestions] =
    useState([])
  const [inclusionCriteriaQuestions, setInclusionCriteriaQuestions] = useState(
    [],
  )
  const [exclusionCriteriaQuestions, setExclusionCriteriaQuestions] = useState(
    [],
  )
  const { data: study } = useShow("admin/studies", studyId)
  const { data: study_questions, mutate: mutateStudyQuestions } = useIndex(
    "admin/study_questions",
    `study_id=${studyId}`,
  )
  const { data: questions, mutate: mutateQuestions } =
    useIndex("admin/questions")
  const [questionsSlideoverOpen, setQuestionsSlideoverOpen] = useState(false)

  const maxOrder = () => {
    if (study_questions?.length === 0) return 0
    const max = study_questions?.slice()?.sort((a, b) => b.order - a.order)[0]
    return max?.order || 0
  }

  const addStudyQuestion = async (studyQuestion: object) => {
    const studyQuestionData = {
      study_id: study.id,
      question_id: studyQuestion.question_id,
      order: studyQuestion?.order || maxOrder() + 1,
      meta: {
        categories: studyQuestion?.categories,
      },
    }
    await useCreate(
      "study_questions",
      studyQuestionData,
      getAccessTokenSilently,
    )
    toast.success("Question Added to Study")
    mutateStudyQuestions()
  }

  const addQuestion = async (fullText, questionType = "boolean") => {
    return await useCreate(
      "admin/questions",
      { full_text: fullText, question_type: questionType },
      getAccessTokenSilently,
    )
  }

  // redo the numbering for order?
  const deleteStudyQuestion = async (id) => {
    await useDelete("admin/study_questions", id, getAccessTokenSilently)
    mutateStudyQuestions()
  }

  const updateStudyQuestion = async (id, data) => {
    await useUpdate("admin/study_questions", id, data, getAccessTokenSilently)
    mutateStudyQuestions()
  }

  const decrementQuestionOrder = async (currentId: string) => {
    const order = study_questions.find(
      (studyQuestion) => studyQuestion.id === currentId,
    )?.order
    if (order - 1 === 0) return
    const previousQuestion = study_questions.find(
      (studyQuestion) => studyQuestion.order === order - 1,
    )
    if (!previousQuestion.id) return
    updateStudyQuestion(currentId, { order: order - 1 })
    updateStudyQuestion(previousQuestion.id, {
      order: previousQuestion.order + 1,
    })
  }

  const incrementQuestionOrder = async (currentId: string) => {
    const order = study_questions.find(
      (studyQuestion) => studyQuestion.id === currentId,
    )?.order
    if (order === maxOrder()) return
    const nextQuestion = study_questions.find(
      (studyQuestion) => studyQuestion.order === order + 1,
    )
    if (!nextQuestion?.id) return
    updateStudyQuestion(currentId, { order: order + 1 })
    updateStudyQuestion(nextQuestion?.id, { order: nextQuestion?.order - 1 })
  }

  useEffect(() => {
    const flattenQuestionList = []
    for (const question of study_questions) {
      if (question?.meta?.categories) {
        for (const category of question.meta.categories) {
          let revisedCategory = category
          if (
            category === "phone_screen" &&
            question.meta.categories.includes("inclusion_criteria")
          ) {
            revisedCategory = "inclusion_criteria/phone_screen"
          } else if (
            category === "phone_screen" &&
            question.meta.categories.includes("exclusion_criteria")
          ) {
            revisedCategory = "exclusion_criteria/phone_screen"
          }

          flattenQuestionList.push({ ...question, category: revisedCategory })
        }
      }
    }
    setQuestionsWithCategoriesFlattened(flattenQuestionList)
  }, [study_questions])

  const renumberQuestionsWithinCategory = (category) => {
    const sortedQuestions = questionsWithCategoriesFlattened
      .filter((q) => q.category === category)
      .sort((a, b) => a.order - b.order)
      .map((q, index) => ({
        ...q,
        relative_order: index + 1,
      }))

    return sortedQuestions
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setPhoneScreenQuestions(renumberQuestionsWithinCategory("phone_screen"))
    setPhoneScreenInclusionQuestions(
      renumberQuestionsWithinCategory("inclusion_criteria/phone_screen"),
    )
    setPhoneScreenExclusionQuestions(
      renumberQuestionsWithinCategory("exclusion_criteria/phone_screen"),
    )
    setInclusionCriteriaQuestions(
      renumberQuestionsWithinCategory("inclusion_criteria"),
    )
    setExclusionCriteriaQuestions(
      renumberQuestionsWithinCategory("exclusion_criteria"),
    )
  }, [questionsWithCategoriesFlattened])

  return (
    <>
      <ActionHeader title="StudyBuilder" />
      <StudyBuilderHeader study={study} />
      <NewQuestionButton
        setQuestionsSlideoverOpen={setQuestionsSlideoverOpen}
      />
      <div className="text-sm font-bold m-4">
        Total Number: {study_questions?.length}
      </div>

      <div className="text-sm font-bold m-4">
        Last Updated:{" "}
        {friendlyDateTime(
          study_questions.reduce((max, obj) => {
            const currentDate = new Date(`${obj.updated_at}Z`)
            return currentDate > max ? currentDate : max
          }, new Date(0)),
        )}
      </div>
      <QuestionsSlideover
        open={questionsSlideoverOpen}
        setOpen={setQuestionsSlideoverOpen}
        refreshData={() => mutateQuestions()}
        questions={questions}
        addStudyQuestion={addStudyQuestion}
        addQuestion={addQuestion}
      />
      <div className="mt-2 flex h-screen">
        <div className="flex-grow">
          <div>
            <h1 className="text-2xl font-bold m-4">Phone Screen</h1>
            {phoneScreenQuestions.map((question) => (
              <StudyBuilderQuestion
                key={question.id}
                question={question}
                deleteStudyQuestion={deleteStudyQuestion}
                updateStudyQuestion={updateStudyQuestion}
                decrementQuestionOrder={decrementQuestionOrder}
                incrementQuestionOrder={incrementQuestionOrder}
                maxOrder={maxOrder}
              />
            ))}
            <div className="ml-5">
              <h2 className="text-xl font-bold m-4">Inclusion Criteria</h2>
              {phoneScreenInclusionQuestions.map((question) => (
                <StudyBuilderQuestion
                  key={question.id}
                  question={question}
                  deleteStudyQuestion={deleteStudyQuestion}
                  updateStudyQuestion={updateStudyQuestion}
                  decrementQuestionOrder={decrementQuestionOrder}
                  incrementQuestionOrder={incrementQuestionOrder}
                  maxOrder={maxOrder}
                />
              ))}
              <h2 className="text-xl font-bold m-4">Exclusion Criteria</h2>
              {phoneScreenExclusionQuestions.map((question) => (
                <StudyBuilderQuestion
                  key={question.id}
                  question={question}
                  deleteStudyQuestion={deleteStudyQuestion}
                  updateStudyQuestion={updateStudyQuestion}
                  decrementQuestionOrder={decrementQuestionOrder}
                  incrementQuestionOrder={incrementQuestionOrder}
                  maxOrder={maxOrder}
                />
              ))}
            </div>
          </div>

          <hr className="my-8 bg-gray-300 border-0 dark:bg-gray-700 h-1" />
          <div>
            <h1 className="text-2xl font-bold m-4">Inclusion Criteria</h1>
            {inclusionCriteriaQuestions.map((question) => (
              <StudyBuilderQuestion
                key={question.id}
                question={question}
                deleteStudyQuestion={deleteStudyQuestion}
                updateStudyQuestion={updateStudyQuestion}
                decrementQuestionOrder={decrementQuestionOrder}
                incrementQuestionOrder={incrementQuestionOrder}
                maxOrder={maxOrder}
              />
            ))}
          </div>
          <div>
            <h1 className="text-2xl font-bold m-4">Exclusion Criteria</h1>
            {exclusionCriteriaQuestions.map((question) => (
              <StudyBuilderQuestion
                key={question.id}
                question={question}
                deleteStudyQuestion={deleteStudyQuestion}
                updateStudyQuestion={updateStudyQuestion}
                decrementQuestionOrder={decrementQuestionOrder}
                incrementQuestionOrder={incrementQuestionOrder}
                maxOrder={maxOrder}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

const NewQuestionButton = (props) => (
  <div
    className="p-4 inline-flex"
    onClick={() => props.setQuestionsSlideoverOpen(true)}
    onKeyUp={() => props.setQuestionsSlideoverOpen(true)}
  >
    <ButtonLeadingIcon
      className="bg-topo-blue hover:bg-blue-800 focus:ring-blue-500"
      icon="PlusCircleIcon"
      text="Add Question"
    />
  </div>
)
