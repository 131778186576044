import React from "react"
import EditorButton from "./editor-button"

const Toolbar = ({ editor }) => {
  return (
    <div className="toolbar bg-gray-200 py-2 px-4 rounded-md shadow-md flex items-center justify-between">
      <div className="toolbar-group space-x-1 flex-wrap">
        {/* Text Formatting */}
        <EditorButton
          title="Bold"
          onClickHandler={() => editor.chain().focus().toggleBold().run()}
          isDisabled={!editor.can().chain().focus().toggleBold().run()}
          isActive={editor.isActive("bold")}
        />
        <EditorButton
          title="Italic"
          onClickHandler={() => editor.chain().focus().toggleItalic().run()}
          isDisabled={!editor.can().chain().focus().toggleItalic().run()}
          isActive={editor.isActive("italic")}
        />
        <EditorButton
          title="Strike"
          onClickHandler={() => editor.chain().focus().toggleStrike().run()}
          isDisabled={!editor.can().chain().focus().toggleStrike().run()}
          isActive={editor.isActive("strike")}
        />

        {/* Paragraph Styles */}
        <EditorButton
          title="Paragraph"
          onClickHandler={() => editor.chain().focus().setParagraph().run()}
          isActive={editor.isActive("paragraph")}
        />
        <EditorButton
          title="H1"
          onClickHandler={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          isActive={editor.isActive("heading", { level: 1 })}
        />
        <EditorButton
          title="H2"
          onClickHandler={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          isActive={editor.isActive("heading", { level: 2 })}
        />
        <EditorButton
          title="H3"
          onClickHandler={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          isActive={editor.isActive("heading", { level: 3 })}
        />

        {/* Lists */}
        <EditorButton
          title="Bullet list"
          onClickHandler={() => editor.chain().focus().toggleBulletList().run()}
          isActive={editor.isActive("bulletList")}
        />
        <EditorButton
          title="Ordered list"
          onClickHandler={() =>
            editor.chain().focus().toggleOrderedList().run()
          }
          isActive={editor.isActive("orderedList")}
        />

        {/* Quotes and Separators */}
        <EditorButton
          title="Horizontal rule"
          onClickHandler={() =>
            editor.chain().focus().setHorizontalRule().run()
          }
        />
        <EditorButton
          title="Hard break"
          onClickHandler={() => editor.chain().focus().setHardBreak().run()}
        />

        {/* History */}
        <EditorButton
          title="Undo"
          onClickHandler={() => editor.chain().focus().undo().run()}
          isDisabled={!editor.can().chain().focus().undo().run()}
        />
        <EditorButton
          title="Redo"
          onClickHandler={() => editor.chain().focus().redo().run()}
          isDisabled={!editor.can().chain().focus().redo().run()}
        />
      </div>
    </div>
  )
}

export default Toolbar
