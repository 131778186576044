import { titleCase } from "change-case-all"
import { TextInput } from "flowbite-react"
import { motion } from "framer-motion"
import { useState } from "react"
import { HeroIcon } from "../../../../components/hero-icon"
import { friendlyDateTime } from "../../../../shared/friendly-dates"
import { snakeToTitle } from "../../../../shared/text-utils"

interface Props {
  question: object
  updateStudyQuestion: () => void // TODO: method signature
  deleteStudyQuestion: () => void // TODO: method signature
  maxOrder: () => number
  decrementQuestionOrder: (id: string) => Promise<void>
  incrementQuestionOrder: (id: string) => Promise<void>
}

export function StudyBuilderQuestion(props: Props) {
  const {
    question,
    deleteStudyQuestion,
    updateStudyQuestion,
    maxOrder,
    decrementQuestionOrder,
    incrementQuestionOrder,
  } = props
  const [editing, setEditing] = useState("")
  const [protocolReference, setProtocolReference] = useState(
    question?.protocol_reference,
  )
  const [decrementing, setDecrementing] = useState(false)
  const [incrementing, setIncrementing] = useState(false)

  const handleDecrement = (question) => {
    if (question?.order <= 1) return
    setDecrementing(true)
    decrementQuestionOrder(question?.id)
  }
  const handleIncrement = (question) => {
    if (question?.order >= maxOrder()) return
    setIncrementing(true)
    incrementQuestionOrder(question?.id)
  }
  // return (<>{JSON.stringify(question?.question?.full_text)}</>)

  if (question?.question === undefined) return <></>

  return (
    <motion.div
      animate={
        decrementing
          ? { top: "-80px", transitionEnd: { top: "0px" } }
          : incrementing
            ? { zIndex: 100, top: "80px", transitionEnd: { top: "0px" } }
            : {}
      }
      onAnimationComplete={() => {
        setDecrementing(false)
        setIncrementing(false)
      }}
      className={
        "relative flex mt-4 ml-4 mr-4 p-4 border border-gray-200 rounded transition duration-500 ease-in-out dark:bg-gray-800 bg-white"
      }
    >
      <div className="flex items-center">
        <div className="mr-4 w-12">
          {question?.relative_order} ({question?.order})
        </div>
        <div className="pr-4  min-w-56 flex">
          <div className="pr-4">
            {question?.meta?.categories.includes("inclusion_criteria") && (
              <div className="px-2 py-1 text-xs rounded-2xl bg-green-400 text-gray-50 justify-center flex">
                Inclusion
              </div>
            )}
            {question?.meta?.categories.includes("exclusion_criteria") && (
              <div className="px-2 py-1 text-xs rounded-2xl bg-red-400 text-gray-50 justify-center flex">
                Exclusion
              </div>
            )}
          </div>
          <div className="pr-4">
            {question?.meta?.categories.includes("phone_screen") && (
              <div className="px-2 py-1 text-xs rounded-2xl bg-blue-400 text-gray-50 justify-center flex">
                Phone Screen
              </div>
            )}
          </div>
        </div>
        {question?.question?.question_type === "boolean" && (
          <div className="flex">
            <HeroIcon
              outline={false}
              className="text-gray-500"
              icon="CheckCircleIcon"
              extraClasses={"w-8 h-8 hover:text-green-400 hover:cursor-pointer"}
            />
            <HeroIcon
              outline={false}
              className="text-gray-500"
              icon="XCircleIcon"
              extraClasses={"w-8 h-8 hover:text-red-400 hover:cursor-pointer"}
            />
            <HeroIcon
              outline={false}
              className="text-gray-500"
              icon="QuestionMarkCircleIcon"
              extraClasses={
                "w-8 h-8 hover:cursor-pointer hover:text-yellow-400"
              }
            />
          </div>
        )}
        {question?.question?.question_type === "text" && (
          <div className="flex">
            <TextInput placeholder="textfield" className="w-24 text-gray-500" />
          </div>
        )}
      </div>
      <div
        className="flex items-center pb-1 ml-4 my-2"
        onClick={() => setEditing("protocol_reference")}
        onKeyUp={() => setEditing("protocol_reference")}
      >
        {/* TODO: Don't allow edit if there are responses for this question */}
        {editing !== "protocol_reference" && (
          <HeroIcon
            icon="PencilAltIcon"
            className="h-4 w-4 ml-2 mr-2 mt-0.5 hover:cursor-pointer"
          />
        )}
      </div>
      {editing !== "protocol_reference" && (
        <div className="flex items-center w-1/12">
          <div className="text-sm">
            Protocol Ref: {question?.protocol_reference}
          </div>
        </div>
      )}

      {editing === "protocol_reference" && (
        <div className="flex items-center ">
          <input
            className="w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:text-gray-900"
            // defaultValue={question?.protocol_reference}
            value={protocolReference}
            onInput={(e) => setProtocolReference(e.target.value)}
          />
          <button
            type="button"
            className="ml-2 border border-topo-green-200 bg-transparent hover:bg-topo-green-100 dark:hover:bg-green-200 hover:bg-opacity-50 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 text-topo-green text-xs font-medium mr-2 px-2.5 py-2 rounded-md flex items-center justify-between"
            // onClick, send the question_id and the updated study_question attributes to the patch endpoint
            onClick={() => {
              updateStudyQuestion(question?.id, {
                protocol_reference: protocolReference,
              })
              setEditing("")
            }}
          >
            Save
            <HeroIcon icon="CheckCircleIcon" className="ml-1 w-5 h-5 flex" />
          </button>
          <button
            type="button"
            onClick={() => setEditing("")}
            className="border border-gray-200 bg-transparent hover:bg-gray-100 dark:hover:bg-gray-200 hover:bg-opacity-50 focus:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2 text-gray-500 text-xs font-medium mr-6 px-2.5 py-2 rounded-md flex items-center justify-between"
          >
            Cancel
            <HeroIcon
              icon="XCircleIcon"
              className="ml-1 w-5 h-5 flex text-gray"
            />
          </button>
        </div>
      )}
      <div className="flex-1 flex items-center ml-2">
        <div className="text-sm">{question?.question?.full_text}</div>
        <div className="text-sm">{question?.question?.short_text} </div>
      </div>
      <div className="flex-1 flex items-center ml-2 justify-end">
        <div className="text-sm ml-auto mr-2 w-15 flex justify-start">
          {friendlyDateTime(question?.updated_at)}
        </div>
      </div>
      <div className="flex justify-between items-center">
        {question?.order < maxOrder() ? (
          <div
            className="ml-2"
            onClick={() => handleIncrement(question)}
            onKeyUp={() => handleIncrement(question)}
          >
            <HeroIcon
              icon="ArrowDownIcon"
              className="h-5 hover:text-topo-green-500 hover:cursor-pointer"
              outline
            />
          </div>
        ) : (
          <div className="ml-2 w-5" />
        )}
        {question?.order > 1 ? (
          <div
            className="ml-2"
            onClick={() => handleDecrement(question)}
            onKeyUp={() => handleDecrement(question)}
          >
            <HeroIcon
              icon="ArrowUpIcon"
              className="h-5 hover:text-topo-green-500 hover:cursor-pointer"
              outline
            />
          </div>
        ) : (
          <div className="ml-2 w-5" />
        )}
        {/* TODO: can't delete if it has responses for this study? */}
        {true && (
          <div
            className="ml-4"
            onClick={() => deleteStudyQuestion(question?.id)}
            onKeyUp={() => deleteStudyQuestion(question?.id)}
          >
            <HeroIcon
              icon="TrashIcon"
              className="h-5 hover:text-red-500 hover:cursor-pointer"
              outline
            />
          </div>
        )}
      </div>
    </motion.div>
  )
}
