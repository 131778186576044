import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { HeroIcon } from "../../components/hero-icon"
import { QualityAssuranceTable } from "../../components/quality-assurance/quality-assurance-table"

import { AuthorizationContext } from "../../shared/authorization-provider"
import { useIndex } from "../../shared/use-rest"
import { QualityAssuranceModal } from "../admin/quality-assurance/quality-assurance-modal"

export function QualityAssurance() {
  const navigate = useNavigate()
  const { practiceId } = useContext(AuthorizationContext)
  const { currentUser, isLoading: isLoadingCurrentUser } =
    useContext(AuthorizationContext)
  const { data: qualityAssurances, mutate: qualityAssuranceMutate } = useIndex(
    "qa_screens",
    `practice_id=${practiceId}&assigned_to_user_id=${currentUser.id}`,
  )

  const [qualityAssuranceModalOpen, setQualityAssuranceModalOpen] =
    useState(false)

  const handleScreenNavigate = (screen_id, patient_id) => {
    navigate(`/patients/${patient_id}/screens/${screen_id}`)
  }

  return (
    <>
      <div className="mt-10 p-6 rounded-lg border border-gray-200 shadow-md">
        <h5 className="text-2xl font-bold tracking-tight flex flex-row">
          <HeroIcon icon="ClipboardIcon" className="h-5 mr-2 self-center" />
          Quality Assurance
        </h5>
        <div className="px-7 mt-2" />
      </div>
      <div className="p-4 rounded-lg border border-gray-200 shadow-md bg-white dark:bg-transparent">
        <div className="flex justify-between items-center">
          <h3 className="p-4 mb-4 text-xl font-bold text-gray-900 dark:text-white">
            Studies
          </h3>
        </div>

        <QualityAssuranceTable
          screens={qualityAssurances}
          handleScreenNavigate={handleScreenNavigate}
        />
      </div>
      {qualityAssuranceModalOpen && (
        <QualityAssuranceModal
          setOpen={setQualityAssuranceModalOpen}
          refreshData={qualityAssuranceMutate}
        />
      )}
    </>
  )
}
