import { useContext, useState } from "react"
import { ButtonLeadingIcon } from "../../../components/button-leading-icon"
import { HeroIcon } from "../../../components/hero-icon"
import { QualityAssuranceTable } from "../../../components/quality-assurance/quality-assurance-table"
import { AuthorizationContext } from "../../../shared/authorization-provider"
import { useIndex } from "../../../shared/use-rest"
import { QualityAssuranceModal } from "./quality-assurance-modal"

export function AdminQualityAssurance() {
  const { practiceId } = useContext(AuthorizationContext)
  const { data: qualityAssurances, mutate: qualityAssuranceMutate } = useIndex(
    "qa_screens",
    `practice_id=${practiceId}`,
  )

  const [qualityAssuranceModalOpen, setQualityAssuranceModalOpen] =
    useState(false)

  return (
    <>
      <div className="mt-10 p-6 rounded-lg border border-gray-200 shadow-md">
        <h5 className="text-2xl font-bold tracking-tight flex flex-row">
          <HeroIcon icon="ClipboardIcon" className="h-5 mr-2 self-center" />
          Quality Assurance
        </h5>
        <div className="px-7 mt-2" />
      </div>
      <div className="p-4 rounded-lg border border-gray-200 shadow-md bg-white dark:bg-transparent">
        <div className="flex justify-between items-center">
          <h3 className="p-4 mb-4 text-xl font-bold text-gray-900 dark:text-white">
            Studies
          </h3>
          <div
            onClick={() => setQualityAssuranceModalOpen(true)}
            onKeyUp={() => setQualityAssuranceModalOpen(true)}
            className="right-justify"
          >
            <ButtonLeadingIcon icon="PlusCircleIcon" text="Add QA Screens" />
          </div>
        </div>

        <QualityAssuranceTable
          // patientId={patientId}
          screens={qualityAssurances}
          // handleScreenNavigate={handleScreenNavigate}
          // handleScreenStart={handleScreenStart}
        />
      </div>
      {qualityAssuranceModalOpen && (
        <QualityAssuranceModal
          setOpen={setQualityAssuranceModalOpen}
          refreshData={qualityAssuranceMutate}
        />
      )}
    </>
  )
}
