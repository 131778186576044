import { formatRelative, isEqual, parseISO, parseJSON } from "date-fns"
import { format } from "date-fns-tz"
import enUS from "date-fns/locale/en-US"

export const dateCompare = (d1, d2): boolean =>
  isEqual(parseISO(d1), parseISO(d2))

export const removeZulu = (dateString: string): string =>
  dateString.replace("Z", "")

export const parseLocalTimeToUTC = (dateString: string): string => {
  if (dateString === null || dateString === undefined) return ""
  return removeZulu(new Date(dateString).toISOString())
}

export const currentBrowserTimezone = () =>
  new Date()
    .toLocaleTimeString(undefined, { timeZoneName: "short" })
    .split(" ")[2]

export function friendlyDate(dateString): string {
  if (dateString === null || dateString === undefined) return ""
  return format(parseISO(dateString), "M/d/yy")
}

export function dateISO(dateString): string | null {
  if (dateString === null || dateString === undefined) return null
  return format(parseJSON(dateString), "yyyy-MM-dd")
}

export function dateTimeISO(dateString): string {
  if (dateString === null || dateString === undefined) return ""
  return format(parseJSON(dateString), "yyyy-MM-dd h:mma zzz")
}

export function dateTimeISOWithoutTimezone(dateString): string {
  if (dateString === null || dateString === undefined) return ""
  return format(parseJSON(dateString), "yyyy-MM-dd'T'HH:mm:ss")
}

const formatRelativeLocale = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: "dd.MM.yyyy",
}

const locale = {
  ...enUS,
  formatRelative: (token) => formatRelativeLocale[token],
}

export function friendlyRelativeWithoutTime(dateString): string {
  if (dateString === null || dateString === undefined) return ""
  return formatRelative(parseJSON(dateString), new Date(), { locale })
}

export function friendlyRelative(dateString): string {
  if (dateString === null || dateString === undefined) return ""
  return formatRelative(parseJSON(dateString), new Date())
}

export function friendlyDateTime(dateString): string {
  if (dateString === null || dateString === undefined) return ""
  return format(parseJSON(dateString), "M/d/yyyy h:mm a zzz")
}

export function friendlyDateTimeWithoutTimezone(dateString): string {
  if (dateString === null || dateString === undefined) return ""
  return format(parseJSON(dateString), "M/d/yyyy HH:mma")
}

export function friendlyTimeDate(dateString): string {
  if (dateString === null || dateString === undefined) return ""
  return format(parseJSON(dateString), "h:mma zzz M/d/yy")
}

export function friendlyTime(dateString): string {
  if (dateString === null || dateString === undefined) return ""
  return format(parseJSON(dateString), "h:mma")
}
