import { useAuth0 } from "@auth0/auth0-react"
import { useContext } from "react"
import { isAdmin } from "../components/protected-admin"
import {
  groupedAfternoonImages,
  groupedEveningImages,
  groupedMorningImages,
} from "../images/groupedDashboardImages"
import { AuthorizationContext } from "../shared/authorization-provider"
import { ManagerDashboard } from "./dashboard/manager-dashboard"
import { UserDashboard } from "./dashboard/user-dashboard"

const _ = require("lodash")
const hour = new Date().getHours()
const morning = hour > 0 && hour < 12
const afternoon = hour >= 12 && hour < 17
const evening = hour >= 17 && hour <= 23

function useGreeting() {
  const welcomeTypes = ["Good morning", "Good afternoon", "Good evening"]

  if (morning) return welcomeTypes[0]
  if (afternoon) return welcomeTypes[1]
  return welcomeTypes[2]
}

function greetingImage() {
  if (morning) return _.sample(groupedMorningImages)
  if (afternoon) return _.sample(groupedAfternoonImages)
  if (evening) return _.sample(groupedEveningImages)
}

export function Dashboard() {
  const greeting = useGreeting()
  const { currentUser, isLoading: isLoadingCurrentUser } =
    useContext(AuthorizationContext)
  const { user } = useAuth0()

  return (
    <>
      {/* <div className="relative overflow-hidden shadow-lg cursor-default m-6 rounded-md mb-0">
        <img
          className="object-cover object-[0px_ -120px] w-full h-36"
          src={greetingImage()}
          alt="dashboard image"
        />

        <div className="absolute top-0 left-0 p-2">
          <div className="dark:rounded-md dark:bg-gray-900 dark:bg-opacity-80 py-6 px-4">
            <h4 className="mb-1 text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-300">{greeting}{user?.given_name && ', '}{user?.given_name}!</h4>
            <p className="leading-normal text-xl font-medium text-gray-900 dark:text-gray-300">Where are you heading {evening ? 'tonight' : 'today'}?</p>
          </div>
        </div>
      </div> */}
      {!isAdmin(currentUser) && <UserDashboard />}
      {isAdmin(currentUser) && <ManagerDashboard />}
    </>
  )
}
