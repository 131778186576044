import { useQueryClient } from "@tanstack/react-query"
import { useKeyPress } from "../shared/useKeyPress"

interface Props {
  closeModal?: () => void
  blockedCount: number
  setBlockedCount: React.Dispatch<React.SetStateAction<number>>
  override: () => void
}

export function SelectCriterionModal(props: Props) {
  const queryClient = useQueryClient()

  const closeModal = () => {
    props.setBlockedCount(props.blockedCount + 1)
    props.closeModal()
  }

  useKeyPress(["Escape"], closeModal)

  return (
    <>
      <div
        className="fixed flex left-0 ml-12 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full"
        id="add-appointment-modal"
      >
        <div className="relative ml-10 w-full h-full max-w-3xl px-4 md:h-auto">
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
            {/* <!-- Modal header --> */}
            <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
              <h3 className="text-xl font-semibold dark:text-white">
                Select a criteria
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={() => closeModal()}
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>X Button</title>
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            {/* <!-- Modal body --> */}
            <div className="py-12 px-6 space-y-6">
              <div className="grid grid-cols-6">
                <div className="col-span-12 text-md">
                  Before leaving this chart, please select the disqualifying
                  inclusion/exclusion criteria.
                </div>
              </div>
            </div>
            {/* <!-- Modal footer --> */}
            <div className="items-center p-4 border-t border-gray-200 dark:border-gray-700 rounded-b">
              <button
                onClick={() => closeModal()}
                className="text-white bg-topo-green hover:bg-topo-green-700 focus:ring-4 focus:ring-topo-green-600 font-medium rounded-lg text-sm mr-5 px-5 py-2.5 text-center"
                type="submit"
              >
                Ok
              </button>
              {props.blockedCount > 1 && (
                <button
                  onClick={() => {
                    props.setBlockedCount(0)
                    props.override()
                  }}
                  className="text-white bg-red-500 hover:bg-topo-red-500 focus:ring-4 focus:ring-red-600 font-medium rounded-lg text-sm mr-5 px-5 py-2.5 text-center"
                  type="submit"
                >
                  Proceed anyway
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        id="modal-backdrop"
        className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
      />
    </>
  )
}
