"use client"

import { Label, Radio } from "flowbite-react"
import { Controller } from "react-hook-form"

export function RadioGroup({ control, name = null, options }) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <fieldset className="flex max-w-md flex-col gap-2">
          {options.map((option) => (
            <label
              htmlFor={`${name}_${option.id}`}
              key={`${name}_${option.id}`}
              className="flex items-center gap-2"
            >
              <Radio
                id={`${name}_${option.id}`}
                name={name}
                value={option?.value}
                checked={field.value === option.value}
                onChange={() => field.onChange(option.value)}
              />
              <Label htmlFor={`${name}_${option.id}`}>{option.name}</Label>
            </label>
          ))}
        </fieldset>
      )}
    />
  )
}
