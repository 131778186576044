import { useAuth0 } from "@auth0/auth0-react"
import { createContext, useEffect, useState } from "react"
import { useSWRConfig } from "swr"
import { AxiosBase, createUrl, indexUrl, showURL } from "./use-rest"

const defaultContext = {
  currentUser: {},
  isLoading: true,
  authorizedPractices: [],
  practiceId: "",
  practiceName: "",
  changePractice: (id) => {},
}

export const AuthorizationContext = createContext(defaultContext)

export const AuthorizationProvider = ({ children }) => {
  // TODO: expose logout from here?
  const { isLoading: isLoadingAuth0User, getAccessTokenSilently } = useAuth0()
  const { mutate } = useSWRConfig()
  const isCurrentPractice = (practiceId) => {
    return context.practiceId === practiceId
  }

  const clearCache = () => {
    mutate(() => true, undefined, { rollbackOnError: false })
    localStorage.removeItem("bookmarkId")
  }

  const changePractice = async (practiceId: string) => {
    if (isCurrentPractice(practiceId)) {
      return
    }

    const accessToken = await getAccessTokenSilently()
    const authHeaders = { Authorization: `Bearer ${accessToken}` }

    if (process.env.PRACTICE_ID) {
      const authorizationResponse = await AxiosBase.get(
        indexUrl("authorizations"),
        {
          headers: authHeaders,
        },
      )
      const practiceResponse = await AxiosBase.get(
        showURL("practices", process.env.PRACTICE_ID),
        {
          headers: authHeaders,
        },
      )

      setContext({
        ...context,
        currentUser: authorizationResponse?.data?.data,
        isLoading: false,
        practiceName: practiceResponse.data?.data?.name,
        practiceId: process.env.PRACTICE_ID,
      })
      return
    }

    const authorizationResponse = await AxiosBase.post(
      createUrl("authorizations"),
      { id: practiceId },
      { headers: authHeaders },
    )
    if (authorizationResponse.status === 200) {
      localStorage.removeItem("bookmarkId")
      // refetch current user metadata
      const currentUserResponse = await AxiosBase.get(
        indexUrl("authorizations"),
        {
          headers: authHeaders,
        },
      )
      // get authorized practices for user (populate dropdown)
      const authorizedPracticesResponse = await AxiosBase.get(
        indexUrl("authorizations/practices"),
        {
          headers: authHeaders,
        },
      )
      // Store in LS for re-authz to same practice
      localStorage.setItem(
        "practiceId",
        authorizationResponse?.data?.data?.practice_id,
      )

      clearCache()

      setContext({
        ...context,
        currentUser: currentUserResponse?.data?.data,
        isLoading: false,
        authorizedPractices: authorizedPracticesResponse?.data?.data,
        practiceName: authorizationResponse?.data?.data?.practice_name,
        practiceId: authorizationResponse?.data?.data?.practice_id,
      })
    }
  }

  const getAuthorizedPractices = async () => {
    const accessToken = await getAccessTokenSilently()
    // get list of authorized practices for user (to populate dropdown)
    const authorizedPracticesResponse = await AxiosBase.get(
      indexUrl("authorizations/practices"),
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    const authorizedPractices = authorizedPracticesResponse?.data?.data
    setContext({
      ...context,
      authorizedPractices: authorizedPractices,
    })
    return authorizedPractices
  }

  const [context, setContext] = useState({ ...defaultContext, changePractice })

  // biome-ignore lint/correctness/useExhaustiveDependencies: No need to specify functions.
  useEffect(() => {
    const authorizeDefault = async () => {
      if (isLoadingAuth0User) return

      // Prefer LS for default practiceId
      if (localStorage.practiceId) {
        await changePractice(localStorage.practiceId)
      }
      // Otherwise use the first authorized practice as a fallback
      else {
        const authorizedPractices = await getAuthorizedPractices()
        await changePractice(authorizedPractices[0]?.id)
      }
    }
    authorizeDefault()
    return () => {}
  }, [isLoadingAuth0User])

  return (
    <AuthorizationContext.Provider value={context}>
      {children}
    </AuthorizationContext.Provider>
  )
}
