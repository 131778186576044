import { useEffect, useState } from "react"

const localStorageJoy = () =>
  "joy" in localStorage && localStorage.joy === "true"

export function usePrefersJoy() {
  const [prefersJoy, setPrefersJoy] = useState(localStorageJoy())

  useEffect(() => {
    function handleChangeStorage() {
      setPrefersJoy(localStorageJoy())
    }

    window.addEventListener("storage", handleChangeStorage)
    return () => window.removeEventListener("storage", handleChangeStorage)
  }, [])

  return prefersJoy
}
