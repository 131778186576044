import { useAuth0 } from "@auth0/auth0-react"
import { QueryCache, QueryClient, useQuery } from "@tanstack/react-query"
import { fetcher, indexUrl, showURL } from "./use-rest"

export const clearCacheQuery = () => {
  const queryCache = new QueryCache()
  queryCache.clear()
}

export const useIndexQuery = (
  resource,
  params = null,
  options = {},
  useQueryOptions = {},
) => {
  // To get an access token, this must be called from within a component where it has context of auth provider
  const { getAccessTokenSilently } = useAuth0()

  const queryFn = async () => {
    const url = `${indexUrl(resource)}${params ? `?${params}` : ""}`
    const data = await fetcher(url, getAccessTokenSilently)
    return data
  }

  return useQuery({
    queryKey: [resource, params],
    queryFn: queryFn,
    initialData: { data: [] },
    refetchOnWindowFocus: false,
    ...useQueryOptions,
  })
}

// TODO
// export const useIndexInfinite = (resource, params=null, options={}) => {}

export const useShowQuery = (
  resource,
  id,
  options = {},
  useQueryOptions = {},
) => {
  if (id === undefined) return
  // To get an access token, this must be called from within a component where it has context of auth provider
  const { getAccessTokenSilently } = useAuth0()

  const queryFn = async () => {
    const url = `${showURL(resource, id)}${
      options?.params ? `?${options?.params}` : ""
    }`
    const data = await fetcher(url, getAccessTokenSilently)
    return data
  }

  return useQuery({
    queryKey: [resource, id],
    queryFn: queryFn,
    initialData: { data: {} },
    refetchOnWindowFocus: false,
    ...useQueryOptions,
  })
}
