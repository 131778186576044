import {
  DataGrid,
  type GridCellParams,
  type GridColDef,
  type GridRowsProp,
  type MuiEvent,
} from "@mui/x-data-grid"
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro"
import { useQueryClient } from "@tanstack/react-query"
import { useRef, useState } from "react"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { ActionHeader } from "../../../../components/action-header"
import { ButtonLeadingIcon } from "../../../../components/button-leading-icon"
import { HeroIcon } from "../../../../components/hero-icon"
import SplashScreen from "../../../../components/splash-screen"
import { friendlyDateTime } from "../../../../shared/friendly-dates"
import { useIndexQuery } from "../../../../shared/use-rest-query"
import { PracticeUserEditModal } from "./edit-modal"
import { PracticeUserNewModal } from "./new-modal"

export function PracticeUsersDisplay(props) {
  const [practiceUser, setPracticeUser] = useState()
  const { userId } = useParams()

  const [newUserModalOpen, setNewUserModalOpen] = useState(false)
  const [editUserModalOpen, setEditUserModalOpen] = useState(false)

  const {
    data: { data: practice_users },
    isFetching,
    isError,
  } = useIndexQuery("admin/practice_users", `user_id=${userId}`)

  const queryClient = useQueryClient()
  const mutatePracticeUsers = () => {
    queryClient.invalidateQueries({
      queryKey: ["admin/practice_users", `user_id=${userId}`],
    })
  }

  const rows: GridRowsProp = practice_users
  const columns: GridColDef[] = [
    {
      field: "practice_name",
      headerName: "Practice",
      width: 150,
      display: "flex",
      renderCell: (params) => <>{params.row.practice?.name}</>,
    },
    {
      field: "practice_abbreviation",
      headerName: "Abbr.",
      width: 80,
      display: "flex",
      renderCell: (params) => <>{params.row.practice?.abbreviation}</>,
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      display: "flex",
      renderCell: (params) => <>{params.row.user?.name}</>,
    },
    {
      field: "task_user_xref_id",
      headerName: "Monday ID",
      width: 120,
      display: "flex",
      renderCell: (params) => <>{params.row.user?.task_user_xref_id}</>,
    },
    {
      field: "phone_extension_xref_id",
      headerName: "Extension",
      width: 100,
      display: "flex",
    },
    {
      field: "role",
      headerName: "Role",
      width: 120,
      display: "flex",
    },
    {
      field: "email",
      headerName: "Email",
      width: 180,
      display: "flex",
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 120,
      display: "flex",
    },
    {
      field: "created_at",
      headerName: "Created",
      width: 180,
      display: "flex",
      renderCell: (params) => friendlyDateTime(params.row.created_at),
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 180,
      display: "flex",
      renderCell: (params) => friendlyDateTime(params.row.updated_at),
    },
    {
      field: "deleted_at",
      headerName: "Deleted",
      width: 120,
      display: "flex",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      display: "flex",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => renderCellActions(params),
    },
  ]

  const renderCellActions = (props: GridCellParams) => {
    return (
      <button
        type="button"
        onClick={() => {
          setPracticeUser(props.row)
          setEditUserModalOpen(true)
        }}
        className="bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 text-white text-xs font-medium mr-6 px-2.5 py-1.5 rounded-md flex items-center justify-between"
      >
        Edit
        <HeroIcon icon="ArrowCircleRightIcon" className="ml-1 w-5 h-5 flex" />
      </button>
    )
  }

  return (
    <>
      <div className="mt-2 pr-4">
        <div>
          <div className="flex justify-between items-center pr-4">
            <h2 className="text-2xl sm:text-3xl  p-2 mb-2 font-bold text-gray-900 dark:text-white">
              Practice Users
            </h2>
            <div
              onClick={() => setNewUserModalOpen(true)}
              onKeyUp={() => setNewUserModalOpen(true)}
            >
              <ButtonLeadingIcon
                icon="PlusCircleIcon"
                text="Add Practice User"
                className="inline-flex items-center justify-center h-10 w-1/2 px-3 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300 sm:w-auto dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              />
            </div>
          </div>
        </div>
        {isFetching || isError ? (
          <SplashScreen isLoading={isFetching} isError={isError} />
        ) : (
          <div className="mt-2 flex min-h-[200px]">
            <div className="flex-grow">
              <DataGridPro
                rows={rows}
                columns={columns}
                onCellDoubleClick={(
                  params: GridCellParams,
                  event: MuiEvent<MouseEvent>,
                ) => {
                  event.defaultMuiPrevented = true
                  setPracticeUser(params.row)
                  setEditUserModalOpen(true)
                }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
              />
            </div>
          </div>
        )}
      </div>
      {newUserModalOpen && userId && (
        <PracticeUserNewModal
          userId={userId}
          setOpen={setNewUserModalOpen}
          refreshData={mutatePracticeUsers}
        />
      )}

      {editUserModalOpen && userId && (
        <PracticeUserEditModal
          practiceUser={practiceUser}
          userId={userId}
          setOpen={setEditUserModalOpen}
          refreshData={mutatePracticeUsers}
        />
      )}
    </>
  )
}
