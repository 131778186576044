import { Button } from '../components/button'
import { ActionHeader } from '../components/action-header'
import { showURL, useCreate, useIndex, useShow, useUpdate } from '../shared/use-rest'
import Input from '../components/input'
import { ButtonLeadingIcon } from '../components/button-leading-icon'
import { HeroIcon } from '../components/hero-icon'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { Comments } from '../components/patients/comments'
import { usePatientContext } from './patient'
import { mutate } from 'swr'
import { event } from '../shared/event'
import { dirtyValues } from '../shared/dirty-values'
import { friendlyRelative } from '../shared/friendly-dates'
import TextField from '@mui/material/TextField'
import LanguageOptions from '../components/language-options'
import { useAuth0 } from '@auth0/auth0-react'
import { SaveSpinner } from '../components/save-spinner'

export function PatientInfo() {
  const { patientId } = useParams()
  const { patient, currentStudyScreenId, mutatePatient } = usePatientContext()
  const { register, handleSubmit, reset, control, formState: { dirtyFields, isDirty } } = useForm()
  const { getAccessTokenSilently } = useAuth0()
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    event({eventName: 'PatientInfoViewStart', meta: {patient_id: patientId, screen_id: currentStudyScreenId }, getAccessTokenSilently})
    return () => {
      event({eventName: 'PatientInfoViewEnd', meta: {patient_id: patientId, screen_id: currentStudyScreenId }, getAccessTokenSilently})
    }
  }, [])

  useEffect(() => {
    reset(patient)
  }, [reset, patient])

  const onSubmit = (data) => {
    const values = dirtyValues(dirtyFields, data)
    setSaving(true)
    useUpdate('patients', patientId, values, getAccessTokenSilently).then((response) => {
      event({eventName: 'PatientInfoUpdate', meta: { patient_id: patientId, element: 'PatientInfoForm', fields: Object.keys }, getAccessTokenSilently})
      mutatePatient()
      setSaving(false)
    }, (error) => {
      // console.dir(error);
    });
  }

  return (
    <>
      {/* { isLoading ? 'Loading..' : '' } */}
      {/* { isError ? 'Failed to load' : '' } */}

      <div className="p-6 rounded-b-lg shadow-md bg-white dark:bg-transparent border-l border-r border-b border-gray-200">
        <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-gray-200 flex flex-row">
          {/* <HeroIcon icon="ClipboardListIcon" className='h-5 mr-2 self-center' /> */}
          Patient Info
        </h5>
        {patient.updated_at && <h3 className='text-xs'>Last updated <span className='text-green-600 font-medium'>{friendlyRelative(patient.updated_at)}</span></h3>}

        <form className='mt-2' onSubmit={handleSubmit(onSubmit)}>
          <label
              htmlFor='given_name'
              className="font-medium text-sm text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
          >
            First Name
          </label>

          <input
            {...register('given_name', {disabled: false})}
            type="text"
            id='given_name'
            className="mb-4 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800"
          />
          <label
            htmlFor='family_name'
            className="font-medium text-sm text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
          >
            Last Name
          </label>

          <input
            {...register('family_name', {disabled: false})}
            type="text"
            id='family_name'
            className="mb-4 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800"
          />
          <label
            htmlFor='suffix'
            className="font-medium text-sm text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
          >
            Suffix
          </label>

          <select
            {...register('suffix')}
            id='suffix'
            className="mb-4 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800"
          >
            <option></option>
            <option>SR</option>
            <option>JR</option>
            <option>III</option>
            <option>IV</option>
            <option>V</option>
          </select>
          <label
            htmlFor='mrn'
            className="font-medium text-sm text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
          >
            MRN
          </label>

          <input
            {...register('mrn', {disabled: false})}
            type="text"
            id='mrn'
            className="mb-4 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800"
          />

          <label
              htmlFor='phone'
              className="font-medium text-sm text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
          >
            Phone
          </label>

          <input
            {...register('phone')}
            type="text"
            id='phone'
            className="mb-4 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800"
          />

          <label
              htmlFor='preferred_phone'
              className="font-medium text-sm text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
          >
            Preferred Phone
          </label>

          <input
            {...register('preferred_phone')}
            type="text"
            id='preferred_phone'
            className="mb-4 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800"
          />
          <label
              htmlFor='email'
              className="font-medium text-sm text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
          >
            Email
          </label>

          <input
            {...register('email')}
            type="text"
            id='email'
            className="mb-4 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800"
          />
          <label
              htmlFor='preferred_email'
              className="font-medium text-sm text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
          >
            Preferred Email
          </label>

          <input
            {...register('preferred_email')}
            type="text"
            id='preferred_email'
            className="mb-4 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800"
          />
          <label
              htmlFor='preferred_language'
              className="font-medium text-sm text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
          >
            Preferred Language
          </label>

          <select
            {...register('preferred_language')}
            id='preferred_language'
            className="mb-4 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800"
          >
            <LanguageOptions />
          </select>
          <label
              htmlFor='dob'
              className="mb-4 font-medium text-sm text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
          >
            Birthdate
          </label>

          <Controller
              name="dob"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  sx={{
                    display: 'block',
                    'marginBottom': '1rem',
                    'padding': '0',
                    'paddingTop': '0.1rem',
                    width: 250,
                    'boxShadow': 'none'
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />

          <label
              htmlFor='birth_sex'
              className="font-medium text-sm text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
          >
            Birth Sex
          </label>
          <select
            {...register('birth_sex', {disabled: false})}
            id='birth_sex'
            className="mb-4 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800"
          >
            <option></option>
            <option>Male</option>
            <option>Female</option>
            <option>Intersex</option>
          </select>
          <label
              htmlFor='gender'
              className="font-medium text-sm text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
          >
            Gender
          </label>
          <input
            {...register('gender')}
            type="text"
            id='gender'
            className="mb-4 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800"
          />
          <label
              htmlFor='race'
              className="font-medium text-sm text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
          >
            Race
          </label>
          <select
            {...register('race', {disabled: false})}
            id='race'
            className="mb-4 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800"
          >
            <option></option>
            <option>American Indian or Alaska Native</option>
            <option>Asian</option>
            <option>Black or African American</option>
            <option>Native Hawaiian or Other Pacific Islander</option>
            <option>White</option>
            <option>Some Other Race</option>
            <option>Two or More Races</option>
            <option>Declines to Answer</option>
          </select>
          <label
              htmlFor='ethnicity'
              className="font-medium text-sm text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
          >
            Ethnicity
          </label>

          <select
            {...register('ethnicity')}
            type="text"
            id='ethnicity'
            className="mb-4 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800"
          >
            <option></option>
            <option>Hispanic or Latino</option>
            <option>Not Hispanic or Latino</option>
            <option>Declines to Answer</option>
          </select>
          <label
              htmlFor='referring_provider'
              className="font-medium text-sm text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
          >
            Referring Provider (Provider of Record)
          </label>

          <input
            {...register('referring_provider')}
            type="text"
            id='referring_provider'
            className="mb-4 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800"
          />


          <label
              htmlFor='source'
              className="font-medium text-sm text-gray-900 dark:text-gray-200 sm:mt-px sm:pt-2"
          >
            Source
          </label>

          <select
            {...register('source')}
            id='source'
            className="mb-4 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-800"
          >
              <option></option>
              <option>Provider Referral</option>
              <option>Data Extract</option>
              <option>Manual EMR Review</option>
              <option>Sponsor Referral</option>
          </select>

          <div className="flex items-center">
            <button
              className="mt-4 text-white bg-topo-blue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="submit"
              >
              Save
            </button>
            <SaveSpinner isShowing={saving} />
          </div>
        </form>
      </div>
    </>
  )
}
