import {
  DataGrid,
  type GridCellParams,
  type GridColDef,
  type GridRowsProp,
  type MuiEvent,
} from "@mui/x-data-grid"
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro"
import { useQueryClient } from "@tanstack/react-query"
import { useRef, useState } from "react"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { ActionHeader } from "../../../../components/action-header"
import { ButtonLeadingIcon } from "../../../../components/button-leading-icon"
import { HeroIcon } from "../../../../components/hero-icon"
import SplashScreen from "../../../../components/splash-screen"
import { friendlyDateTime } from "../../../../shared/friendly-dates"
import { useIndex } from "../../../../shared/use-rest"
import { useIndexQuery } from "../../../../shared/use-rest-query"
import { StudyEditModal } from "../edit-modal"
import { StudyNewSlideover } from "../new-slideover"
import { PracticeStudyEditModal } from "./edit-modal"
import { PracticeStudyNewModal } from "./new-modal"

export function PracticeStudiesDisplay(props) {
  const [practiceStudy, setPracticeStudy] = useState()
  const { studyId } = useParams()
  const [newPracticeStudyModalOpen, setNewPracticeStudyModalOpen] =
    useState(false)
  const [editPracticeStudyModalOpen, setEditPracticeStudyModalOpen] =
    useState(false)

  const {
    data: { data: practice_studies },
    isFetching,
    isError,
  } = useIndexQuery("admin/practice_studies", `study_id=${studyId}`)

  const queryClient = useQueryClient()
  const mutatePracticeStudies = () => {
    queryClient.invalidateQueries({
      queryKey: ["admin/practice_studies", `study_id=${studyId}`],
    })
  }

  const rows: GridRowsProp = practice_studies
  const columns: GridColDef[] = [
    {
      field: "practice_name",
      headerName: "Practice",
      width: 150,
      display: "flex",
      renderCell: (params) => <>{params.row.practice?.name}</>,
    },
    {
      field: "study_name",
      headerName: "Study",
      width: 150,
      display: "flex",
      renderCell: (params) => <>{params.row.study?.name}</>,
    },
    {
      field: "study_abbreviation",
      headerName: "Study Abbr.",
      width: 120,
      display: "flex",
      renderCell: (params) => <>{params.row.study?.abbreviation}</>,
    },
    {
      field: "topography_study_identifier",
      headerName: "Topo Study Id",
      width: 150,
      display: "flex",
    },
    {
      field: "task_board_xref_id",
      headerName: "Monday Board ID",
      width: 150,
      display: "flex",
    },
    {
      field: "xref_study_id",
      headerName: "Esource Study Id",
      width: 150,
      display: "flex",
    },
    {
      field: "xref_study_visit_id",
      headerName: "Screening Visit Id",
      width: 150,
      display: "flex",
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      display: "flex",
    },
    {
      field: "enrollment_start_dt",
      headerName: "Enroll Start",
      width: 120,
      display: "flex",
    },
    {
      field: "enrollment_end_dt",
      headerName: "Enroll End",
      width: 120,
      display: "flex",
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 100,
      display: "flex",
    },
    {
      field: "enrollment_target",
      headerName: "Enroll Target",
      width: 120,
      display: "flex",
    },
    {
      field: "created_at",
      headerName: "Created",
      width: 180,
      display: "flex",
      renderCell: (params) => friendlyDateTime(params.row.created_at),
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 180,
      display: "flex",
      renderCell: (params) => friendlyDateTime(params.row.updated_at),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      display: "flex",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => renderCellActions(params),
    },
  ]

  const renderCellActions = (props: GridCellParams) => {
    return (
      <button
        type="button"
        onClick={() => {
          setPracticeStudy(props.row)
          setEditPracticeStudyModalOpen(true)
        }}
        className="bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 text-white text-xs font-medium px-2.5 py-1.5 rounded-md flex items-center justify-between"
      >
        Edit
        <HeroIcon icon="ArrowCircleRightIcon" className="ml-1 w-5 h-5 flex" />
      </button>
    )
  }

  return (
    <>
      <div className="mt-2 pr-4">
        <div>
          <div className="flex justify-between items-center pl-2">
            <h3 className="font-bold text-lg">Practice Studies</h3>
            <div
              onClick={() => setNewPracticeStudyModalOpen(true)}
              onKeyUp={() => setNewPracticeStudyModalOpen(true)}
            >
              <ButtonLeadingIcon
                icon="PlusCircleIcon"
                text="Add Practice Study"
                className="inline-flex items-center justify-center h-10 w-1/2 px-3 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300 sm:w-auto dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              />
            </div>
          </div>
        </div>

        {isFetching || isError ? (
          <SplashScreen isLoading={isFetching} isError={isError} />
        ) : (
          <div className="mt-2 flex min-h-[200px]">
            <div className="flex-grow">
              <DataGridPro
                rows={rows}
                columns={columns}
                onCellDoubleClick={(
                  params: GridCellParams,
                  event: MuiEvent<MouseEvent>,
                ) => {
                  event.defaultMuiPrevented = true
                  setPracticeStudy(params.row)
                  setEditPracticeStudyModalOpen(true)
                }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
              />
            </div>
          </div>
        )}
      </div>
      {newPracticeStudyModalOpen && studyId && (
        <PracticeStudyNewModal
          setOpen={setNewPracticeStudyModalOpen}
          refreshData={mutatePracticeStudies}
        />
      )}

      {editPracticeStudyModalOpen && studyId && (
        <PracticeStudyEditModal
          practiceStudy={practiceStudy}
          setOpen={setEditPracticeStudyModalOpen}
          refreshData={mutatePracticeStudies}
        />
      )}
    </>
  )
}
