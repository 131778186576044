import { useState } from "react"

export default function SnowModeSelector() {
  const [snow, setSnow] = useState(localStorage.snow || "false")

  const handleClick = (mode) => {
    localStorage.setItem("snow", mode)
    window.dispatchEvent(new Event("storage"))
    setSnow(localStorage.snow || "true")
  }

  return (
    <div className="rounded-md flex min-w-full h-16 pb-2 px-2 justify-center">
      <button
        type="button"
        className={`${
          snow === "true" ? "dark:bg-gray-500 bg-gray-200" : ""
        } w-1/2  items-center rounded-l-md border border-gray-300 px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-700 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-0 focus:ring-indigo-500`}
        onClick={() => handleClick("true")}
      >
        <div>Snow</div> ⛄️
      </button>
      <button
        type="button"
        className={`${
          snow === "false" ? "dark:bg-gray-500 bg-gray-200" : ""
        } w-1/2 -ml-px  items-center rounded-r-md border border-gray-300 px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-700 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-0 focus:ring-indigo-500`}
        onClick={() => handleClick("false")}
      >
        No Snow 🌞
      </button>
    </div>
  )
}
