interface Props {
  setOpen
  setDefaultFilterAndSort
  changePractice
}

export function BookmarkModal(props: Props) {
  const changePractice = async () => {
    await props.changePractice()
    props.setOpen(false)
  }

  const setDefaultFilterAndSort = async () => {
    props.setDefaultFilterAndSort()
    props.setOpen(false)
  }
  return (
    <>
      <div
        className="fixed flex left-0 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full bg-gray-800"
        id="bookmark-modal"
      >
        <div className="relative w-full h-full max-w-2xl px-4 md:h-auto bg-gray-800">
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
            {/* <!-- Modal header --> */}
            <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
              <h3 className="text-xl font-semibold dark:text-white">
                The provided url contains a bookmark filter that is invalid at
                this practice.
              </h3>
            </div>
            {/* <!-- Modal body --> */}
            <div className="p-6 space-y-6">
              <div>
                <p>
                  You can either load the default bookmark filter for this
                  practice, or switch to the practice that supports the bookmark
                  filter.
                </p>
              </div>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-6 ">
                  <button
                    onClick={() => changePractice()}
                    onKeyUp={() => changePractice()}
                    type="button"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Change Practice and Load the Provided Bookmark Filter
                  </button>
                </div>
                <div className="col-span-6 sm:col-span-6">
                  <button
                    onClick={() => setDefaultFilterAndSort()}
                    onKeyUp={() => setDefaultFilterAndSort()}
                    type="button"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Load the Default Bookmark Filters at the Current Practice
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
