import { AxiosError } from "axios"
import { useState } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useIndex } from "../shared/use-rest"
import { HeroIcon } from "./hero-icon"

interface Props {
  // TODO types, moving quickly
  setOpen
  handleAssign
  handleArchive
}

export function BulkAssignModal(props: Props) {
  const { data: users } = useIndex("users")
  const { register, handleSubmit } = useForm()
  const [assigning, setAssigning] = useState(false)
  const [confirmingArchive, setConfirmingArchive] = useState(false)
  const [responseMessage, setResponseMessage] = useState("")

  const submitAssigned = (data) => {
    const response = props.handleAssign(data.assigned_to_user_id)
    setAssigning(false)
  }

  const submitArchive = async () => {
    const response = await props.handleArchive().catch((error: AxiosError) => {
      toast(`⚠️ ${error.response.data.message}`)
      setConfirmingArchive(false)
      if (error instanceof Error) {
        console.error("Error with fetching ..., details: ", error)
      }
    })
    // if (response?.message) {
    //   setResponseMessage(response?.message)
    // }
  }

  return (
    <>
      <div
        className="fixed flex bottom-0 right-[30%] z-50 items-end overflow-x-hidden overflow-y-auto h-modal sm:h-[8.5%] sm:w-[40%]"
        id="add-user-modal"
      >
        <div className=" relative w-full h-full max-w-2xl">
          {/* <!-- Modal content --> */}
          <div className="relative border bg-gray-200 dark:bg-gray-900 rounded-lg">
            <div className="text-sm p-3 flex items-center justify-between">
              {/* <div className={`${assigning ? 'hidden' : 'hidden'} ${confirmingArchive ? 'opacity-0' : ''}`}>
              <button
                  onClick={() => {setAssigning(true); setConfirmingArchive(false)}}
                  className={`flex ml-2 border border-topo-green-200 bg-transparent hover:bg-topo-green-100 dark:hover:bg-green-200 hover:bg-opacity-50 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 text-topo-green text-xs font-medium mr-6 px-2.5 py-2 rounded-md items-center justify-between`}
                  title={screen?.study?.abbreviation}
                >
                  Assign
                  <HeroIcon
                    icon="UserAddIcon"
                    className="ml-1 w-5 h-5 flex"
                    />
                </button>
              </div> */}
              <div className={`items-center ${assigning ? "flex" : "hidden"}`}>
                <form className="flex" onSubmit={handleSubmit(submitAssigned)}>
                  <select
                    className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm hover:border-gray-800 dark:bg-gray-800 dark:hover:border-gray-600"
                    {...register("assigned_to_user_id")}
                    id="assigned_to_user_id"
                  >
                    <option value="">None</option>
                    {users.map((user) => (
                      <option key={user.id} value={user.id} className="text-sm">
                        {user.name}
                      </option>
                    ))}
                  </select>
                  <button
                    type="submit"
                    className="ml-2 border border-topo-green-200 bg-transparent hover:bg-topo-green-100 dark:hover:bg-green-200 hover:bg-opacity-50 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 text-topo-green text-xs font-medium mr-2 px-2.5 py-2 rounded-md flex items-center justify-between"
                    title={screen?.study?.abbreviation}
                  >
                    Save
                    <HeroIcon
                      icon="CheckCircleIcon"
                      className="ml-1 w-5 h-5 flex"
                    />
                  </button>
                </form>
                <button
                  type="button"
                  onClick={() => setAssigning(false)}
                  className="border border-gray-200 bg-transparent hover:bg-gray-100 dark:hover:bg-gray-200 hover:bg-opacity-50 focus:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2 text-gray-500 text-xs font-medium mr-6 px-2.5 py-2 rounded-md flex items-center justify-between"
                  title={screen?.study?.abbreviation}
                >
                  Cancel
                  <HeroIcon
                    icon="XCircleIcon"
                    className="ml-1 w-5 h-5 flex text-gray"
                  />
                </button>
              </div>
              {responseMessage}
              <div className={assigning ? "hidden" : "flex"}>
                <div className={confirmingArchive ? "hidden" : "flex"}>
                  <button
                    type="button"
                    onClick={() => {
                      setResponseMessage("")
                      setConfirmingArchive(true)
                    }}
                    className="border border-red-200 bg-transparent hover:bg-red-100 dark:hover:bg-red-200 hover:bg-opacity-50 focus:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-800 focus:ring-offset-2 text-red-500 text-xs font-medium mr-6 px-2.5 py-2 rounded-md flex items-center justify-between"
                    title={screen?.study?.abbreviation}
                  >
                    Archive
                    <HeroIcon
                      icon="FolderRemoveIcon"
                      className="ml-1 w-5 h-5 flex text-red"
                    />
                  </button>
                </div>
                <div className={confirmingArchive ? "flex" : "hidden"}>
                  <button
                    type="button"
                    onClick={() => submitArchive()}
                    className="border border-red-200 bg-transparent hover:bg-red-100 dark:hover:bg-red-200 hover:bg-opacity-50  focus:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-800 focus:ring-offset-2 text-red-500 text-xs font-medium mr-6 px-2.5 py-2 rounded-md flex items-center justify-between"
                    title={screen?.study?.abbreviation}
                  >
                    Confirm
                    <HeroIcon
                      icon="ShieldExclamationIcon"
                      className="ml-1 w-5 h-5 flex text-red"
                    />
                  </button>
                  <button
                    type="button"
                    onClick={() => setConfirmingArchive(false)}
                    className="border border-gray-200 bg-transparent hover:bg-gray-100 dark:hover:bg-gray-200 hover:bg-opacity-50 focus:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2 text-gray-500 text-xs font-medium mr-6 px-2.5 py-2 rounded-md flex items-center justify-between"
                    title={screen?.study?.abbreviation}
                  >
                    Cancel
                    <HeroIcon
                      icon="XCircleIcon"
                      className="ml-1 w-5 h-5 flex text-gray"
                    />
                  </button>
                </div>
              </div>
            </div>

            {/* <!-- Modal footer --> */}
            {/* <div className="items-center p-4 border-t border-gray-200 rounded-b">
              <button
                className="text-white bg-topo-blue hover:bg-blue-800 focus:ring-4 focus:ring-topo-green-600 font-medium rounded-lg text-sm mr-5 px-5 py-2.5 text-center"
                onClick={() => handleClose()}
              >
                Resume Screening
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}
