import { useAuth0 } from "@auth0/auth0-react"
import { XCircleIcon } from "@heroicons/react/solid"
import LinearProgress from "@mui/material/LinearProgress"
import {
  Button,
  Drawer,
  Spinner,
  Tabs,
  type TabsRef,
  Tooltip,
} from "flowbite-react"
import { useContext, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { SyncLoader } from "react-spinners"
import { HeroIcon } from "../../components/hero-icon"
import { AuthorizationContext } from "../../shared/authorization-provider"
import { event } from "../../shared/event"
import { useCreate } from "../../shared/use-rest"
import { useIndexQuery, useShowQuery } from "../../shared/use-rest-query"
import { ChatBubble } from "./chat-bubble"

export function ChatDrawer(props) {
  const { isOpen, setIsOpen, patientId } = props
  const {
    data: { data: patient },
    isFetching: isFetchingPatient,
    isLoading,
    isError,
  } = useShowQuery("patients", patientId)
  const { getAccessTokenSilently } = useAuth0()
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm()
  const { currentUser, practiceId } = useContext(AuthorizationContext)
  const phone = patient?.preferred_phone || patient?.phone
  const tabsRef = useRef<TabsRef>(null)
  const prevMessagesRef = useRef()

  const [activeTab, setActiveTab] = useState(0)

  const onSubmit = async (data) => {
    data.patient_id = props.patientId

    await useCreate("secure_messages", data, getAccessTokenSilently)
    event({
      eventName: "PatientSecureMessageCreate",
      meta: {
        patient_id: patientId,
        element: "ChatDrawer",
      },
      getAccessTokenSilently,
    })
    // secureMessagesMutate()
    reset()
    // wait for paubox
    setTimeout(refetch, 500)
    // callback
    props.onMessageSent()
  }

  const {
    isFetching: isFetchingSecureMessages,
    isRefetching: isRefetchingSecureMessages,
    data: { data: messages },
    refetch,
  } = useIndexQuery(
    `secure_messages?patient_id=${patientId}`,
    null,
    {},
    { refetchOnWindowFocus: true, refetchInterval: 5000 },
  )

  const handleClose = () => setIsOpen(false)

  const messagesEndRef = useRef(null)

  // biome-ignore lint/correctness/useExhaustiveDependencies: is open should retrigger fetch
  useEffect(() => {
    refetch()
  }, [isOpen])

  // biome-ignore lint/correctness/useExhaustiveDependencies: auto scroll on messages change
  useEffect(() => {
    // Scroll to the bottom of the element whenever tab is opened
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" })
  }, [isOpen, activeTab])

  useEffect(() => {
    // Check if messages have actually changed
    if (JSON.stringify(prevMessagesRef?.current) !== JSON.stringify(messages)) {
      messagesEndRef?.current?.scrollIntoView({ behavior: "auto" })
      // Update the ref with the new messages
      prevMessagesRef.current = messages
    }
  }, [messages])

  useEffect(() => {
    if (activeTab === 0) {
      reset({ phone_number: patient?.preferred_phone || patient?.phone })
    } else if (activeTab === 1) {
      reset({ phone_number: patient?.phone })
    }
  }, [patient, activeTab, reset])

  const displayChat = (phoneType, tabIndex) => {
    return (
      <div className="space-y-6 overflow-auto max-h-[80vh]">
        {Object.entries(messages)
          .filter(([key]) => key.includes(patient?.[phoneType]))
          .map(([key, messageList]) => (
            <div key={`message-tab-${key}`} className="space-y-6">
              {messageList
                ?.sort(
                  (a, b) => new Date(a.created_at) - new Date(b.created_at),
                )
                .map((message) => (
                  <ChatBubble
                    key={message?.id}
                    message={message}
                    patient={patient}
                  />
                ))}
            </div>
          ))}
        {activeTab === tabIndex && <div ref={messagesEndRef} />}
      </div>
    )
  }
  return (
    <>
      <Drawer
        open={isOpen}
        onClose={handleClose}
        position="right"
        className="w-1/5"
        backdrop={false}
      >
        <Drawer.Items>
          {/* Drawer Header */}
          <div className="flex items-center mb-4">
            <h3 className="flex-1 text-lg font font-semibold">Messages</h3>
            <div
              className="flex h-5 hover:cursor-pointer"
              onClick={handleClose}
              onKeyUp={handleClose}
            >
              <XCircleIcon />
            </div>
          </div>
          <Tabs
            aria-label="Default tabs"
            // variant="default"
            ref={tabsRef}
            onActiveTabChange={(tab) => setActiveTab(tab)}
          >
            {patient?.preferred_phone && (
              <Tabs.Item
                active
                title={
                  <Tooltip content={"Preferred Phone"}>
                    <div className="flex items-center space-x-2">
                      <HeroIcon icon="StarIcon" className="ml-1 w-5 h-5 flex" />
                      <span>{patient?.preferred_phone || ""}</span>
                    </div>
                  </Tooltip>
                }
              >
                {displayChat("preferred_phone", 0)}
              </Tabs.Item>
            )}

            {patient?.phone && (
              <Tabs.Item title={patient?.phone || ""}>
                {displayChat("phone", patient?.preferred_phone ? 1 : 0)}
              </Tabs.Item>
            )}
          </Tabs>
          {/* Message Form */}
          {false && !patientId && (
            <div>
              <form>
                <input
                  className="mr-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700"
                  type="text"
                />
                <button
                  type="submit"
                  className="align-middle bg-[#0227FB] hover:bg-blue-800 rounded focus:ring-blue-300 h-10 p-2 rotate-90"
                >
                  <HeroIcon className="h-5 w-5 text-white" icon="SearchIcon" />
                </button>
              </form>
            </div>
          )}
          {/* Message Form */}
          <div className="mt-4">
            {phone && (
              <div className="text-sm grey-400 flex">
                Sending to{" "}
                {activeTab === 0
                  ? patient?.preferred_phone || patient?.phone
                  : patient?.phone}
                <div className="text-xs mb-2 flex justify-end h-4">
                  <div className="px-2">
                    {(isFetchingSecureMessages ||
                      isRefetchingSecureMessages ||
                      isFetchingPatient) && <SyncLoader size={5} />}
                  </div>
                </div>
              </div>
            )}
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-1">
              <input type="hidden" {...register("phone_number")} />
              <textarea
                {...register("message")}
                rows={1}
                disabled={isSubmitting}
                required
                className="p-2 w-full self-center min-h-[2.3rem] text-sm bg-white dark:bg-gray-800 border border-gray-200 shadow-sm rounded-md mr-2"
              />
              <button
                type="submit"
                disabled={isSubmitting}
                className="align-middle bg-[#0227FB] hover:bg-blue-800 rounded focus:ring-blue-300 h-10 p-2 rotate-90"
              >
                {isSubmitting && <Spinner size={"sm"} />}
                <HeroIcon
                  className="h-5 w-5 text-white"
                  icon="PaperAirplaneIcon"
                />
              </button>
            </form>
          </div>
          <div className="flex mt-2 text-sm font-medium text-center text-gray-500 border-t border-gray-200 dark:text-gray-400 dark:border-gray-700">
            {patient?.preferred_phone && (
              <Tooltip content={"Preferred Phone"}>
                <button
                  type="button"
                  className={`inline-flex p-4 rounded-b-lg ${
                    activeTab === 0
                      ? "text-black bg-gray-100 active dark:bg-gray-800 dark:text-gray-300"
                      : "hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                  }`}
                  onClick={() => tabsRef?.current?.setActiveTab(0)}
                >
                  <HeroIcon icon="StarIcon" className="ml-1 w-5 h-5 flex" />
                  {patient?.preferred_phone || ""}
                </button>
              </Tooltip>
            )}
            {patient?.phone && (
              <button
                type="button"
                className={`inline-block p-4 rounded-b-lg ${
                  activeTab === 1 || patient?.preferred_phone === null
                    ? "text-black bg-gray-100 active dark:bg-gray-800 dark:text-gray-300"
                    : "hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                }`}
                onClick={() =>
                  tabsRef?.current?.setActiveTab(
                    patient?.preferred_phone ? 1 : 0,
                  )
                }
              >
                {patient?.phone || ""}
              </button>
            )}
          </div>
        </Drawer.Items>
      </Drawer>
    </>
  )
}
