import {
  BeakerIcon,
  ClipboardListIcon,
  ExclamationIcon,
  RefreshIcon,
  UserGroupIcon,
} from "@heroicons/react/solid"
import React from "react"

const ErrorScreen = () => {
  return (
    <div className="flex flex-col items-center justify-center space-x-8 m-20 bg-gray-100 px-4">
      <div className="text-center max-w-2xl">
        <ExclamationIcon className="mx-auto h-16 w-16 text-yellow-400" />
        <h2 className="mt-2 text-2xl font-bold text-gray-900">
          Failed to load
        </h2>
        <p className="mt-4 text-base text-gray-600 leading-relaxed">
          We apologize, but an error occurred while attempting to load this
          page. Please check that your VPN is connected and refresh the page.
        </p>
        <p className="mt-2 text-base text-gray-600 leading-relaxed">
          If the problem persists, please contact{" "}
          <a
            href="slack://app?team=TRG68NXEU&id=C036RCSHPPC"
            className="font-semibold"
          >
            #product-feedback
          </a>{" "}
          on Slack.
        </p>
      </div>

      <button
        type="button"
        onClick={() => window.location.reload()}
        className="mt-12 px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300 flex items-center"
      >
        <RefreshIcon className="h-5 w-5 mr-2" />
        Refresh Page
      </button>
    </div>
  )
}

export default ErrorScreen
