import { formatInTimeZone, utcToZonedTime } from "date-fns-tz"
import { ageCalculator } from "../../shared/age-calculator"
import { dobCalculator } from "../../shared/dob-calculator"
import { snakeToTitle } from "../../shared/text-utils"

const convertOperatorToSymbol = (operator) => {
  const operatorsTable = {
    contains: "∋",
    equals: "=",
    not_equals: "≠",
    starts_with: "^",
    ends_with: "$",
    is_empty: "∅",
    is_not_empty: "!∅",
    is: "≡",
    not: "≢",
    is_after: ">",
    after: ">",
    onOrAfter: "≥",
    is_before: "<",
    before: "<",
    onOrBefore: "≤",
    isEmpty: "∅",
    isNotEmpty: "!∅",
    gt: ">",
    lt: "<",
    gte: "≥",
    lte: "≤",
    in: "∈",
    is_any_of: "∈",
  }

  return operatorsTable[operator]
}

const convertOperatorAgeToSymbol = (operator) => {
  const operatorsTable = {
    contains: "∋",
    equals: "=",
    not_equals: "≠",
    starts_with: "^",
    ends_with: "$",
    is_empty: "∅",
    is_not_empty: "!∅",
    is: "≡",
    not: "≢",
    is_after: "<",
    after: "<",
    onOrAfter: "≤",
    is_before: ">",
    before: ">",
    onOrBefore: "≥",
    isEmpty: "∅",
    isNotEmpty: "!∅",
    gt: "<",
    lt: ">",
    gte: "≤",
    lte: "≥",
    in: "∈",
    is_any_of: "∈",
  }

  return operatorsTable[operator]
}

const displayAgeFilter = (item) => {
  return `Age ${convertOperatorAgeToSymbol(item.operator_value)} ${ageCalculator(item.value)}`
}

export const displayFilters = (item) => {
  if (item.field === "dob") {
    return displayAgeFilter(item)
  }
  if (item.field === "review_status" && item.value === null) {
    return `${snakeToTitle(item.field)} ${convertOperatorToSymbol(item.operator_value)} Unreviewed`
  }
  if (item.field === "appointment_start_dt") {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return `${snakeToTitle(item.field)} ${convertOperatorToSymbol(item.operator_value)} ${formatInTimeZone(item.value, browserTimezone, "yyyy-MM-dd h:mma z")}`
  }
  return `${snakeToTitle(item.field)} ${convertOperatorToSymbol(item.operator_value)} ${item.value}`
}
