import {
  DataGrid,
  type GridCellParams,
  type GridColDef,
  type GridRowsProp,
  type MuiEvent,
} from "@mui/x-data-grid"
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro"
import { useQueryClient } from "@tanstack/react-query"
import { useRef, useState } from "react"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { ActionHeader } from "../../../../components/action-header"
import { ButtonLeadingIcon } from "../../../../components/button-leading-icon"
import { HeroIcon } from "../../../../components/hero-icon"
import SplashScreen from "../../../../components/splash-screen"
import { friendlyDateTime } from "../../../../shared/friendly-dates"
import { useIndex } from "../../../../shared/use-rest"
import { useIndexQuery } from "../../../../shared/use-rest-query"
import { PracticeUserEditModal } from "./edit-modal"
import { PracticeUserNewModal } from "./new-modal"

export function PracticeUsersIndex(props) {
  const [practiceUser, setPracticeUser] = useState()
  const userId = props.userId

  const [newUserModalOpen, setNewUserModalOpen] = useState(false)
  const [editUserModalOpen, setEditUserModalOpen] = useState(false)

  const {
    data: { data: practice_users },
    isFetching,
    isError,
  } = useIndexQuery("admin/practice_users", `user_id=${userId}`)

  const queryClient = useQueryClient()
  const mutatePracticeUsers = () => {
    queryClient.invalidateQueries({
      queryKey: ["admin/practice_users", `user_id=${userId}`],
    })
  }

  const navigate = useNavigate()

  const handleShowUser = (userId) => navigate(`/admin/users/${userId}`)

  const rows: GridRowsProp = practice_users
  const columns: GridColDef[] = [
    {
      field: "practice_name",
      headerName: "Practice",
      width: 150,
      display: "flex",
      renderCell: (params) => <>{params.row.practice?.name}</>,
    },
    {
      field: "practice_abbreviation",
      headerName: "Abbr.",
      width: 100,
      display: "flex",
      renderCell: (params) => <>{params.row.practice?.abbreviation}</>,
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      display: "flex",
      renderCell: (params) => <>{params.row.user?.name}</>,
    },
    {
      field: "task_user_xref_id",
      headerName: "Monday ID",
      width: 150,
      display: "flex",
      renderCell: (params) => <>{params.row.user?.task_user_xref_id}</>,
    },
    {
      field: "phone_extension_xref_id",
      headerName: "Extension",
      width: 120,
      display: "flex",
    },
    {
      field: "role",
      headerName: "Role",
      width: 150,
      display: "flex",
    },
    {
      field: "email",
      headerName: "Email",
      width: 180,
      display: "flex",
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 120,
      display: "flex",
    },
    {
      field: "created_at",
      headerName: "Created",
      width: 180,
      display: "flex",
      renderCell: (params) => friendlyDateTime(params.row.created_at),
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 180,
      display: "flex",
      renderCell: (params) => friendlyDateTime(params.row.updated_at),
    },
  ]

  return (
    <>
      <div className="mt-2">
        <div className="flex justify-between mb-2">
          <h3 className="font-bold text-lg">Practice Users</h3>
        </div>
        {isFetching || isError ? (
          <SplashScreen isLoading={isFetching} isError={isError} />
        ) : (
          <div className="mt-2 flex min-h-[200px]">
            <div className="flex-grow">
              <DataGridPro
                rows={rows}
                columns={columns}
                onCellDoubleClick={(
                  params: GridCellParams,
                  event: MuiEvent<MouseEvent>,
                ) => {
                  event.defaultMuiPrevented = true
                  handleShowUser(userId)
                }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}
