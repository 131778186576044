import { titleCase } from "change-case-all"

export const snakeToTitle = (input: string): string => {
  if (!input) return ""
  return titleCase(input?.split("_")?.join(" "))
}

export const nameToInitials = (name: string): string => {
  if (!name) return ""
  const initials = `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
  return initials
}

// https://stackoverflow.com/a/65003355/1096451
export const toSnakeCase = (input: string) => {
  return (
    input.charAt(0).toLowerCase() +
    input
      .slice(1) // Lowercase the first character
      .replace(/\W+/g, " ") // Remove all excess white space and replace & , . etc.
      .replace(/([a-z])([A-Z])([a-z])/g, "$1 $2$3") // Put a space at the position of a camelCase -> camel Case
      .split(/\B(?=[A-Z]{2,})/) // Now split the multi-uppercases customerID -> customer,ID
      .join(" ") // And join back with spaces.
      .split(" ") // Split all the spaces again, this time we're fully converted
      .join("_") // And finally snake_case things up
      .toLowerCase()
  ) // With a nice lower case
}
