export const disqualificationReasons = [
  "Patient unreachable or no-show",
  "Patient not interested in trial - distance",
  "Patient not interested in trial - safety concerns",
  "Patient not interested in trial - not available",
  "Patient not interested in trial - other",
  "Patient missing inclusion criteria",
  "Patient fails exclusion criteria - diagnosis",
  "Patient fails exclusion criteria - medication",
  "Patient fails exclusion criteria - other",
  "Patient enrolled in a different trial",
  "Doctor disqualified patient",
  "Patient unreliable",
  "Patient speaks another language not supported by trial or site staff",
  "Other",
]


