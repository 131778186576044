import {
  DataGridPro,
  type GridCellParams,
  type GridColDef,
  type GridRowsProp,
  GridToolbar,
  type MuiEvent,
} from "@mui/x-data-grid-pro"
import { useState } from "react"
import { ActionHeader } from "../../../../components/action-header"
import { Button } from "../../../../components/button"
import { ButtonLeadingIcon } from "../../../../components/button-leading-icon"
import SplashScreen from "../../../../components/splash-screen"
import { friendlyDateTime } from "../../../../shared/friendly-dates"
import { useIndex } from "../../../../shared/use-rest"
import { TeamEditModal } from "./edit-modal"
import { TeamNewModal } from "./new-modal"

export function PracticeTeamXrefs() {
  const team_xrefs = {
    practiceTeamXrefs: useIndex("admin/practice_team_xrefs"),
  }

  const [newModalOpen, setNewModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [entityId, setEntityId] = useState("")
  const [entityType, setEntityType] = useState("")
  const rows: GridRowsProp = [...team_xrefs.practiceTeamXrefs.data]
  const columns: GridColDef[] = [
    {
      field: "practice",
      headerName: "Practice",
      width: 150,
      renderCell: (params) => <>{params.row.practice?.name}</>,
    },
    { field: "name", headerName: "Name", width: 150 },
    { field: "description", headerName: "Description", width: 200 },
    { field: "abbreviation", headerName: "Abbr.", width: 100 },
    { field: "xref_id", headerName: "Xref ID", width: 120 },
    { field: "team_type", headerName: "Team Type", width: 120 },
    { field: "vendor_code", headerName: "Vendor", width: 120 },
    {
      field: "created_at",
      headerName: "Created",
      width: 120,
      renderCell: (params) => friendlyDateTime(params.row.created_at),
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 120,
      renderCell: (params) => friendlyDateTime(params.row.updated_at),
    },
  ]

  return (
    <>
      <div className="flex justify-between items-center">
        <h2 className="text-2xl sm:text-3xl  p-2 mb-2 font-bold text-gray-900 dark:text-white">
          Practice TeamXrefs
        </h2>
        <div
          onClick={() => setNewModalOpen(true)}
          onKeyUp={() => setNewModalOpen(true)}
        >
          <ButtonLeadingIcon
            icon="PlusCircleIcon"
            text="Add Practice Team"
            className="inline-flex items-center justify-center h-10 w-1/2 px-3 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300 sm:w-auto dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          />
        </div>
      </div>
      {team_xrefs?.practiceTeamXrefs?.isLoading ||
      team_xrefs?.practiceTeamXrefs?.isError ? (
        <SplashScreen
          isLoading={team_xrefs?.practiceTeamXrefs?.isLoading}
          isError={team_xrefs?.practiceTeamXrefs?.isError}
        />
      ) : (
        <div>
          <div className="mt-2 flex">
            <div className="flex-grow">
              <DataGridPro
                rows={rows}
                columns={columns}
                onCellDoubleClick={(
                  params: GridCellParams,
                  event: MuiEvent<MouseEvent>,
                ) => {
                  event.defaultMuiPrevented = true
                  setEntityId(params.row.id)
                  setEntityType(
                    params.row.practice_id !== undefined
                      ? "practice_team_xref"
                      : "team_xref",
                  )
                  setEditModalOpen(true)
                }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}
      {newModalOpen && (
        <TeamNewModal
          setOpen={setNewModalOpen}
          refreshData={() => team_xrefs?.practiceTeamXrefs?.mutate()}
        />
      )}
      {editModalOpen && entityId && entityType && (
        <TeamEditModal
          id={entityId}
          entity_key={entityType}
          setOpen={setEditModalOpen}
          refreshData={() => team_xrefs?.practiceTeamXrefs?.mutate()}
        />
      )}
    </>
  )
}
