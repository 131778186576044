import { useAuth0 } from "@auth0/auth0-react"
import { Dispatch, SetStateAction, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { KeyedMutator } from 'swr/dist/types'
import { AuthorizationContext } from "../../shared/authorization-provider"
import { event } from "../../shared/event"
import { messagingEnabled } from "../../shared/feature-flag-provider"
import { friendlyDateTime } from '../../shared/friendly-dates'
import { statusOptions } from '../../shared/statuses'
import { useCreate, useUpdate } from '../../shared/use-rest'
import { HeroIcon } from '../hero-icon'

  interface Props {
    patientId: string
    patient: {}
    contacts: []
    contactModalEditingId: string
    contactDispositionOpen: boolean
    setContactModalOpen: Dispatch<SetStateAction<boolean>>
    setContactDispositionOpen: Dispatch<SetStateAction<boolean>>
    setChatDrawerIsOpen: Dispatch<SetStateAction<boolean>>
    setContactModalEditingId: Dispatch<SetStateAction<string>>
    mutateContacts: KeyedMutator<any>
  }

  const iconForContactType = (contactType) =>{
    switch (contactType) {
    case "phone_call":
      return "PhoneIcon"
    case "in_person":
      return "UsersIcon"
    case "sms":
      return "ChatAltIcon"
    case "email":
      return "AtSymbolIcon"
    case "postcard_letter":
      return "MailOpenIcon"
    default:
      return "AnnotationIcon"
    }
  }

  export function PatientInfoSidebarContacts(props: Props) {
    const { screenId } = useParams()
    const { getAccessTokenSilently } = useAuth0()
    const handleEdit = (contactId) => {
      props.setContactModalEditingId(contactId)
      props.setContactModalOpen(true)
    }

    const handleMakeCall = async (props) => {
      await useCreate("contacts", {
        screen_id: screenId,
        patient_id: props.patientId,
        contact_type: "phone_call",
        contact_reason: "",
        contact_length: "",
        disposition: "",
        contacted_at: new Date().toISOString().slice(0, -1),
      }, getAccessTokenSilently).then(
        (response) => {
          props.setContactModalEditingId(response?.data.data?.id)
          event({
            eventName: "ContactSave",
            meta: {
              patient_id: props.patientId,
              screen_id: props.screenId,
              contact_id: response?.data?.data?.id,
            },
            getAccessTokenSilently,
          })
        },
      )
      props.mutateContacts()
      props.setContactDispositionOpen(true)
    }

    const handleIDidntMakeACall = async (props) => {
      props.setContactDispositionOpen(false)
      if(props.contactModalEditingId) {
        await useUpdate(
          "contacts",
          props.contactModalEditingId,
          {
            deleted_at: new Date().toISOString().slice(0, -1),
          }, getAccessTokenSilently).then(
          (response) => {
              props.setContactModalEditingId("")
              event({
                eventName: "ContactDelete",
                meta: {
                  patient_id: props.patientId,
                  screen_id: props.screenId,
                  contact_id: response?.data?.data?.id,
                },
                getAccessTokenSilently,
              })
            },
          )
        props.mutateContacts()

      }
    }
    const { practiceName } = useContext(AuthorizationContext)


    const handleIMadeACall = async (props) => {
      props.setContactModalOpen(true)
      props.setContactDispositionOpen(false)
    }

    return (
      <div className='p-4 mb-4 rounded-lg border border-gray-200 shadow-md text-gray-700 bg-white dark:bg-transparent dark:text-gray-200'>
        <div className={"sm:flex xl:block sm:space-x-4 xl:space-x-0"}>
          <div>
            <div className='flex justify-between'>
              <h3 className="mb-2 text-base font-bold text-gray-900 dark:text-gray-200">Contact Log</h3>
            </div>
          </div>
        </div>
        <div className='flex flex-row mb-4'>
          <a href={`tel:${props.patient?.preferred_phone || props.patient?.phone}`}>
            <button
                onClick={()=>handleMakeCall(props)}
                className=" text-white bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 rounded font-semibol text-xs px-3.5 py-1.5 text-center inline-flex items-center"
              >
                <HeroIcon
                  icon="PhoneIcon"
                  className="fill-white w-4 h-4 mr-2"
                />
                {props.patient?.preferred_phone || props.patient?.phone ? 'Call' : 'No Phone'}
              </button>
          </a>
          {messagingEnabled(practiceName) && <button
              onClick={() => props.setChatDrawerIsOpen(true)}
              className=" ml-2 text-white bg-topo-navy hover:bg-topo-navy focus:bg-topo-navy focus:outline-none focus:ring-2 focus:ring-topo-navy-800 focus:ring-offset-2 rounded font-semibol text-xs px-2.5 py-1.5 text-center inline-flex items-center"
              >
              <HeroIcon
                icon="ChatIcon"
                className="fill-white w-4 h-4 mr-2"
                />
              Message
          </button>}
          <button
              onClick={() => props.setContactModalOpen(true)}
              className=" ml-2 text-white bg-topo-navy hover:bg-topo-navy focus:bg-topo-navy focus:outline-none focus:ring-2 focus:ring-topo-navy-800 focus:ring-offset-2 rounded font-semibol text-xs px-2.5 py-1.5 text-center inline-flex items-center"
              >
              <HeroIcon
                icon="PlusCircleIcon"
                className="fill-white w-4 h-4 mr-2 rotate-90"
                />
              Log Contact
          </button>
        </div>
        {props.contactDispositionOpen && (
          <div className="mt-4 mb-2 bg-yellow-100 rounded p-2 pl-2 pt-2 shadow-md">
            <p className="font-medium text-yellow-700">Just made a call?</p>
            <p className="text-yellow-700 mb-2">
              Please fill out this call log before you move onto another patient.
            </p>
            <div className="flex flex-row">
              <button
                type="button"
                className={
                  "inline-flex items-center p-2 pr-4 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-yellow-700 bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                }
                onClick={() => handleIMadeACall(props)}
              >
                <HeroIcon
                  icon="EyeIcon"
                  className={"h-5 w-7 mr-1 text-yellow-700"}
                  outline
                />
                Update Call Info
              </button>
              <button
                type="button"
                className={
                  "ml-2 inline-flex items-center p-2 pr-4 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-red-700 bg-red-300 hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                }
                onClick={() => handleIDidntMakeACall(props)}
              >
                <HeroIcon
                  icon="EyeIcon"
                  className={"h-5 w-7 mr-1 text-red-700"}
                  outline
                />
                I Didn't Make a Call
              </button>
            </div>
          </div>
        )}
        { props.contacts?.length == 0 &&
          <div className='text-xs mb-2'>
            No Contacts Logged
            <div className='mt-2 underline'>
              {/* <Link to={`/patients/${props.patientId}`}>View All</Link> */}
            </div>
          </div>
        }

        { props.contacts?.map((contact, index) =>
          <div key={index} className='flex flex-row mb-2 border-b border-gray-200 dark:border-gray-800 bg-gray-50  dark:bg-gray-700 dark:opacity-95 rounded p-1 pl-0 pt-2'>
            <div className='block w-full'>
              <div className="flex justify-between">
                <div className="text-xs mb-1 font-medium mr-2 px-2.5 py-0.5 rounded-md whitespace-nowrap flex"> <HeroIcon icon="UserIcon" outline className="h-4 mr-2 rounded-md" />{contact?.created_by?.name}</div>
                <button onClick={() => handleEdit(contact.id)} className={`mr-2 align-middle group-hover:inline-block hover:text-gray-500`}>
                    <HeroIcon className='h-4 w-4' icon="PencilAltIcon" />
                  </button>
              </div>
              <div className="text-xs mb-1 font-medium mr-2 px-2.5 py-0.5 rounded-md whitespace-nowrap flex"> <HeroIcon icon="ClockIcon" outline className="h-4 mr-2 rounded-md" />{friendlyDateTime(contact.contacted_at)}</div>
              <div className="text-xs mb-1 font-medium mr-2 px-2.5 py-0.5 rounded-md whitespace-nowrap flex"> <HeroIcon icon={iconForContactType(contact.contact_type)} outline className="h-4 mr-2 rounded-md" /> {contact.contact_reason}{contact.contact_reason && ' - '}{contact.disposition}</div>
              {contact?.screen && <div className="text-xs mb-1 font-medium mr-2 px-2.5 py-0.5 rounded-md whitespace-nowrap flex "><HeroIcon icon={statusOptions[contact?.screen?.status]?.icon || 'DotsHorizontalIcon'} outline className="h-4 mr-2 rounded-md"/><Link to={`/patients/${props.patientId}/screens/${contact.screen_id}`}>{contact?.screen?.study?.abbreviation}</Link></div> }
            </div>
          </div>
        )}
      </div>
    )
  }
