import { snakeToTitle } from "./text-utils"

// statusOptions used in:
// dashboard-converstion-dynamics-sankey-chart
// patient-info-sidebar-contacts
// patient-screen
// study-screen-status-chips

// TODO: make this typed so icon autocompletes
export const statusOptions = {
  assign: {
    textColor: "text-topo-blue-800",
    borderColor: "border-topo-blue-800",
    bgColor: "bg-blue-100",
    acronym: "A",
    strokeColor: "stroke-gray-600",
    icon: "UserAddIcon",
    fillColor: "fill-gray-600",
  },
  provider_referral: {
    textColor: "text-topo-blue-800",
    borderColor: "border-topo-blue-800",
    bgColor: "bg-blue-100",
    acronym: "PR",
    strokeColor: "stroke-gray-600",
    icon: "UserAddIcon",
    fillColor: "fill-gray-600",
  },
  chart_review: {
    textColor: "text-topo-blue-800",
    borderColor: "border-topo-blue-800",
    bgColor: "bg-blue-100",
    acronym: "CR",
    strokeColor: "stroke-green-100",
    icon: "DocumentTextIcon",
    fillColor: "fill-green-100",
  },
  provider_feedback: {
    textColor: "text-topo-blue-800",
    borderColor: "border-topo-blue-800",
    bgColor: "bg-blue-100",
    acronym: "PF",
    strokeColor: "stroke-fuchsia-100",
    icon: "QuestionMarkCircleIcon",
    fillColor: "fill-fuchsia-100",
  },
  standard_of_care: {
    textColor: "text-topo-blue-800",
    borderColor: "border-topo-blue-800",
    bgColor: "bg-blue-100",
    acronym: "SOC",
    strokeColor: "stroke-fuchsia-100",
    icon: "ClipboardCheckIcon",
    fillColor: "fill-fuchsia-100",
  },
  phone_call: {
    textColor: "text-topo-blue-800",
    borderColor: "border-topo-blue-800",
    bgColor: "bg-blue-100",
    acronym: "PC",
    strokeColor: "stroke-purple-100",
    icon: "PhoneIcon",
    fillColor: "fill-purple-100",
  },
  left_voicemail: {
    textColor: "text-topo-blue-800",
    bgColor: "bg-blue-100",
    acronym: "VM",
    strokeColor: "stroke-purple-100",
    icon: "PhoneMissedCallIcon",
  },
  callback: {
    textColor: "text-topo-blue-800",
    borderColor: "border-topo-blue-800",
    bgColor: "bg-blue-100",
    acronym: "CB",
    strokeColor: "stroke-yellow-100",
    icon: "PhoneOutgoingIcon",
    fillColor: "fill-yellow-100",
  },
  follow_up: {
    textColor: "text-topo-blue-800",
    borderColor: "border-topo-blue-800",
    bgColor: "bg-blue-100",
    acronym: "FU",
    strokeColor: "stroke-orange-100",
    icon: "ClockIcon",
    fillColor: "fill-orange-100",
  },
  schedule_for_screen: {
    textColor: "text-yellow-900",
    borderColor: "border-yellow-900",
    bgColor: "bg-amber-100",
    acronym: "SS",
    strokeColor: "stroke-blue-100",
    icon: "CalendarIcon",
    fillColor: "fill-blue-100",
  },
  moved_to_esource: {
    textColor: "text-green-800",
    borderColor: "border-green-800",
    bgColor: "bg-green-100",
    acronym: "ME",
    strokeColor: "stroke-cyan-100",
    icon: "FolderAddIcon",
    fillColor: "fill-cyan-100",
  },
  disqualified: {
    textColor: "text-red-900",
    borderColor: "border-red-900",
    bgColor: "bg-red-300",
    acronym: "DQ",
    strokeColor: "stroke-red-400",
    icon: "ExclamationIcon",
    fillColor: "fill-red-400",
  },
}

export const statuses = Object.keys(statusOptions)
export const statusTitles = statuses.map((status) => snakeToTitle(status))
