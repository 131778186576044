import { Auth0Provider } from "@auth0/auth0-react"

export const Auth0ProviderCustom = ({ children }) => {
  const domain = process.env.AUTH0_DOMAIN
  const clientId = process.env.AUTH0_CLIENT_ID
  const audience = process.env.AUTH0_AUDIENCE
  const defaultRoute = "/home"
  const scope = "openid email profile offline_access"

  if (!(domain && clientId && audience)) {
    return null
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        scope: scope,
        audience: audience,
        redirectUri: window.location.origin + defaultRoute, // todo: store previous URL then redirect to that after login
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage" // this is because we're hosting on amplify, serverless. if we had a traditional webserver, we could set HTTPOnly cookie
    >
      {children}
    </Auth0Provider>
  )
}
