import { useMemo } from "react"
import { Link, useParams } from "react-router-dom"
import { ButtonLeadingIcon } from "../../../../components/button-leading-icon"
import SplashScreen from "../../../../components/splash-screen"
import { friendlyDateTime } from "../../../../shared/friendly-dates"
import { useIndex } from "../../../../shared/use-rest"

export function Criteria() {
  const { studyId } = useParams()
  const {
    data: study_questions,
    isLoading,
    isError,
    mutate: mutateStudyQuestions,
  } = useIndex("admin/study_questions", `study_id=${studyId}`)

  const sortedStudyQuestions = useMemo(() => {
    if (study_questions) {
      return study_questions.sort((a, b) => {
        const aCategories = a?.meta?.categories || []
        const bCategories = b?.meta?.categories || []

        const aOrder = [
          aCategories.includes("phone_screen") ? 1 : 0,
          aCategories.includes("inclusion_criteria") ? 1 : 0,
          aCategories.includes("exclusion_criteria") ? 1 : 0,
          a.order,
        ]

        const bOrder = [
          bCategories.includes("phone_screen") ? 1 : 0,
          bCategories.includes("inclusion_criteria") ? 1 : 0,
          bCategories.includes("exclusion_criteria") ? 1 : 0,
          b.order,
        ]

        for (let i = 0; i < aOrder.length; i++) {
          if (aOrder[i] !== bOrder[i]) {
            return aOrder[i] > bOrder[i] ? -1 : 1
          }
        }

        return 0
      })
    }

    return study_questions
  }, [study_questions])

  return (
    <div className="pr-4">
      <div>
        <div className="flex justify-between items-center">
          <h3 className="text-lg p-2 mb-2 font-bold text-gray-900 dark:text-white">
            Criteria
          </h3>
          <Link to="builder">
            <ButtonLeadingIcon
              icon="PlusCircleIcon"
              text="Manage Criteria"
            />{" "}
          </Link>
        </div>
      </div>

      {isLoading || isError ? (
        <SplashScreen isLoading={isLoading} isError={isError} />
      ) : (
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
          <thead className="bg-gray-50 dark:bg-gray-700">
            <tr>
              <th
                scope="col"
                className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-300"
              >
                Type
              </th>
              <th
                scope="col"
                className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-300"
              >
                Format
              </th>
              <th
                scope="col"
                className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-300"
              >
                Protocol Reference
              </th>
              <th
                scope="col"
                className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-300"
              >
                Question
              </th>
              {/* <th
                scope="col"
                className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-300"
              >
                Order
              </th> */}
              <th
                scope="col"
                className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-300"
              >
                Last Updated
              </th>
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-800">
            {sortedStudyQuestions.map((study_question, index) => (
              <tr
                key={study_question.id}
                className={index % 2 !== 0 ? "bg-gray-50" : ""}
              >
                <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white flex min-w-32">
                  <span className="text-sm font-medium flex min-w-32">
                    <div>
                      {study_question?.meta?.categories?.includes(
                        "inclusion_criteria",
                      ) && (
                        <div className="px-2 py-1 text-xs rounded-2xl bg-green-400 text-gray-50 mr-4">
                          Inclusion
                        </div>
                      )}
                      {study_question?.meta?.categories?.includes(
                        "exclusion_criteria",
                      ) && (
                        <div className="px-2 py-1 text-xs rounded-2xl bg-red-400 text-gray-50 mr-4">
                          Exclusion
                        </div>
                      )}
                    </div>
                    <div>
                      {study_question?.meta?.categories?.includes(
                        "phone_screen",
                      ) && (
                        <div className="px-2 py-1 text-xs rounded-2xl bg-blue-400 text-gray-50 justify-center flex">
                          Phone Screen
                        </div>
                      )}
                    </div>
                  </span>
                </td>
                <td className="p-4 text-sm whitespace-nowrap dark:text-gray-300">
                  {study_question?.question?.question_type}
                </td>
                <td className="p-4 text-sm whitespace-nowrap dark:text-gray-300">
                  {study_question.protocol_reference}
                </td>
                <td className="p-4 text-sm whitespace-wrap dark:text-gray-300">
                  {study_question.question?.full_text}
                </td>
                {/* <td className="p-4 text-sm whitespace-nowrap dark:text-gray-300">
                  {study_question.order}
                </td> */}
                <td className="p-4 text-sm whitespace-nowrap dark:text-gray-300">
                  {friendlyDateTime(study_question.updated_at)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}
