import {
  DataGridPro,
  type GridColDef,
  type GridRowsProp,
  GridSortItem,
  type GridSortModel,
} from "@mui/x-data-grid-pro"
import { Link } from "react-router-dom"
import { ActionHeader } from "../../../components/action-header"
import { Button } from "../../../components/button"
import { ButtonLeadingIcon } from "../../../components/button-leading-icon"
import { friendlyDateTime } from "../../../shared/friendly-dates"
import { useIndex } from "../../../shared/use-rest"

export function Medications() {
  const { data, isLoading, isError } = useIndex("medications")
  const rows: GridRowsProp = data
  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", width: 250 },
    { field: "type", headerName: "Type", width: 120 },
    { field: "source", headerName: "Source", width: 120 },
    { field: "upload_id", headerName: "Upload", width: 120 },
    {
      field: "created_at",
      headerName: "Created",
      width: 120,
      renderCell: (params) => friendlyDateTime(params.row.created_at),
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 120,
      renderCell: (params) => friendlyDateTime(params.row.updated_at),
    },
  ]
  const sortModel: GridSortModel = [{ field: "name", sort: "asc" }]

  return (
    <>
      <ActionHeader title="Medications">
        <Link to="/uploads" className="flex">
          <ButtonLeadingIcon icon="PlusCircleIcon" text="Upload Conditions" />
        </Link>
      </ActionHeader>
      {isLoading ? "Loading.." : ""}
      {isError ? "Failed to load" : ""}
      <div className="mt-2 flex h-screen">
        <div className="flex-grow">
          <DataGridPro rows={rows} columns={columns} sortModel={sortModel} />
        </div>
      </div>
    </>
  )
}
