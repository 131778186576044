import { useAuth0 } from "@auth0/auth0-react"
import { DataGridPro, GridCellParams, GridColDef, GridRowsProp, MuiEvent, } from "@mui/x-data-grid-pro"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { HeroIcon } from "../components/hero-icon"
import { event } from "../shared/event"
import { friendlyDateTime } from "../shared/friendly-dates"
import { useDelete, useIndex } from "../shared/use-rest"
import { usePatientContext } from "./patient"

export function PatientAppointments() {
  const { patientId } = useParams()
  const { setNewAppointmentModalOpen, currentStudyScreenId } = usePatientContext()
  const { data, isLoading, isError, mutate } = useIndex('patient_appointments', `patient_id=${patientId}`)
  const { getAccessTokenSilently } = useAuth0()

  const rows: GridRowsProp = data
  const columnDefaults: Partial<GridColDef> =  { width: 150, headerClassName: 'bg-gray-100 text-gray-500', display: 'flex' }
  const columns: GridColDef[] = [
    {field: 'start_dt', headerName: 'Appointment Start', ...columnDefaults, type: 'dateTime', width: 225, valueFormatter: (value, row, column, apiRef) => friendlyDateTime(value)},
    {field: 'appointment_type', headerName: 'Appointment Type', ...columnDefaults},
    {field: 'appointment_location', headerName: 'Appointment Location', ...columnDefaults, width: 225},
    {field: 'provider_name', headerName: 'Provider Name', ...columnDefaults},
    {field: 'actions', headerName: 'Actions', ...columnDefaults, flex: 1, sortable: false, filterable: false, renderCell: (params) => renderCellActions(params, deleteAppointment)},
  ]

  const deleteAppointment = async (id) => {
    await useDelete('patient_appointments', id, getAccessTokenSilently)
    mutate()
  }

  useEffect(() => {
    event({eventName: 'PatientAppointmentsViewStart', meta: {patient_id: patientId, screen_id: currentStudyScreenId }, getAccessTokenSilently})
    return () => {
      event({eventName: 'PatientAppointmentsViewEnd', meta: {patient_id: patientId, screen_id: currentStudyScreenId }, getAccessTokenSilently})
    }
  }, [])

  return (
    <>
      <div className="p-4 rounded-b-lg shadow-md bg-white dark:bg-transparent border-l border-r border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h3 className="p-4 mb-4 text-xl font-bold text-gray-900 dark:text-white">
            Appointments
          </h3>
          <button
            onClick={() => setNewAppointmentModalOpen(true)}
            className="h-10 text-white bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 rounded font-semibol text-xs px-2.5 py-1.5 text-center inline-flex items-center"
          >
            <HeroIcon
              icon="PlusCircleIcon"
              className="fill-white w-4 h-4 mr-2 rotate-90"
            />
            Add Appointment
          </button>
        </div>

        <div className="flex h-screen">
          <div className="flex-grow">
            <DataGridPro
              rows={rows}
              columns={columns}
              disableDensitySelector={true}
              onCellDoubleClick={(params: GridCellParams, event: MuiEvent<MouseEvent>) => {
                // event.defaultMuiPrevented = true
                // params.row.status ? props.handleScreenNavigate(params.row.id, params.row.study.abbreviation) :  props.handleScreenStart(params.row.study_id)
                return true
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide these columns by default, the other columns will remain visible
                  }
                },
                sorting: {
                  sortModel: [{ field: 'start_dt', sort: 'desc' }],
                },
                density: "comfortable" // contact
              }}
              sx={{
                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                  outline: "none"
                },
                ".dark & .MuiDataGrid-columnHeader": {
                  backgroundColor: "rgb(55 65 81)",
                  color: '#FFFFFF'
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}


const renderCellActions = (params: GridCellParams, deleteAppointment) => (
  <div onClick={() => deleteAppointment(params?.row.id)}><HeroIcon icon="TrashIcon" className='h-5 hover:text-red-500 hover:cursor-pointer' outline></HeroIcon></div>
)


