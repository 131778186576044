import { ThemeProvider } from "@emotion/react"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { Outlet } from "react-router-dom"
import { SWRConfig } from "swr"
import { ParticlesProvider } from "./particles-provider"
import { FeatureFlagProvider } from "./shared/feature-flag-provider"
import { MalformedParameterProvider } from "./shared/malformed-parameter-provider"
import { muiCustomTheme } from "./shared/mui-custom-theme"
import { usePrefersDarkMode } from "./shared/use-prefers-dark-mode"

export function AppWrapper() {
  const prefersDarkMode = usePrefersDarkMode()
  const theme = muiCustomTheme(prefersDarkMode)

  return (
    <MalformedParameterProvider>
      <SWRConfig>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <FeatureFlagProvider>
              <ParticlesProvider>
                <Outlet />
              </ParticlesProvider>
            </FeatureFlagProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </SWRConfig>
    </MalformedParameterProvider>
  )
}
