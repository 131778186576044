import {
  DataGridPro,
  type GridColDef,
  type GridRowsProp,
} from "@mui/x-data-grid-pro"
import { ActionHeader } from "../../../components/action-header"
import { Button } from "../../../components/button"
import { friendlyDateTime } from "../../../shared/friendly-dates"
import { useIndex } from "../../../shared/use-rest"

export function Procedures() {
  const { data, isLoading, isError } = useIndex("procedures")
  const rows: GridRowsProp = data
  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", width: 250 },
    {
      field: "created_at",
      headerName: "Created",
      width: 200,
      renderCell: (params) => friendlyDateTime(params.row.created_at),
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 200,
      renderCell: (params) => friendlyDateTime(params.row.updated_at),
    },
  ]

  return (
    <>
      <ActionHeader title="Procedures" />
      {isLoading ? "Loading.." : ""}
      {isError ? "Failed to load" : ""}
      <div className="mt-2 flex h-screen">
        <div className="flex-grow">
          <DataGridPro rows={rows} columns={columns} />
        </div>
      </div>
    </>
  )
}
