import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIdleTimer } from 'react-idle-timer'
import { KeyedMutator } from 'swr';
import { useCreate, useIndex, useUpdate } from '../shared/use-rest';
import { useKeyPress } from '../shared/useKeyPress';
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import { useAuth0 } from '@auth0/auth0-react';
import ReactSelect, { createFilter } from 'react-select'

interface Props {
  patientId: string
  setOpen: Dispatch<SetStateAction<boolean>>
  refreshData: KeyedMutator<{data: any[]}>
}

export function NewMedicationModal(props: Props) {
  const { register, handleSubmit, control } = useForm({defaultValues: {patient_id: props.patientId}});
  const { data: medications, isLoading: isLoadingMedications, isError: isErrorMedications, mutate: mutateMedications } = useIndex('medications')
  const { data: conditions, isLoading: isLoadingConditions, isError: isErrorConditions, mutate: mutateConditions } = useIndex('conditions')
  const [medicationsOptions, setMedicationsOptions] = useState([])
  const [conditionsOptions, setConditionsOptions] = useState([])
  const [ongoing, setOngoing] = useState(true)

  useEffect(() => {
    setMedicationsOptions(medications.slice().map((medication) => ({value: medication.id, label: medication.name })))
  }, [medications])

  useEffect(() => {
    setConditionsOptions(conditions.slice().map((condition) => ({value: condition.id, label: condition.name })))
  }, [conditions])

  const { getAccessTokenSilently } = useAuth0()

  const closeModal = () => {
    props.setOpen(false)
  }

  useKeyPress(['Escape'], closeModal);

  const onSubmit = (data) => {
    data = {
      ...data,
      medication_id: data.medication_id?.value,
      condition_id: data.condition_id?.value,
      started_at: data.started_at + ' 00:00:00',
      ended_at: data.started_at + ' 00:00:00'
    };
    useCreate('patient_medications', data, getAccessTokenSilently).then((response) => {
      props?.refreshData()
      closeModal()
    }, (error) => {});
  }

  return (
  <>
     <div
        className="fixed flex left-0 ml-10 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full"
        id="add-medication-modal"
      >
        <div className="relative ml-10 w-full h-full max-w-2xl px-4 md:h-auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <!-- Modal content --> */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
              {/* <!-- Modal header --> */}
              <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
                <h3 className="text-xl font-semibold dark:text-white">
                  Add Medication
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                  onClick={()=> closeModal() }
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <div className="p-6 space-y-6">
                <div className="grid grid-cols-6 space-y-6">
                      <div className="col-span-6 sm:col-span-6">
                        <label
                          htmlFor="medication_id"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Medication
                        </label>
                        <Controller
                          name="medication_id"
                          control={control}
                          defaultValue={""}
                          render={({ field }) => (
                          <>
                            <ReactSelect
                              {...field}
                              filterOption={createFilter({ ignoreAccents: false })}
                              className="text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              classNamePrefix="select"
                              defaultValue={""}
                              isDisabled={false}
                              isLoading={isLoadingMedications}
                              isClearable={true}
                              isSearchable={true}
                              name="medication_id_select"
                              options={medicationsOptions}
                            />
                          </>
                          )}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-6">
                        <label
                          htmlFor="medication_id"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Condition
                        </label>
                        <Controller
                          name="condition_id"
                          control={control}
                          defaultValue={""}
                          render={({ field }) => (
                            <>
                              <ReactSelect
                                {...field}
                                filterOption={createFilter({ ignoreAccents: false })}
                                className="text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                classNamePrefix="select"
                                defaultValue={""}
                                isDisabled={false}
                                isLoading={isLoadingConditions}
                                isClearable={true}
                                isSearchable={true}
                                name="condition_id_select"
                                options={conditionsOptions}
                              />
                            </>
                          )}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-6">
                        <label
                          htmlFor="amount"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Amount
                        </label>
                        {/* TODO: make the border not move the other elements around */}
                        <input
                          {...register('amount')}
                          type="text"
                          name="amount"
                          id="amount"
                          className="shadow-sm bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-2 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          required
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-6">
                        <label
                          htmlFor="unit"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Unit
                        </label>
                        {/* TODO: make the border not move the other elements around */}
                        <input
                          {...register('unit')}
                          type="text"
                          name="unit"
                          id="unit"
                          className="shadow-sm bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-2 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          required
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-6">
                        <label
                          htmlFor="started_at"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Started at
                        </label>
                        <Controller
                          name="started_at"
                          control={control}
                          defaultValue={""}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="date"
                              sx={{
                                width: 250,
                                'boxShadow': 'none'
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </div>

                      <label
                          htmlFor="ongoing"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >Ongoing?
                        </label>
                      <input type="checkbox" onClick={() => setOngoing(!ongoing)} checked={ongoing} />

                      {!ongoing && <div className="col-span-6 sm:col-span-6">
                        <label
                          htmlFor="ended_at"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Ended at
                        </label>
                        <Controller
                          name="ended_at"
                          control={control}
                          defaultValue={""}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="date"
                              sx={{
                                width: 250,
                                'boxShadow': 'none'
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </div>}

                      <div className="col-span-6 sm:col-span-6">
                          <input
                          {...register('patient_id')}
                          type="hidden"
                          name="patient_id"
                          id="patient_id"
                          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          required
                        />
                      </div>
                  </div>

              </div>

                {/* <!-- Modal footer --> */}
                <div className="items-center p-4 border-t border-gray-200 rounded-b">
                  <button
                    className="text-white bg-topo-green hover:bg-topo-green-700 focus:ring-4 focus:ring-topo-green-600 font-medium rounded-lg text-sm mr-5 px-5 py-2.5 text-center"
                    type="submit"
                    >
                    Save
                  </button>
                </div>
            </div>
          </form>
        </div>
      </div>
      <div id="modal-backdrop" className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"></div>
    </>
  )
}
