import { useState } from "react"
import { useParticles } from "../particles-provider"

export default function JoyModeSelector() {
  const [joy, setJoy] = useState(localStorage.joy || "false")
  const { triggerConfetti } = useParticles()

  const handleClick = (mode) => {
    localStorage.setItem("joy", mode)
    window.dispatchEvent(new Event("storage"))
    setJoy(localStorage.joy || "false")
    triggerConfetti()
  }

  return (
    <div className="rounded-md flex min-w-full h-16 pb-2 px-2 justify-center">
      <button
        type="button"
        className={`${
          joy === "true" ? "dark:bg-gray-500 bg-gray-200" : ""
        } w-1/2  items-center rounded-l-md border border-gray-300 px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-700 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-0 focus:ring-indigo-500`}
        onClick={() => handleClick("true")}
      >
        <div>Joy</div> 🦄
      </button>
      <button
        type="button"
        className={`${
          joy === "false" ? "dark:bg-gray-500 bg-gray-200" : ""
        } w-1/2 -ml-px  items-center rounded-r-md border border-gray-300 px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-700 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-0 focus:ring-indigo-500`}
        onClick={() => handleClick("false")}
      >
        No Joy 🫡
      </button>
    </div>
  )
}
