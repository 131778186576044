import { ChartReviewDirectoryScreenCard } from "./chart-review-directory-screen-card"

export function ChartReviewDirectoryScreens({ screens }) {
  return (
    <>
      <div className="flex h-full py-2 bg-gray-100 text-black dark:bg-gray-800 dark:text-gray-100">
        <div className="flex-auto h-auto ml-10">
          {screens
            ?.sort((a, b) => b.last_updated - a.last_updated)
            ?.map((screen) => {
              return (
                <ChartReviewDirectoryScreenCard
                  key={screen.id}
                  screen={screen}
                />
              )
            })}
        </div>
      </div>
    </>
  )
}
