import { DataGrid, type GridColDef, type GridRowsProp } from "@mui/x-data-grid"
import { DataGridPro } from "@mui/x-data-grid-pro"
import { ActionHeader } from "../../../components/action-header"
import SplashScreen from "../../../components/splash-screen"
import { friendlyDateTime } from "../../../shared/friendly-dates"
import { useIndex } from "../../../shared/use-rest"

export function Sponsor() {
  const { data, isLoading, isError } = useIndex("admin/ponsors")
  const rows: GridRowsProp = data
  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", width: 250 },
    { field: "abbreviation", headerName: "Abbr", width: 150 },
    {
      field: "created_at",
      headerName: "Created",
      width: 150,
      renderCell: (params) => friendlyDateTime(params.row.created_at),
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 150,
      renderCell: (params) => friendlyDateTime(params.row.updated_at),
    },
  ]

  return (
    <>
      <ActionHeader title="Sponsors" />
      {isLoading || isError ? (
        <SplashScreen isLoading={isLoading} isError={isError} />
      ) : (
        <div className="mt-2 flex h-screen">
          <div className="flex-grow">
            <DataGridPro rows={rows} columns={columns} />
          </div>
        </div>
      )}
    </>
  )
}
