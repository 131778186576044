import { useAuth0 } from "@auth0/auth0-react"
import { TextField } from "@mui/material"
import { QueryClient, useQueryClient } from "@tanstack/react-query"
import { Badge, Card } from "flowbite-react"
import { useState } from "react"
import { Link, useParams } from "react-router-dom"
import { ButtonLeadingIcon } from "../../../components/button-leading-icon"
import SplashScreen from "../../../components/splash-screen"
import TiptapDisplay from "../../../components/tiptap-display/tiptap-display"
import { useIndex, useShow, useUpdate } from "../../../shared/use-rest"
import { useShowQuery } from "../../../shared/use-rest-query"
import { Criteria } from "./builder/display"
import { StudyEditModal } from "./edit-modal"
import { PracticeStudiesDisplay } from "./practices/display"
import { Visits } from "./visits/index"

export function Study() {
  const [editModalOpen, setEditModalOpen] = useState(false)
  const { studyId } = useParams()

  const {
    data: { data: study },
    isFetching,
    isError,
  } = useShowQuery("admin/studies", studyId)

  const queryClient = useQueryClient()
  const mutateStudy = () => {
    queryClient.invalidateQueries({
      queryKey: ["admin/studies", studyId],
    })
  }

  const { data: sponsor, mutate: mutateSponsor } = useShow(
    "sponsors",
    study.sponsor_id,
  )

  const { getAccessTokenSilently } = useAuth0()

  const handleRefresh = () => {
    mutateStudy()
    mutateSponsor()
  }

  return (
    <>
      {isFetching || isError ? (
        <SplashScreen isLoading={isFetching} isError={isError} />
      ) : (
        <div className="">
          <div>
            <div className="flex items-center pr-4 pl-4">
              <h2 className="text-2xl sm:text-3xl  p-2 mb-2 font-bold text-gray-900 dark:text-white">
                {`${study.name} (${study.abbreviation})`}
              </h2>
              <div
                onClick={() => setEditModalOpen(true)}
                onKeyUp={() => setEditModalOpen(true)}
              >
                <ButtonLeadingIcon
                  icon="PencilIcon"
                  text="Edit Details"
                  className="inline-flex items-center justify-center h-10 w-1/2 px-3 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300 sm:w-auto dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                />
              </div>
            </div>
          </div>
          <div className="pr-4 mb-2">
            <Card>
              <h3 className="font-bold text-lg mb-4">Details</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className="flex items-center mb-1">
                    <Badge color="gray" size="sm">
                      Name
                    </Badge>
                    <div className="ml-2">
                      {study.name || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center mb-1">
                    <Badge color="gray" size="sm">
                      Nickname
                    </Badge>
                    <div className="ml-2">
                      {study.nickname || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center mb-1">
                    <Badge color="gray" size="sm">
                      Abbreviation
                    </Badge>
                    <div className="ml-2">
                      {study.abbreviation || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center mb-1">
                    <Badge color="gray" size="sm">
                      Description
                    </Badge>
                    <div className="ml-2">
                      {study.description || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center mb-1">
                    <Badge color="gray" size="sm">
                      Protocol Version
                    </Badge>
                    <div className="ml-2">
                      {study.protocol_version || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-center mb-1">
                    <Badge color="gray" size="sm">
                      Indications
                    </Badge>
                    <div className="ml-2">
                      {study.indications
                        ?.map((indication) => indication.name)
                        .join(", ") || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center mb-1">
                    <Badge color="gray" size="sm">
                      Call Script
                    </Badge>
                    <div className="ml-2">
                      {study.call_script || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center mb-1">
                    <Badge color="gray" size="sm">
                      Objectives
                    </Badge>
                    <div className="ml-2">
                      {study.objectives || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center mb-1">
                    <Badge color="gray" size="sm">
                      Study Type
                    </Badge>
                    <div className="ml-2">
                      {study.study_type || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center mb-1">
                    <Badge color="gray" size="sm">
                      Sponsor
                    </Badge>
                    <div className="ml-2">
                      {sponsor.name || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="grid grid-cols-6 gap-4">
                  <div className="col-span-1">
                    <Badge color="gray" size="sm">
                      Trial Summary
                    </Badge>
                  </div>
                  <div
                    className="w-full col-span-5"
                    onDoubleClick={() => setEditModalOpen(true)}
                  >
                    <TiptapDisplay defaultValue={study.provider_summary} />
                  </div>
                  <div className="col-span-1">
                    <Badge color="gray" size="sm">
                      Key Inclusion/Exclusion Criteria
                    </Badge>
                  </div>
                  <div
                    className="col-span-5"
                    onDoubleClick={() => setEditModalOpen(true)}
                  >
                    <TiptapDisplay defaultValue={study.ie_criteria} />
                  </div>
                  <div className="col-span-1">
                    <Badge color="gray" size="sm">
                      Patient Pitch
                    </Badge>
                  </div>
                  <div
                    className="w-full col-span-5"
                    onDoubleClick={() => setEditModalOpen(true)}
                  >
                    <TiptapDisplay defaultValue={study.how_you_can_help} />
                  </div>
                  <div className="col-span-1">
                    <Badge color="gray" size="sm">
                      Compound/Asset
                    </Badge>
                  </div>
                  <div
                    className="col-span-5"
                    onDoubleClick={() => setEditModalOpen(true)}
                  >
                    <div className="">
                      {study.compound || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-span-1">
                    <Badge color="gray" size="sm">
                      Study Specifics
                    </Badge>
                  </div>
                  <div
                    className="w-full col-span-5"
                    onDoubleClick={() => setEditModalOpen(true)}
                  >
                    <TiptapDisplay defaultValue={study.key_stats} />
                  </div>
                  <div className="col-span-1 hidden">
                    <Badge color="gray" size="sm">
                      Criteria Summary
                    </Badge>
                  </div>
                  <div
                    className="w-full col-span-5 hidden"
                    onDoubleClick={() => setEditModalOpen(true)}
                  >
                    <TiptapDisplay defaultValue={study.criteria_summary} />
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <div>
            <div className="flex justify-between items-center pr-4">
              <h3 className="text-lg  p-2 mb-2 font-bold text-gray-900 dark:text-white">
                Visits
              </h3>
              <Link to="visits">
                <ButtonLeadingIcon icon="PlusCircleIcon" text="Manage Visits" />
              </Link>
            </div>
          </div>

          <Visits />
          {editModalOpen && studyId && (
            <StudyEditModal
              studyId={studyId}
              setOpen={setEditModalOpen}
              refreshData={handleRefresh}
            />
          )}
          <div className="mb-4">
            <Criteria />
          </div>
          <PracticeStudiesDisplay />
        </div>
      )}
    </>
  )
}
