import { useAuth0 } from "@auth0/auth0-react"
import TextField from "@mui/material/TextField"
import { useQueryClient } from "@tanstack/react-query"
import { zonedTimeToUtc } from "date-fns-tz"
import {
  type Dispatch,
  type SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { Controller, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { AuthorizationContext } from "../shared/authorization-provider"
import { EsourceTimeOptions } from "../shared/esource-time-options"
import { TimezoneOptions } from "../shared/timezone-options"
import { useCreate, useIndex, useShow, useUpdate } from "../shared/use-rest"
import { useKeyPress } from "../shared/useKeyPress"
import { managerOrUser } from "./protected-admin"

interface Props {
  patientId: string
  screenId: string
  setOpen: Dispatch<SetStateAction<boolean>>
}

export function ScheduleInESourceModal(props: Props) {
  const { getAccessTokenSilently } = useAuth0()
  const { practiceId } = useContext(AuthorizationContext)
  const { data: practice } = useShow("practices", practiceId)
  const { register, handleSubmit, reset, control, setValue, getValues } =
    useForm({
      defaultValues: {
        patient_id: props.patientId,
        screen_id: props.screenId,
        end_time: "09:00",
        start_timezone: "America/New_York",
      },
    })
  const { data: users } = useIndex("users")
  const {
    data: screen,
    isLoading: isLoadingScreen,
    mutate: mutateScreen,
  } = useShow("screens", props.screenId)
  const [responseMessage, setResponseMessage] = useState("")
  const [responseDetail, setResponseDetail] = useState("")
  const queryClient = useQueryClient()

  const closeModal = () => {
    props.setOpen(false)
  }

  useEffect(() => {
    if (!practice?.timezone) return
    setValue("start_timezone", practice?.timezone)
  }, [setValue, practice])

  useKeyPress(["Escape"], closeModal)

  const onSubmit = async (data) => {
    // VALIDATION RULES:
    // Must have DOB
    // Must have Phone Number or Email
    // Appointment times must end in: [:00 :15 :30 :45]

    if (!data.start_date) {
      setResponseMessage("Must set Appointment Date")
      return
    }
    // if (!data.start_timezone) return
    if (!data.end_time) return
    const utcStartDt = zonedTimeToUtc(
      `${data.start_date}T${data.start_time}`,
      data.start_timezone,
    )
      ?.toISOString()
      .slice(0, -1)
    const utcEndDt = zonedTimeToUtc(
      `${data.start_date}T${data.end_time}`,
      data.start_timezone,
    )
      ?.toISOString()
      .slice(0, -1)
    await useUpdate(
      "screens",
      props.screenId,
      { assigned_to_user_id: data.assigned_to_user_id },
      getAccessTokenSilently,
    )
    const patient_appointment_data = {
      type: "Screen",
      patient_id: data.patient_id,
      start_dt: utcStartDt,
      end_dt: utcEndDt,
    }
    const patient_appointment_response = await useCreate(
      "patient_appointments",
      patient_appointment_data,
      getAccessTokenSilently,
    )
    const patient_appointment = patient_appointment_response?.data?.data
    if (!patient_appointment) {
      setResponseMessage("Error Creating Appointment")
      return
    }
    const crioXRefData = {
      patient_id: props.patientId,
      screen_id: props.screenId,
      patient_appointment_id: patient_appointment?.id,
    }
    console.dir(crioXRefData)
    const createXrefResponse = await useCreate(
      "crio_xrefs",
      crioXRefData,
      getAccessTokenSilently,
    )
    console.dir("createXrefResponse")
    console.dir(createXrefResponse)
    const updateScreenResponse = await useUpdate(
      "screens",
      props.screenId,
      { status: "moved_to_esource" },
      getAccessTokenSilently,
    )
    console.dir("updateScreenResponse")
    console.dir(updateScreenResponse)
    if (createXrefResponse.data.message === "success") {
      queryClient.invalidateQueries({ queryKey: ["screens", props.screenId] })
      toast.success("Sent to eSource")
      closeModal()
    } else {
      setResponseMessage(createXrefResponse.data.message)
      setResponseDetail(createXrefResponse.data?.data)
      toast.error("There was an error sending to eSource")
    }
  }

  function setEndTimeToStartPlus(minutes: number) {
    // if start date is null return nothing
    // if start time is null return 0800
    // if start time isn't null get the value, parse it as a time, add it on
    const today = new Date().toISOString().split("T")[0]
    if (!getValues("start_date")) setValue("start_date", today)
    const startDate = getValues("start_date")
    console.dir(startDate)
    // if (!getValues('start_time')) return dateTimeISOWithoutTimezone(startDt.setHours(8))
    const startTime = getValues("start_time")
    const startDt = new Date(`${startDate}T${startTime}`)
    const startTimezone = getValues("start_timezone")
    // setValue('start_dt', zonedTimeToUtc(startDt, startTimezone))

    const endTime = startDt.setTime(startDt.getTime() + minutes * 60 * 1000)
    const endTimeString = `${new Date(endTime)
      .getHours()
      .toString()
      .padStart(2, "0")}:${new Date(endTime)
      .getMinutes()
      .toString()
      .padEnd(2, "0")}`
    console.dir(endTimeString)
    setValue("end_time", endTimeString)
    // return dateTimeISOWithoutTimezone(endDt)
  }

  useEffect(() => {
    if (!screen) return
    reset({ assigned_to_user_id: screen?.assigned_to_user_id })
  }, [screen, reset])

  return (
    <>
      <div
        className="fixed flex left-0 ml-10 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full"
        id="add-appointment-modal"
      >
        <div className="relative ml-10 w-full h-full max-w-2xl px-4 md:h-auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <!-- Modal content --> */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
              {/* <!-- Modal header --> */}
              <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
                <h3 className="text-xl font-semibold dark:text-white">
                  Add To eSource
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                  onClick={() => closeModal()}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>X Button</title>
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <div className="p-6 space-y-6">
                <div className="grid grid-cols-6 space-y-6">
                  <div className="col-span-12 text-sm">
                    <div className="font-medium">Notes</div>
                    <ul className="list-disc pl-3.5">
                      <li className="">Patient must have DOB</li>
                      <li>Patient must have either: Phone Number or Email</li>
                      <li className="">
                        Assigned User must have the same email in Waypoints and
                        CRIO
                      </li>
                      <li>Appointment must be in the future</li>
                    </ul>
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="start_dt"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Assigned
                    </label>
                    {/* <div className='mb-2' title={screen?.assigned_to?.email}>{screen?.assigned_to?.name}</div> */}
                    <select
                      // {...registerScreen('assigned_to_user_id')}
                      {...register("assigned_to_user_id")}
                      className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm hover:border-gray-800 dark:bg-gray-800 dark:hover:border-gray-600"
                    >
                      <option value="">None</option>
                      {users
                        .filter((user) => managerOrUser(user))
                        .map((user, index) => (
                          <option key={user.id} value={user.id} className="">
                            {user.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-span-12 sm:col-span-12">
                    <label
                      htmlFor="start_date"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Appointment Date
                    </label>
                    <Controller
                      name="start_date"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="date"
                          sx={{
                            width: 250,
                            boxShadow: "none",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-12 sm:col-span-12">
                    <label
                      htmlFor="start_dt"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Appointment Start Time
                    </label>
                    <div className="flex items-center">
                      <select
                        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm hover:border-gray-800 dark:bg-gray-800 dark:hover:border-gray-600"
                        {...register("start_time")}
                      >
                        <EsourceTimeOptions />
                      </select>
                      <div className="flex ml-2 rounded-md border-gray-300 text-sm">
                        <input type="hidden" {...register("start_timezone")} />
                        <div className="flex ml-2 rounded-md border-gray-300">
                          {TimezoneOptions?.[practice.timezone]}
                        </div>
                      </div>
                    </div>
                    {/* <select
                      className="ml-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm hover:border-gray-800 dark:bg-gray-800 dark:hover:border-gray-600"
                      {...register('start_timezone')}
                    >
                      <EsourceTimezoneOptions />
                    </select> */}
                  </div>
                  <div className="col-span-12">
                    <label
                      htmlFor="appointment_length"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Appointment Length
                    </label>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault()
                        setEndTimeToStartPlus(15)
                      }}
                      className="mr-2 dark:text-white hover:text-white bg-transparent border border-gray-300 dark:border-gray-600 hover:border-gray-500 hover:dark:border-gray-300 hover:bg-topo-blue-700 focus:ring-4 focus:ring-topo-blue-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                      15 min
                    </button>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault()
                        setEndTimeToStartPlus(30)
                      }}
                      className="mr-2 dark:text-white hover:text-white bg-transparent border border-gray-300 dark:border-gray-600 hover:border-gray-500 hover:dark:border-gray-300 hover:bg-topo-blue-700 focus:ring-4 focus:ring-topo-blue-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                      30 min
                    </button>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault()
                        setEndTimeToStartPlus(60)
                      }}
                      className="mr-2 dark:text-white hover:text-white bg-transparent border border-gray-300 dark:border-gray-600 hover:border-gray-500 hover:dark:border-gray-300 hover:bg-topo-blue-700 focus:ring-4 focus:ring-topo-blue-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                      60 min
                    </button>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault()
                        setValue("end_time", null)
                      }}
                      className="mr-2 dark:text-white hover:text-white bg-transparent border border-gray-300 dark:border-gray-600 hover:border-gray-500 hover:dark:border-gray-300 hover:bg-topo-blue-700 focus:ring-4 focus:ring-topo-blue-600 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                      Custom
                    </button>
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="end_time"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Appointment End Time
                    </label>
                    <div className="flex items-center text-sm">
                      <select
                        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm hover:border-gray-800 dark:bg-gray-800 dark:hover:border-gray-600"
                        {...register("end_time")}
                      >
                        <EsourceTimeOptions />
                      </select>
                      <div className="flex ml-2 rounded-md border-gray-300">
                        {TimezoneOptions?.[practice.timezone]}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <input
                      {...register("patient_id")}
                      type="hidden"
                      name="patient_id"
                      id="patient_id"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      // placeholder="example@company.com"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="p-4">{responseDetail}</div>
              {/* <!-- Modal footer --> */}
              <div className="items-center p-4 border-t border-gray-200 dark:border-gray-700 rounded-b">
                <button
                  className="text-white bg-topo-green hover:bg-topo-green-700 focus:ring-4 focus:ring-topo-green-600 font-medium rounded-lg text-sm mr-5 px-5 py-2.5 text-center"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        id="modal-backdrop"
        className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
      />
    </>
  )
}
