import { useAuth0 } from "@auth0/auth0-react"
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { AuthorizationContext } from "../shared/authorization-provider"
import { event } from "../shared/event"
import { useCreate, useIndex } from "../shared/use-rest"
import { usePatientContext } from "./patient"
import { ScreensTable } from "./patient-screens/screens-table"

export function PatientScreens() {
  const { patientId } = useParams()
  const { practiceId } = useContext(AuthorizationContext)
  const { getAccessTokenSilently } = useAuth0()
  const {
    setCurrentStudyScreenName,
    setCurrentStudyScreenId,
    currentStudyScreenId,
  } = usePatientContext()
  const {
    data: started_screens,
    isLoading,
    isError,
    mutate,
  } = useIndex("screens", `patient_id=${patientId}`)
  const { data: practice_studies } = useIndex(
    "practice_studies"
  )
  const { handleSubmit, register, reset, setValue, control } = useForm()
  const [addingScreen, setAddingScreen] = useState(false)
  const [newScreenStudyId, setNewScreenStudyId] = useState("")
  const [unscreenedStudies, setUnscreenedStudies] = useState([])
  const [screens, setScreens] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    let meta = { patient_id: patientId }
    if (currentStudyScreenId) {
      meta = { ...meta, study_id: currentStudyScreenId }
    }
    event({
      eventName: "PatientScreensViewStart",
      meta: meta,
      getAccessTokenSilently,
    })
    return () => {
      event({
        eventName: "PatientScreensViewEnd",
        meta: meta,
        getAccessTokenSilently,
      })
    }
  }, [currentStudyScreenId, getAccessTokenSilently, patientId])

  useEffect(() => {
    if (practice_studies.length === 0) {
      return
    }

    const combinedScreens = practice_studies.slice()
    for (const screen of started_screens) {
      const study_index = combinedScreens.findIndex(
        (practice_study) => practice_study.study_id === screen?.study_id,
      )
      if (study_index !== -1) {
        combinedScreens[study_index].screen_id = screen?.id
        combinedScreens[study_index].screen_status = screen?.status
      }
    }

    setScreens(combinedScreens)
  }, [practice_studies, started_screens])

  const handleScreenNavigate = (screen_id, screen_abbreviation = "") => {
    setCurrentStudyScreenName(screen_abbreviation)
    setCurrentStudyScreenId(screen_id)
    navigate(`/patients/${patientId}/screens/${screen_id}`)
  }

  const handleScreenStart = async (study_id) => {
    if (!study_id) return
    const data = {
      study_id: study_id,
      patient_id: patientId,
      practice_id: practiceId,
      status: "chart_review",
    }
    const response = await useCreate("screens", data, getAccessTokenSilently)
    const id = response?.data?.data?.id
    if (id) handleScreenNavigate(id)
  }

  return (
    <>
      <div className="p-4 rounded-b-lg shadow-md bg-white dark:bg-transparent border-l border-r border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h3 className="p-4 mb-4 text-xl font-bold text-gray-900 dark:text-white">
            Studies
          </h3>
        </div>
        <ScreensTable
          patientId={patientId}
          screens={screens}
          handleScreenNavigate={handleScreenNavigate}
          handleScreenStart={handleScreenStart}
        />
      </div>
    </>
  )
}
