import { DotsHorizontalIcon, PlusIcon } from "@heroicons/react/solid"
import { snakeToTitle } from "../../shared/text-utils"
import { PatientCard } from "./patient-card"
import { Droppable, DroppableProvided } from 'react-beautiful-dnd';

interface Props {
  status: string
  patients: []
  studyId: string
  statusIndex: number
}

export const Column = (props: Props) => {
  const { status, patients, studyId, statusIndex } = props

  return (
    <Droppable droppableId={status} key={status} type="category">
    {(provided: DroppableProvided) => {
      return (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="flex flex-col flex-shrink-0 mr-3 select-none w-[200px]"
        >
          {/* column title */}
          <div className="flex items-center justify-between pb-4 text-sm">
            {/* left info */}
            <div className="flex items-center">
              {/* {statusIcon} */}
              <span className="ml-3 mr-3 font-medium">      {snakeToTitle(status)} </span>
              <span className="mr-3 font-normal text-gray-400">
                {patients?.length || 0}
              </span>
            </div>

            {/* action buttons */}
            {/* <div className="flex items-center">
              <button className="flex items-center justify-center border-none rounded h-7 w-7 hover:bg-gray-200 focus:outline-none">
                <PlusIcon className="w-3.5 text-gray-400 hover:text-gray-700" />
              </button>
              <button className="flex items-center justify-center border-none rounded h-7 w-7 hover:bg-gray-200 focus:outline-none">
                <DotsHorizontalIcon className="w-3.5 text-gray-400 hover:text-gray-700" />
              </button>
            </div> */}
          </div>

          {/* list of issues */}
          <div className="flex flex-col flex-1 w-full overflow-y-auto border-gray-200 pt-0.5">
            {patients?.map((patient, index) => <PatientCard patient={patient} studyId={studyId} patientIndex={index} statusIndex={statusIndex} />)}
            {provided.placeholder}
          </div>
        </div>
      )}}
    </Droppable>
  )
}
