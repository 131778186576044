import { differenceInYears } from "date-fns"

export function dobCalculator(age: number) {
  const today = new Date()
  const birthYear = today.getFullYear() - age

  const birthday = new Date()
  birthday.setFullYear(birthYear)

  return birthday.toISOString().split("T")[0]
}
