import type { Container } from "@tsparticles/engine"
import Particles, { initParticlesEngine } from "@tsparticles/react"
import { createContext, useContext, useEffect, useState } from "react"
import { loadFull } from "tsparticles" // if you are going to use `loadFull`, install the "tsparticles" package too.
import { confettiOptionsDefault } from "./confetti-options"
import { generateUUID } from "./shared/dom-utils"
import { usePrefersJoy } from "./shared/use-prefers-joy"

// USAGE
// const { triggerConfetti } = useParticles()
// onClick={() => triggerConfetti()}

export const ParticlesContext = createContext({ triggerConfetti: () => null })

export const ParticlesProvider = ({ children }) => {
  const [init, setInit] = useState(false)
  const [confettiTrigger, setConfettiTrigger] = useState("")
  const confettiOptions = confettiOptionsDefault
  const triggerConfetti = () => {
    confettiOptions.autoPlay = true
    setConfettiTrigger(generateUUID()) // Toggle to force re-render
  }

  const joy = usePrefersJoy()

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      await loadFull(engine)
      // await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true)
    })
  }, [])

  const particlesLoaded = async (container?: Container): Promise<void> => {
    if (process.env.ENV === "dev") console.dir("particlesLoaded")
  }

  if (!joy || !init) return <>{children}</>

  return (
    <ParticlesContext.Provider value={{ triggerConfetti }}>
      {children}
      <Particles
        id="tsparticles"
        particlesLoaded={particlesLoaded}
        options={confettiOptions}
        key={confettiTrigger}
      />
    </ParticlesContext.Provider>
  )
}

export const useParticles = () => useContext(ParticlesContext)
