import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/solid"
import { lowerCase } from "change-case-all"
import { Fragment } from "react"
import { useForm } from "react-hook-form"
import Tiptap from "./tiptap-editor/tiptap-editor"

export function NewSlideover(props) {
  const { register, handleSubmit, rese, control } = useForm()

  function wrapSubmit(data) {
    props.onSubmit(data)
    reset()
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={props.setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-400 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-400 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-2xl">
                <form
                  className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                  onSubmit={handleSubmit(wrapSubmit)}
                >
                  <div className="flex-1">
                    {/* Header */}
                    <div
                      className="
                bg-topo-navy px-4 py-6 sm:px-6"
                    >
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-100 ">
                            Add New {props.entity.label}
                          </Dialog.Title>
                          <p className="text-sm text-gray-300">
                            Get started by filling in the information below to
                            add a new {lowerCase(props.entity.label)}.
                          </p>
                        </div>
                        <div className="flex h-7 items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={() => props.setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      {props.fields.map((field) => (
                        <div
                          key={field.key}
                          className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                        >
                          <div>
                            <label
                              htmlFor={field.key}
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              {" "}
                              {field.label}{" "}
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            {field.options && (
                              <select {...register(field.key)}>
                                <option />
                                {field.options.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            )}
                            {!field.options && field.type !== "wysiwyg" && (
                              <input
                                {...register(field.key)}
                                type="text"
                                id={field.key}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            )}
                            {field.type === "wysiwyg" && (
                              <Tiptap
                                control={control}
                                fieldName={field.key}
                                defaultValue=""
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div className="flex justify-start space-x-3">
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-topo-green py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-500 focus:ring-offset-2"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-red-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        onClick={() => props.setOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
