import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { StudyPriorityChip } from "../components/patients/study-priority-chip"
import { StudyStatusChip } from "../components/patients/study-status-chip"
import { AuthorizationContext } from "../shared/authorization-provider"
import { friendlyDate, friendlyDateTime } from "../shared/friendly-dates"
import { useIndex, useShow } from "../shared/use-rest"

export function PracticeStudies() {
  // TODO: Get this from global context provider
  const { practiceId } = useContext(AuthorizationContext)
  const { data, isLoading, isError, mutate } = useIndex("practice_studies")
  const { data: practice } = useShow("practices", practiceId)
  const navigate = useNavigate()

  const handleClickPracticeStudy = (practiceStudy) => {
    const path = `/patients?filter_screens=${encodeURIComponent(
      JSON.stringify([practiceStudy.study.abbreviation, null, null]),
    )}`
    navigate(path)
  }
  return (
    <>
      <h3 className="p-4 mb-4 text-xl font-bold text-gray-900 dark:text-white">
        Studies at {practice.name}
      </h3>
      {/* Table */}
      <div className="flex flex-col p-4">
        <div className="overflow-x-auto rounded-lg">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow sm:rounded-lg shadow-gray-600">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600 border dark:border-gray-600">
                <thead className="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th
                      scope="col"
                      className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400"
                    >
                      Study Name
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400"
                    >
                      Abbreviation
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400"
                    >
                      Sponsor
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400"
                    >
                      Study Type
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400"
                    >
                      Indications
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400"
                    >
                      Priority
                    </th>
                    <th
                      scope="col"
                      className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400"
                    >
                      Enrollment Start Date
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-800">
                  {data.map((practice_study, index) => (
                    <tr
                      key={practice_study.id}
                      className={`${
                        index % 2 !== 0
                          ? "bg-gray-100 dark:bg-gray-600 dark:hover-bg-gray-700"
                          : ""
                      } hover:cursor-pointer hover:bg-sky-50 dark:hover:bg-gray-700`}
                      onClick={() => handleClickPracticeStudy(practice_study)}
                      onKeyUp={() => handleClickPracticeStudy(practice_study)}
                    >
                      <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                        <span className="text-sm font-medium ">
                          {practice_study.study.name}
                        </span>
                      </td>
                      <td className="p-4 text-sm whitespace-nowrap dark:text-gray-400">
                        {practice_study.study.abbreviation}
                      </td>
                      <td className="p-4 text-sm whitespace-nowrap dark:text-gray-400">
                        {practice_study.study.sponsor.name}
                      </td>
                      <td className="p-4 text-sm dark:text-gray-400 w-72">
                        {practice_study.study.description}
                      </td>
                      <td className=" text-sm whitespace-nowrap mr-2 px-2.5 py-0.5">
                        <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md">
                          {practice_study.study.study_type}
                        </span>
                      </td>
                      <td className="text-xs mr-2 px-2.5 py-0.5">
                        {practice_study?.study?.indications?.map(
                          (indication) => (
                            <span
                              key={indication.name}
                              className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md"
                            >
                              {indication.name}
                            </span>
                          ),
                        )}
                      </td>
                      <td className="p-4 text-sm whitespace-nowrap dark:text-gray-400">
                        <StudyStatusChip
                          status={practice_study.study.status}
                          text={practice_study.study.status}
                        />
                      </td>
                      <td className="p-4 text-sm whitespace-nowrap dark:text-gray-400">
                        <StudyPriorityChip
                          text={practice_study.study.priority}
                        />
                      </td>
                      <td className="p-4 text-sm whitespace-nowrap dark:text-gray-400">
                        {friendlyDate(
                          practice_study.study?.enrollment_start_dt,
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
