import { useAuth0 } from "@auth0/auth0-react"
import copy from 'copy-to-clipboard'
import { useState } from "react"
import { FieldValues, useForm, UseFormRegister } from "react-hook-form"
import toast from 'react-hot-toast'
import { Link } from "react-router-dom"
import { KeyedMutator } from "swr"
import { ageCalculator } from "../../shared/age-calculator"
import { dirtyValues } from "../../shared/dirty-values"
import { event } from "../../shared/event"
import { friendlyDate } from "../../shared/friendly-dates"
import { nextAppointmentSlashFormat } from "../../shared/next-appointment"
import { snakeToTitle } from "../../shared/text-utils"
import { useUpdate } from "../../shared/use-rest"
import { HeroIcon } from "../hero-icon"
import { HeaderInlineForm } from "./header-inline-form"
import { StudyScreenStatusChip } from "./study-screen-status-chip"

interface Props {
  patient: object
  mutatePatient: KeyedMutator<any>
  screens: []
  registerContactPref: UseFormRegister<FieldValues>
  submitContactPref: (data: any) => void
  submitDutyToWarnSent: (data: any) => void
  submitDutyToWarnAcknowledged: (data: any) => void
  infoExpanded: boolean
  handlePatientInfoExpand: any,
}

export function PatientInfoHeader(props: Props) {
  const { patient, screens, submitContactPref, submitDutyToWarnSent, submitDutyToWarnAcknowledged } = props
  const { getAccessTokenSilently } = useAuth0()
  const { register, handleSubmit, reset, control, formState: { dirtyFields, isDirty } } = useForm()
  const canContact = () => props.patient?.['can_contact'] == undefined || !!props.patient?.['can_contact']
  const [editing, setEditing] = useState('')
  const dutyToWarnSent = () => props.patient?.duty_to_warn_sent == undefined || !!props.patient?.duty_to_warn_sent
  const dutyToWarnAcknowledged = () => props.patient?.duty_to_warn_acknowledged == undefined || !!props.patient?.duty_to_warn_acknowledged

  const onSubmit = (data) => {
      const values = dirtyValues(dirtyFields, data)
      useUpdate('patients', patient?.['id'], values, getAccessTokenSilently).then((response) => {
        event({eventName: 'PatientInfoUpdate', meta: { patient_id: patient?.['id'], element: 'PatientInfoHeader', fields: Object.keys }, getAccessTokenSilently})
        props.mutatePatient()
        setEditing('')
      }, (error) => {
        // console.dir(error);
      })
    }

  const handleEdit = (attributeName) => {
    reset({[attributeName]: patient?.[attributeName]})
    setEditing(attributeName)
  }

  return (
    <div className={`p-4 mb-4 rounded-lg border border-gray-200 shadow-md ${!canContact() ? 'bg-red-300 border-red-400' : 'text-gray-700 bg-white dark:bg-transparent dark:text-gray-200'}`} >
      <div className="flex">
        <h2 className="text-xl font-bold">{patient.given_name} {patient.family_name}{patient.suffix ? `, ${patient.suffix}` : null }</h2>
      </div>
      <div className='flex flex-wrap'>
        <div className='pt-1 items-center space-y-1'>
          <div className="flex hover:cursor-pointer mt-1" onClick={() => copy(patient.mrn) && toast('📋 MRN Copied to Clipboard!')}>
            <div className='font-bold text-sm mr-1'>
              MRN
            </div>
            <div className='mr-6 text-sm'>
              {patient.mrn}
            </div>
          </div>
          <div className="flex">
            <div onClick={() => handleEdit('referring_provider')} className='font-bold text-sm mr-1'>
              Provider
            </div>
            <div className={`flex hover:cursor-pointer mr-6 text-sm ${patient.referring_provider ? 'dark:text-gray-200': 'text-red-500 dark:text-red-400'}`}>
              <div onClick={() => handleEdit('referring_provider')} className={editing == 'referring_provider' ? 'hidden' : 'flex '}>{patient.referring_provider || 'Unknown' }
                <div className="opacity-0 flex items-center group-hover:opacity-100 pr-2">
                  <HeroIcon icon="PencilAltIcon" className="ml-2 h-4" />
                </div>
              </div>
              <HeaderInlineForm attributeName='referring_provider' value={patient.email} reset={reset} handleSubmit={handleSubmit} onSubmit={onSubmit} register={register} editing={editing} setEditing={setEditing} />
            </div>
          </div>
          <div className='flex items-baseline pb-0'>
            <div className='font-bold text-sm'>
              Source
            </div>
            <div className="flex text-sm ml-1">
              {snakeToTitle(patient?.source)}
            </div>
          </div>
          <div className='flex items-baseline pb-0'>
            <div className='font-bold text-sm'>
              Next Appointment
            </div>
            <div className="flex flex-nowrap text-sm ml-1">
              {nextAppointmentSlashFormat(patient)}
            </div>
          </div>
          <div className='flex items-baseline pb-0'>
            <div className='font-bold text-sm'>
              Age
            </div>
            <div className="flex text-sm ml-2">
            {ageCalculator(patient.dob)} {patient.dob ? `(${friendlyDate(patient.dob)})` : '' }
            </div>
          </div>
        </div>
        <div className='flex flex-col space-y-1'>
          <div className="group flex items-center mt-1">
            <div onClick={() => handleEdit('preferred_email')} className={`text-sm mr-1`}>
              <HeroIcon icon="MailIcon" className={`h-5 w-5`} outline />
            </div>
            <div className={` text-sm ${editing == 'preferred_email' ? 'hidden' : 'flex'}`}>
              <a className={`${patient.email || patient.preferred_email ? 'dark:text-gray-200': 'text-red-500'}`} href={`mailto:${patient.preferred_email || patient.email}`}>{patient.preferred_email || patient.email || 'Unknown'}</a>
            </div>
            <div onClick={() => handleEdit('preferred_email')} className={editing == 'preferred_email' ? 'hidden' : 'flex'}>
              <div className="opacity-0 flex items-center group-hover:opacity-100 pr-2 hover:cursor-pointer">
                <HeroIcon icon="PencilAltIcon" className="ml-2 h-4" />
              </div>
            </div>
            <HeaderInlineForm attributeName='preferred_email' value={patient.preferred_email || patient.email} reset={reset} handleSubmit={handleSubmit} onSubmit={onSubmit} register={register} editing={editing} setEditing={setEditing} />
          </div>
          <div className="group flex items-center">
            <div onClick={() => setEditing('preferred_phone')} className={`text-sm mr-1`}>
              <HeroIcon icon="PhoneIcon" className={`h-5 w-5 `} outline />
            </div>
            <div className={`mr-6 text-sm ${editing == 'preferred_phone' ? 'hidden' : 'flex'}`}>
              <a className={`${patient.phone && patient.preferred_phone }} ? 'dark:text-gray-200': 'text-red-500'}`} href={`tel:${patient.preferred_phone || patient.phone}`}>{patient.preferred_phone || patient.phone || 'Unknown'}</a>
            </div>
            <div onClick={() => handleEdit('preferred_phone')} className={editing == 'preferred_phone' ? 'hidden' : 'flex'}>
              <div className="opacity-0 flex items-center group-hover:opacity-100 pr-2 hover:cursor-pointer">
                <HeroIcon icon="PencilAltIcon" className="ml-2 h-4" />
              </div>
            </div>
            <HeaderInlineForm attributeName='preferred_phone' value={patient.preferred_phone || patient.phone} reset={reset} handleSubmit={handleSubmit} onSubmit={onSubmit} register={register} editing={editing} setEditing={setEditing} />
          </div>
        </div>
        <div className='items-baseline pb-1'>
          <div className='font-bold text-sm mr-1 mt-2'>
            Screening Studies
          </div>
          <div className="flex flex-col">
            { screens.map((screen, index) =>
              <Link key={index} to={`/patients/${patient.id}/screens/${screen.id}`}>
                <StudyScreenStatusChip text={ screen.study.abbreviation } status={screen.status} />
              </Link>
            ) }
          </div>
        </div>
        
      </div>
      <div className="flex-1">
        {/* Contact Preferences Form */}
            <div className="flex pt-2">
              <div className="flex space-x-2 align-top">
                <div className="flex">
                  <div onClick={() => submitContactPref({can_contact: true})} className={`${!canContact() ? 'cursor-pointer' : 'cursor-default'} `}>
                    <HeroIcon
                      icon="CheckCircleIcon"
                      className={`w-5 h-5 flex ${canContact() ? 'text-topo-green-500' : 'text-gray-500 dark:text-gray-400 hover:text-topo-green-500 dark:hover:text-topo-green-500'}`}
                    />
                  </div>
                  <div onClick={() => submitContactPref({can_contact: false})} className={`${canContact() ? 'cursor-pointer' : 'cursor-default' }`}>
                    <HeroIcon
                      icon="XCircleIcon"
                      className={`ml-1 w-5 h-5 flex ${!canContact() ? 'text-red-500' : 'text-gray-500 dark:text-gray-400 hover:text-red-500'}`}
                    />
                  </div>
                </div>
                <div className={`flex text-sm space-x-1 font-medium ${canContact() ? 'text-gray-900 dark:text-gray-300' : ( canContact() ?  'text-gray-900 dark:text-gray-300': 'text-red-500' )}`}>
                  <div className="font-bold">{canContact() ? 'Yes,' : 'No,'}</div>
                  <div>{`${canContact() ? 'can' : (canContact() ? 'can' : 'cannot')} be contacted for research`}</div>
                </div>
            </div>
          </div>

      </div>
      <div className="flex-1">
        {/* DTW Sent Form */}
            <div className="flex pt-2">
              <div className="flex pr-4 space-x-2 items-center">
                <div className="flex">
                  <div onClick={() => submitDutyToWarnSent({duty_to_warn_sent: true})} className={`${!dutyToWarnSent() ? 'cursor-pointer' : 'cursor-default'} `}>
                    <HeroIcon
                      icon="CheckCircleIcon"
                      className={`w-5 h-5 flex ${dutyToWarnSent() ? 'text-topo-green-500' : 'text-gray-500 dark:text-gray-400 hover:text-topo-green-500 dark:hover:text-topo-green-500'}`}
                    />
                  </div>
                  <div onClick={() => submitDutyToWarnSent({duty_to_warn_sent: false})} className={`${dutyToWarnSent() ? 'cursor-pointer' : 'cursor-default' }`}>
                    <HeroIcon
                      icon="XCircleIcon"
                      className={`ml-1 w-5 h-5 flex ${!dutyToWarnSent() ? 'text-red-500' : 'text-gray-500 dark:text-gray-400 hover:text-red-500'}`}
                    />
                  </div>
                </div>
                <div className={`flex text-sm space-x-1 font-medium align-middle ${dutyToWarnSent() ? 'text-gray-900 dark:text-gray-300' : ( dutyToWarnSent() ?  'text-gray-900 dark:text-gray-300': 'text-red-500' )}`}>
                  {/* <div className="font-bold">{canContact() ? 'Yes' : 'No'}</div> */}
                  <div>{`Duty To Warn ${dutyToWarnSent() ? 'sent': 'not sent'}`}</div>
                </div>
            </div>
          </div>
      </div>
      <div className="flex-1">
        {/* DTW ACK Form */}
            <div className="flex pt-2">
              <div className="flex pr-4 space-x-2 items-center">
                <div className="flex">
                  <div onClick={() => submitDutyToWarnAcknowledged({duty_to_warn_acknowledged: true})} className={`${!dutyToWarnAcknowledged() ? 'cursor-pointer' : 'cursor-default'} `}>
                    <HeroIcon
                      icon="CheckCircleIcon"
                      className={`w-5 h-5 flex ${dutyToWarnAcknowledged() ? 'text-topo-green-500' : 'text-gray-500 dark:text-gray-400 hover:text-topo-green-500 dark:hover:text-topo-green-500'}`}
                    />
                  </div>
                  <div onClick={() => submitDutyToWarnAcknowledged({duty_to_warn_acknowledged: false})} className={`${dutyToWarnAcknowledged() ? 'cursor-pointer' : 'cursor-default' }`}>
                    <HeroIcon
                      icon="XCircleIcon"
                      className={`ml-1 w-5 h-5 flex ${!dutyToWarnAcknowledged() ? 'text-red-500' : 'text-gray-500 dark:text-gray-400 hover:text-red-500'}`}
                    />
                  </div>
                </div>
                <div className={`flex text-sm space-x-1 font-medium align-middle ${dutyToWarnAcknowledged() ? 'text-gray-900 dark:text-gray-300' : ( dutyToWarnAcknowledged() ?  'text-gray-900 dark:text-gray-300': 'text-red-500' )}`}>
                  {/* <div className="font-bold">{canContact() ? 'Yes' : 'No'}</div> */}
                  <div>{`Duty to Warn ${dutyToWarnAcknowledged() ? 'acknowledged' : 'not acknowledged' }`}</div>
                </div>
            </div>
          </div>
      </div>
    </div>
  )
}
