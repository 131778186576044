import { HeroIcon, IconName } from "./hero-icon";

interface Props {
  icon: IconName;
  className?: string;
  iconClassName?: string;
  outline?: boolean;
  text: string;
  bgColorClass?: string
  bgHoverColorClass?: string
  focusRingColorClass?: string
}

export function ButtonLeadingIcon(props: Props) {

  props = {
    outline: false,
    bgColorClass: 'bg-topo-green',
    bgHoverColorClass: 'hover:bg-topo-green-600',
    focusRingColorClass: 'focus:ring-topo-green-500',
    ...props
  }

  return (
    <button
        type="button"
        className={`inline-flex items-center p-2 pr-4 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white ${props.bgColorClass} ${props.bgHoverColorClass} focus:outline-none focus:ring-2 focus:ring-offset-2 ${props.focusRingColorClass} ${props.className}`}
      >
        <HeroIcon icon={props.icon} className={`h-5 w-7 mr-1 text-white ${props.iconClassName}`} outline={props.outline} />
        {props.text}
      </button>
  )
}
