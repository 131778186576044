import {
  type Dispatch,
  type SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"

import { useAuth0 } from "@auth0/auth0-react"
import TextField from "@mui/material/TextField"
import { Controller, useForm } from "react-hook-form"
import type { KeyedMutator } from "swr"
import { AuthorizationContext } from "../../../shared/authorization-provider"
import { useCreate, useIndex } from "../../../shared/use-rest"
import { useKeyPress } from "../../../shared/useKeyPress"

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>
  refreshData: KeyedMutator<string>
}

export function QualityAssuranceModal(props: Props) {
  const { getAccessTokenSilently } = useAuth0()
  const { practiceId } = useContext(AuthorizationContext)

  const {
    data: screens,
    isLoading: isLoadingScreens,
    isError: isErrorScreens,
    mutate: mutateScreens,
  } = useIndex("admin/practice_studies", `practice_id=${practiceId}`)
  const {
    data: users,
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    mutate: mutateUsers,
  } = useIndex("admin/users", `practice_id=${practiceId}`)

  const [filteredUsers, setFilteredUsers] = useState([])

  useEffect(() => {
    setFilteredUsers(users.filter((user) => user.role !== "Admin"))
  }, [users])

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      min_confidence_score: 0,
      max_confidence_score: 100,
      min_dt: "",
      max_dt: "",
      current_statuses: ["disqualified"],
      study_id: "",
      current_assigned_to_users: [],
      assign_to_user_ids: [],
      batch_size: 10,
    },
  })

  const closeModal = () => {
    props.setOpen(false)
  }

  useKeyPress(["Escape"], closeModal)

  const onSubmit = async (data) => {
    if (data.max_dt === "") {
      data.max_dt = undefined
    }
    if (data.min_dt === "") {
      data.min_dt = undefined
    }
    data.practice_id = practiceId
    await useCreate("qa_screens", data, getAccessTokenSilently)
    props.setOpen(false)
    props?.refreshData()
    reset()
  }

  return (
    <>
      <div
        className="fixed flex left-0 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full"
        id="edit-practice-modal"
      >
        <div className="relative w-full h-full max-w-2xl px-4 md:h-auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <!-- Modal content --> */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
              {/* <!-- Modal header --> */}
              <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
                <h3 className="text-xl font-semibold dark:text-white">
                  Create QA Batch
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                  onClick={() => closeModal()}
                  onKeyUp={() => closeModal()}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Close Modal</title>
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <div className="p-6 space-y-6">
                <div className="grid grid-cols-6 gap-6">
                  {/* <input type="hidden" {...register('patient_id')} />
                    <input type="hidden" {...register('contacted_at')} /> */}
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="screen"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Study
                    </label>
                    <select
                      {...register("study_id")}
                      name="study_id"
                      id="study_id"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    >
                      <option />
                      {screens?.map((screen) => (
                        <option
                          key={screen?.study?.id}
                          value={screen?.study?.id}
                        >
                          {screen?.study?.abbreviation}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="min_dt"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Minimum Screen Date
                    </label>
                    <Controller
                      name="min_dt"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="datetime-local"
                          sx={{
                            width: 250,
                            boxShadow: "none",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="max_dt"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Maximum Screen Date
                    </label>
                    <Controller
                      name="max_dt"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="datetime-local"
                          sx={{
                            width: 250,
                            boxShadow: "none",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="min_confidence_score"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Minimum Confidence Score
                    </label>
                    <input
                      type="number"
                      min="0"
                      max="100"
                      {...register("min_confidence_score")}
                      name="min_confidence_score"
                      id="min_confidence_score"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="max_confidence_score"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Maximum Confidence Score
                    </label>
                    <input
                      type="number"
                      min="0"
                      max="100"
                      {...register("max_confidence_score")}
                      name="max_confidence_score"
                      id="max_confidence_score"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="current_statuses"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Current Screen Statuses
                    </label>
                    <select
                      {...register("current_statuses")}
                      name="current_statuses"
                      id="current_statuses"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                      multiple
                    >
                      <option>disqualified</option>
                      <option>assigned</option>
                      <option>chart_review</option>
                      <option>follow_up</option>
                      <option>provider_feedback</option>
                      <option>moved_to_esource</option>
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="batch_size"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Number of Screens
                    </label>
                    <input
                      type="number"
                      min="0"
                      max="100"
                      {...register("batch_size")}
                      name="batch_size"
                      id="batch_size"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="current_assigned_to_users"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Users to QA
                    </label>
                    <select
                      {...register("current_assigned_to_users")}
                      name="current_assigned_to_users"
                      id="current_assigned_to_users"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                      multiple
                    >
                      <option />
                      {filteredUsers?.map((user) => (
                        <option key={user?.id} value={user?.id}>
                          {user?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="assign_to_user_ids"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Users doing the QA
                    </label>
                    <select
                      {...register("assign_to_user_ids")}
                      name="assign_to_user_ids"
                      id="assign_to_user_ids"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                      multiple
                    >
                      <option />
                      {filteredUsers?.map((user) => (
                        <option key={user?.id} value={user?.id}>
                          {user?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* <!-- Modal footer --> */}
                <div className="items-center pt-4 border-t border-gray-200 rounded-b dark:border-gray-700">
                  <button
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        onClick={() => closeModal()}
        onKeyUp={() => closeModal()}
        id="modal-backdrop"
        className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
      />
    </>
  )
}
