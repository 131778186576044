import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export const MalformedParameterProvider = ({ children }) => {
  // This component was created because google calendar lazily inserts parameters when storing links, breaking the URL and breaking the router
  // Example: &sa=D&source=calendar&ust=&usg

  const location = useLocation()
  const navigate = useNavigate()

  const wellformedPathname = (str) => {
    const index = str.indexOf('&');
    if (index !== -1) {
      return str.substring(0, index) + '?&' + str.substring(index + 1)
    }
    return str
  }

  const isMalformedPathname = (str) => (str.indexOf('&') !== -1) && (str.indexOf('?') === -1)

  useEffect(() => {
    if (isMalformedPathname(location.pathname)) {
      navigate(wellformedPathname(location.pathname))
    }
 }, [])

  return children
}
