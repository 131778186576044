import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Avatar } from '../avatar';
import { Tooltip } from 'flowbite-react';
import { HeroIcon } from '../hero-icon';
import { useCreate, useIndex } from '../../shared/use-rest';
import { event } from '../../shared/event';
import { friendlyRelative, friendlyTimeDate } from '../../shared/friendly-dates';
import { formatRelative, parseISO } from 'date-fns';

interface Props {
  text: string,
}

export function StudyPriorityChip(props: Props) {
  let color = ''
  switch (props.text) {
    case 'Low':
      color = 'gray'
      break;
    case 'Medium':
      color = 'yellow'
      break;
    case 'High':
      color = 'red'
      break;
    default:
      color = 'blue'
      break;
  }

  return (
    <span
        className={`bg-${color}-100 text-${color}-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md whitespace-nowrap`}>
        { props.text || "Unknown"  }
    </span>
  )
}
