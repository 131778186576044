import { useAuth0 } from "@auth0/auth0-react"
import { useContext, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AuthorizationContext } from "../shared/authorization-provider"
import { useCreate } from "../shared/use-rest"
import { Patients } from "./patients"

export function Referrals() {
  const { getAccessTokenSilently } = useAuth0()
  const { practiceId } = useContext(AuthorizationContext)
  const [bookmarkId, setBookmarkId] = useState("")
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!practiceId || !getAccessTokenSilently) {
      return
    }
    const createBookmarkAndNavigate = async () => {
      const response = await useCreate(
        "filter_and_sort_bookmark",
        {
          filter_and_sort: {
            filtering: {
              filters: [
                {
                  field: "status",
                  operator_value: "equals",
                  value: "provider_referral",
                },
              ],
            },
            practice_id: practiceId,
            search: "",
            sorting: [],
          },
        },
        getAccessTokenSilently,
      )
      setBookmarkId(response?.data?.data?.id)
      localStorage.setItem("bookmarkId", response?.data?.data?.id)
      setSearchParams({ bookmarkId: bookmarkId })
    }
    createBookmarkAndNavigate()
  }, [practiceId, getAccessTokenSilently])

  if (!bookmarkId) return <></>

  return <Patients />
}
