import { useContext } from "react"
import { Link } from "react-router-dom"
import { dashboardActions } from "../components/dashboard/dashboard-actions"
import {
  groupedAfternoonImages,
  groupedEveningImages,
  groupedMorningImages,
} from "../images/groupedDashboardImages"
import { AuthorizationContext } from "../shared/authorization-provider"
import { classNames } from "../shared/class-names"

const _ = require("lodash")
const hour = new Date().getHours()
const morning = hour < 12 && hour > 3
const afternoon = hour < 17 && hour >= 12
const evening = hour >= 17 && hour <= 3

function useGreeting() {
  const welcomeTypes = ["Good morning", "Good afternoon", "Good evening"]

  if (morning) return welcomeTypes[0]
  if (afternoon) return welcomeTypes[1]
  return welcomeTypes[2]
}

function greetingImage() {
  if (morning) return _.sample(groupedMorningImages)
  if (afternoon) return _.sample(groupedAfternoonImages)
  if (evening) return _.sample(groupedEveningImages)
}

export function Home() {
  const greeting = useGreeting()
  const { currentUser } = useContext(AuthorizationContext)

  return (
    <>
      <div className="relative overflow-hidden shadow-lg cursor-default m-6 rounded-md mb-0">
        <img
          className="object-cover object-[0px_ -120px] w-full h-36"
          src={greetingImage()}
          alt="dashboard"
        />

        <div className="absolute top-0 left-0 p-2">
          <div className="dark:rounded-md dark:bg-gray-900 dark:bg-opacity-80 py-6 px-4">
            <h4 className="mb-1 text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-300">
              {greeting}
              {currentUser?.given_name && ", "}
              {currentUser?.given_name}!
            </h4>
            <p className="leading-normal text-xl font-medium text-gray-900 dark:text-gray-300">
              Where are you heading today?
            </p>
          </div>
        </div>
      </div>
      <section aria-labelledby="quick-links-title" className="mt-6 ">
        <div className="rounded-lg overflow-hidden shadow divide-y divide-gray-200 dark:divide-x dark:divide-gray-600 dark:divide-y dark:border-b dark:border-gray-600 dark:border-r dark:text-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-3 sm:gap-px dark:gap-0 m-5">
          <h2 className="sr-only" id="quick-links-title">
            Quick links
          </h2>
          {dashboardActions.map((action, actionIdx) => (
            <div
              key={action.name}
              className={classNames(
                actionIdx === 0
                  ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                  : "",
                // actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                // actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                // actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                "relative group p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500 hover:bg-gray-50 dark:hover:bg-gray-700",
              )}
            >
              <div>
                <span
                  className={classNames(
                    action.iconBackground,
                    action.iconForeground,
                    "rounded-lg inline-flex p-3 ring-0 ring-white",
                  )}
                >
                  <action.icon className="h-6 w-6" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-8">
                <h3 className="text-lg font-medium">
                  <Link to={action.href} className="focus:outline-none">
                    {/* Extend touch target to entire panel */}
                    <span className="absolute inset-0" aria-hidden="true" />
                    {action.name}
                  </Link>
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  {action.description}
                </p>
              </div>
              <span
                className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                aria-hidden="true"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <title>X Button</title>
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>
          ))}
        </div>
      </section>
    </>
  )
}
