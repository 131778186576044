import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Utah } from '../../../components/utah';
import { Tooltip } from 'flowbite-react';
import { HeroIcon } from '../../../components/hero-icon';
import { useCreate, useIndex, useShow } from '../../../shared/use-rest';
import { Avatar } from '../../../components/avatar';

export function PatientFinder() {
  const { data } = useShow('metabase_iframes', 'patient-finder')

  return (
    <>
     {data && <iframe
        src={data}
        frameBorder={0}
        className="inset-0 w-full h-full"
        allowTransparency
    /> }
    </>
  );
}
