import React from "react"
import ErrorScreen from "./failed-to-load"
import LoadingScreen from "./loading-screen"

const SplashScreen = ({ isLoading, isError }) => {
  return (
    <div>
      {isLoading ? <LoadingScreen isLoading={isLoading} /> : ""}
      {isError ? <ErrorScreen /> : ""}
    </div>
  )
}

export default SplashScreen
