import {
  DataGridPro,
  type GridCellParams,
  type GridColDef,
  type MuiEvent,
} from "@mui/x-data-grid-pro"
import { Link } from "react-router-dom"

import React from "react"
import { useIndex } from "../../shared/use-rest"
import { HeroIcon } from "../hero-icon"
import { StudyScreenStatusChip } from "../patients/study-screen-status-chip"

interface Props {
  screens: never[]
  handleScreenNavigate
}

export function QualityAssuranceTable(props: Props) {
  const { data: usersData, isLoading: isLoadingUsers } = useIndex("users")

  const columnDefaults: Partial<GridColDef> = {
    width: 150,
    headerClassName: "bg-gray-100 text-gray-500",
    display: "flex",
  }
  const columns: GridColDef[] = [
    {
      field: "study",
      headerName: "Study",
      ...columnDefaults,
      width: 225,
      renderCell: (params) =>
        renderCellStudy(params, props.handleScreenNavigate),
    },
    {
      field: "assigned_to",
      headerName: "Assigned",
      ...columnDefaults,
      width: 225,
      renderCell: (params) => renderCellAssigned(params),
    },
    {
      field: "qa_assigned_to",
      headerName: "QA Assignee",
      ...columnDefaults,
      width: 225,
      renderCell: (params) => renderCellQaAssigned(params, usersData),
    },
    {
      field: "qa_review_status",
      headerName: "QA Review Status",
      ...columnDefaults,
      renderCell: (params) => renderCellQaStatus(params),
    },
    {
      field: "actions",
      headerName: "Actions",
      ...columnDefaults,
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) =>
        renderCellActions(
          params,
          props.handleScreenNavigate,
          props.handleScreenStart,
        ),
    },
  ]

  return (
    <div className="flex h-screen">
      <div className="flex-grow">
        <DataGridPro
          rows={props.screens}
          columns={columns}
          disableDensitySelector={true}
          onCellDoubleClick={(
            params: GridCellParams,
            event: MuiEvent<MouseEvent>,
          ) => {
            event.defaultMuiPrevented = true
            props.handleScreenNavigate(params.row.id, params.row.patient_id)
          }}
          initialState={{
            filter: {
              filterModel: {
                items: [
                  {
                    field: "study_status",
                    operator: "isAnyOf",
                    value: ["enrolling", "active"],
                  },
                ],
              },
            },
            columns: {
              columnVisibilityModel: {
                // Hide these columns by default, the other columns will remain visible
                indication: false,
              },
            },
            density: "comfortable", // contact
          }}
          sx={{
            "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            ".dark & .MuiDataGrid-columnHeader": {
              backgroundColor: "rgb(55 65 81)",
              color: "#FFFFFF",
            },
          }}
        />
      </div>
    </div>
  )
}

function renderCellQaAssigned(props: GridCellParams, usersData) {
  const user = usersData.find(
    (user) => user.id === props.row?.qa_assigned_to_user_id,
  )
  return <div className="text-sm font-medium">{user?.name || "None"}</div>
}

function renderCellAssigned(props: GridCellParams) {
  return (
    <div className="text-sm font-medium">
      {props.row?.assigned_to?.name || "None"}
    </div>
  )
}

function renderCellQaStatus(props: GridCellParams) {
  return (
    <div className="text-sm font-medium">{props.row?.qa_status || "None"}</div>
  )
}

function renderCellStudy(params: GridCellParams, handleScreenNavigate) {
  return (
    <Link
      to={"#"}
      className="text-sm font-medium hover:cursor-pointer w-full flex-col"
      onClick={() =>
        handleScreenNavigate(params.row?.id, params.row?.patient_id)
      }
    >
      <StudyScreenStatusChip
        text={params.row.study?.abbreviation?.substring(0, 13)}
        status={params.row?.screen_status}
        tooltipPlacement="right"
      />
    </Link>
  )
}

function renderCellActions(props: GridCellParams, handleScreenNavigate) {
  if (!handleScreenNavigate) return <>...</>
  return (
    <button
      type="button"
      onClick={() => handleScreenNavigate(props.row?.id, props.row?.patient_id)}
      className="bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 text-white text-xs font-medium mr-6 px-2.5 py-1.5 rounded-md flex items-center justify-between"
    >
      Review
      <HeroIcon icon="ArrowCircleRightIcon" className="ml-1 w-5 h-5 flex" />
    </button>
  )
}
