import { titleCase } from "change-case-all"
import { usePrefersJoy } from "../shared/use-prefers-joy"

export function SeasonalEmoji() {
  const joy = usePrefersJoy()

  // https://stackoverflow.com/a/54501026

  const monthDay = (month, day) => ({ month, day })
  const toMonthDay = (date) => monthDay(date.getMonth(), date.getDate())

  const before = (md1, md2) =>
    md1.month < md2.month || (md1.month === md2.month && md1.day <= md2.day)

  const after = (md1, md2) => !before(md1, md2)

  const between = (mdX, mdLow, mdHigh) =>
    after(mdX, mdLow) && before(mdX, mdHigh)

  const season = (date, seasons) =>
    ((md = toMonthDay(date)) =>
      Object.keys(seasons).find((season) => seasons[season](md)))()

  const MARCH_EQUINOX = monthDay(2, 20)
  const JUNE_SOLSTICE = monthDay(5, 21)
  const SEPTEMBER_EQUINOX = monthDay(8, 23)
  const DECEMBER_SOLSTICE = monthDay(11, 21)
  const NEW_YEAR = monthDay(0, 1)

  const seasons = {
    spring: (d) => between(d, MARCH_EQUINOX, JUNE_SOLSTICE),
    summer: (d) => between(d, JUNE_SOLSTICE, SEPTEMBER_EQUINOX),
    fall: (d) => between(d, SEPTEMBER_EQUINOX, DECEMBER_SOLSTICE),
    winter: (d) =>
      between(d, DECEMBER_SOLSTICE, NEW_YEAR) ||
      between(d, NEW_YEAR, MARCH_EQUINOX),
  }

  const seasonNow = season(new Date(), seasons)

  const renderObject = {
    spring: { emoji: "🌷" },
    summer: { emoji: "🔅" },
    fall: { emoji: "🍂" },
    winter: { emoji: "☃️" },
  }

  if (!joy) return <></>

  if (!seasonNow) return <></>

  return (
    <div
      className="hover:cursor-pointer"
      title={`Happy ${titleCase(seasonNow)}!`}
    >
      {renderObject[seasonNow]?.emoji}
    </div>
  )
}
