import { Dispatch, SetStateAction } from 'react'
import { useKeyPress } from '../../shared/useKeyPress'
import { indexUrl, useShow, useUpdate } from '../../shared/use-rest'
import { mutate } from 'swr'
import { useAuth0 } from '@auth0/auth0-react'

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>
  uploadId: string
}

export function UploadShowModal(props: Props) {

  const { data: upload, mutate: mutateUpload } = useShow('uploads', props.uploadId)
  const { getAccessTokenSilently } = useAuth0()

  const handleClose = () => {
    props.setOpen(false)
    mutate(indexUrl('uploads'))
  }

  const handleReprocess = () => {
    useUpdate('uploads', props.uploadId, { status: 'pending' }, getAccessTokenSilently).then(() => mutateUpload())
  }

  useKeyPress(['Escape'], handleClose)

  return (
    <>
    <div
      className="fixed flex left-0 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full"
      id="add-user-modal"
    >
      <div className="relative w-full h-full max-w-2xl px-4 md:h-auto">
        {/* <!-- Modal content --> */}
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
          {/* <!-- Modal header --> */}
          <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
            <h3 className="text-xl font-semibold dark:text-white" title={props.uploadId}>
              Upload Detail
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
              onClick={() => handleClose()}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          {/* <!-- Modal body --> */}
          <div className="p-6 dark:text-gray-200">

            <div className='font-medium'>
              Source File
            </div>
            <div className='mt-0'>
              <a className='underline text-blue-600 hover:text-blue-900 visited:text-purple-600 text-sm' href={upload.s3_presigned_url} target="_blank">Download Source File</a>
            </div>
            <div className='font-medium mt-4'>
              Counts
            </div>
            <div>
              <div className='text-sm'>Created: {upload.created_count}</div>
              <div className='text-sm'>Duplicate: {upload.duplicate_count}</div>
              <div className='text-sm'>Failed: {upload.failed_count}</div>
            </div>
            <div className='font-medium mt-4'>
              Uploaded At
            </div>
            <div className='text-sm mb-4'>
              {upload.created_at}
            </div>
            <div className='font-medium mt-4'>
              Last Updated At
            </div>
            <div className='text-sm mb-4'>
              {upload.updated_at}
            </div>
          </div>
            {/* <!-- Modal footer --> */}
            <div className="items-center p-5 border-t border-gray-200 rounded-b dark:border-gray-700">
              <button
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                type="submit"
                onClick={() => handleReprocess()}
                >
                Reprocess
              </button>
            </div>
        </div>
      </div>
    </div>
    <div id="modal-backdrop" className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"></div>
    </>
  )
}
