import { useAuth0 } from "@auth0/auth0-react"
import type { Dispatch, SetStateAction } from "react"
import type { KeyedMutator } from "swr"
import { NewSlideover } from "../../../components/new-slideover"
import { useCreate, useIndex } from "../../../shared/use-rest"

interface Props {
  open?: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  refreshData: KeyedMutator<{ data: any[] }>
}

export function StudyNewSlideover(props: Props) {
  const { data: sponsorsResponse } = useIndex("admin/sponsors")
  const { getAccessTokenSilently } = useAuth0()
  const sponsors = sponsorsResponse.map((item) => ({
    value: item.id,
    label: item.name,
  }))
  const onSubmit = async (data) => {
    await useCreate("admin/studies", data, getAccessTokenSilently)
    props.setOpen(false)
    props.refreshData()
  }

  const entity = {
    label: "Study",
    key: "study",
  }
  const fields = [
    { key: "name", label: "Name" },
    { key: "nickname", label: "Nickname" },
    { key: "abbreviation", label: "Abbreviation" },
    { key: "description", label: "Description" },
    { key: "protocol_version", label: "Protocol Version" },
    { key: "compound", label: "Compound" },
    { key: "phase", label: "Phase" },
    { key: "call_script", label: "Call Script" },
    { key: "sponsor_id", label: "Sponsor", options: sponsors },
    { key: "ie_criteria", label: "I/E Criteria", type: "wysiwyg" },
    { key: "key_stats", label: "Key Stats", type: "wysiwyg" },
    { key: "criteria_summary", label: "Criteria Summary", type: "wysiwyg" },
    {
      key: "how_you_can_help",
      label: "How a provider can help:",
      type: "wysiwyg",
    },
    { key: "provider_summary", label: "Provider Summary", type: "wysiwyg" },
  ]

  return (
    <NewSlideover
      entity={entity}
      fields={fields}
      onSubmit={onSubmit}
      open={props.open}
      setOpen={props.setOpen}
    />
  )
}
