import { useEffect, useState } from "react"

export function prefersDark() {
  if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    return true
  }
  else return false
}

export function usePrefersDarkMode () {
  const [prefersDarkMode, setPrefersDarkMode] = useState(prefersDark())

  useEffect(() => {
    function handleChangeStorage () {
      setPrefersDarkMode(prefersDark())
    }

    window.addEventListener('storage', handleChangeStorage);
    return () => window.removeEventListener('storage', handleChangeStorage);

  }, [])

  return prefersDarkMode
}
