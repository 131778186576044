import Snowfall from "react-snowfall"
import { usePrefersSnow } from "../shared/use-prefers-snow"

export function Snow() {
  const letItSnow = usePrefersSnow()
  if (letItSnow)
    return (
      <>
        <Snowfall
          color="#c7eeff"
          radius={[0.25, 1]}
          speed={[0.05, 0.5]}
          wind={[-0.05, 0.05]}
        />
      </>
    )
  return <></>
}
