import type { Placement } from "@floating-ui/core"
import { Tooltip } from "flowbite-react"
import { statusOptions } from "../../shared/statuses"
import { snakeToTitle } from "../../shared/text-utils"

interface Props {
  status?: string
  text?: string
  tooltipPlacement?: string
}

export function StudyScreenStatusChip(props: Props) {
  const textColor = statusOptions[props.status]?.textColor || "black"
  const bgColor = statusOptions[props.status]?.bgColor || "bg-blue-100"
  const statusAcronym = statusOptions[props.status]?.acronym || "N/A"
  const statusTitle = snakeToTitle(
    props.status ? props?.status : "Unknown Status",
  )
  const tooltipPlacement = props.tooltipPlacement || "start"

  return (
    <div
      title={statusTitle}
      className="flex relative mr-1 bg-white border border-gray-200 rounded-full text-xs font-medium pl-2 py-1 w-min whitespace-nowrap dark:bg-gray-700"
    >
      <div className="flex">
        <Tooltip
          content={statusTitle}
          style="light"
          placement={tooltipPlacement}
        >
          <div
            className={`${bgColor} ${textColor} flex absolute left-1 rounded-full h-5 w-7`}
          >
            <p className="flex m-auto font-small font-semibold">
              {statusAcronym}
            </p>
          </div>
        </Tooltip>
        <div className="grow pl-6 mx-2 text-sm">
          {snakeToTitle(props.text || props.status || "N/A")}
        </div>
      </div>
    </div>
  )
}
