// import "./styles.scss"

import { Color } from "@tiptap/extension-color"
import ListItem from "@tiptap/extension-list-item"
import TextStyle from "@tiptap/extension-text-style"
import {
  BubbleMenu,
  EditorContent,
  EditorProvider,
  FloatingMenu,
  useCurrentEditor,
  useEditor,
} from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useController } from "react-hook-form"
import HeaderMenu from "./toolbar"
import Toolbar from "./toolbar"

interface TiptapProps {
  control
  fieldName
  defaultValue
}

export default function Tiptap({
  control,
  fieldName,
  defaultValue,
}: TiptapProps) {
  const { field } = useController({ control, name: fieldName })
  const [showToolbar, setShowToolbar] = useState(false)

  const extensions = [
    TextStyle.configure({ types: [ListItem.name] }),
    StarterKit.configure({
      bulletList: {
        keepMarks: true,
        // keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
      orderedList: {
        keepMarks: true,
        // keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
    }),
  ]
  const editor = useEditor({
    extensions,
    content: defaultValue || "",
    editorProps: {
      attributes: {
        class:
          "prose max-w-none [&_ol]:list-decimal [&_ul]:list-disc p-2 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",
      },
    },
    onUpdate: ({ editor }) => {
      field.onChange(editor.getHTML()) // Update React Hook Form value on content change
    },
  })

  useEffect(() => {
    if (editor && defaultValue) {
      editor.commands.setContent(defaultValue)
    }
  }, [defaultValue, editor])

  return (
    <div onFocus={() => setShowToolbar(true)}>
      {showToolbar && <Toolbar editor={editor} />}
      <EditorContent editor={editor} />
    </div>
  )
}
