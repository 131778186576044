import { HeroIcon } from "../../../../components/hero-icon"
import { StudyStatusChip } from "../../../../components/patients/study-status-chip"

interface Props {
  study: object
}

export function StudyBuilderHeader(props: Props) {
  const { study } = props

  return (
    <div>
      <h3 className="p-0 mt-4 ml-4 mr-2 mb-2 text-xl font-bold text-gray-900 dark:text-white flex">
        <HeroIcon icon="DocumentTextIcon" className="text-gray-900 w-6 h-6" />
        {study?.abbreviation}
      </h3>
      <div className="ml-4 text-xs flex">
        <div className="font-medium mr-1">Name:</div> {study?.name}
      </div>
      <div className="ml-4 text-xs flex">
        <div className="font-medium mr-1">Description:</div>{" "}
        {study?.description}
      </div>
      <div className="ml-4 text-xs flex">
        <div className="font-medium mr-1">Indications:</div>{" "}
        {study?.indications?.map((indication) => indication.name).join(", ")}
      </div>
      <div className="ml-4 text-xs flex">
        <div className="font-medium mr-1">Compound:</div> {study?.compound}
      </div>
    </div>
  )
}
