import { snakeToTitle } from "../../shared/text-utils"

const convertOperatorToSymbol = (operator) => {
  const operatorsTable = {
    contains: "∋",
    equals: "=",
    not_equals: "≠",
    starts_with: "^",
    ends_with: "$",
    is_empty: "∅",
    is_not_empty: "!∅",
    is: "≡",
    not: "≢",
    is_after: ">",
    after: ">",
    onOrAfter: "≥",
    is_before: "<",
    before: "<",
    onOrBefore: "≤",
    isEmpty: "∅",
    isNotEmpty: "!∅",
    gt: ">",
    lt: "<",
    gte: "≥",
    lte: "≤",
    in: "∈",
    is_any_of: "∈",
  }

  return operatorsTable[operator]
}

export const displayFilters = (item, users = null) => {
  if (item.field === "assigned" && Array.isArray(item.value)) {
    return `${snakeToTitle(item.field)} ${convertOperatorToSymbol(item.operator_value)} ${item.value.map((value) => users.find((user) => user.id === value)?.name).join(", ")}`
  }
  if (item.field === "assigned" && !Array.isArray(item.value)) {
    return `${snakeToTitle(item.field)} ${convertOperatorToSymbol(item.operator_value)} ${users.find((user) => user.id === item.value)?.name}`
  }

  const value = Array.isArray(item.value)
    ? item.value.map((value) => snakeToTitle(value)).join(", ")
    : snakeToTitle(item.value)
  //   if (value_type === "array") {
  return `${snakeToTitle(item.field)} ${convertOperatorToSymbol(item.operator_value)} ${value}`
}
