import { useContext, useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { AuthorizationContext } from "../shared/authorization-provider"

export const allowedAdminRoles = ["Admin", "Manager"]
export const managerOrQaUserRoles = ["Admin", "Manager", "QaUser"]
export const managerOrUserRoles = ["Manager", "User", "QaUser"]

export const isAdmin = (currentUser) =>
  allowedAdminRoles.includes(currentUser?.role)
export const managerOrQaUser = (currentUser) =>
  managerOrQaUserRoles.includes(currentUser?.role)
export const managerOrUser = (currentUser) =>
  managerOrUserRoles.includes(currentUser?.role)

export const ProtectedAdmin = () => {
  const navigate = useNavigate()
  const { isLoading: isLoadingCurrentUser } = useContext(AuthorizationContext)

  useEffect(() => {
    if (isLoadingCurrentUser) return
    if (!isAdmin) navigate("/401")
  }, [isLoadingCurrentUser, navigate])

  if (isLoadingCurrentUser)
    return (
      <div className="p-4 text-bold dark:text-gray-200">Authorizing...</div>
    )
  return <Outlet />
}
