import { dateTimeISO, dateTimeISOWithoutTimezone, friendlyDateTime } from "./friendly-dates";

export const nextAppointmentISO = (patient) => (
  patient?.patient_appointments_future?.sort((a, b) => Date.parse(a.start_dt) - Date.parse(b.start_dt))?.[0]
)

export const nextAppointmentLocalTime = (patient) => {
	const nextAppointmentValue = nextAppointmentISO(patient)
	if (!!nextAppointmentValue) {
		return dateTimeISOWithoutTimezone(new Date(nextAppointmentISO(patient)?.start_dt + 'Z'))
	}
}

export const nextAppointmentPretty = (patient) => {
	const nextAppointmentValue = nextAppointmentISO(patient)?.start_dt
	if (!!nextAppointmentValue) {
		return dateTimeISO(nextAppointmentValue)
	}
}

export const nextAppointmentSlashFormat = (patient) => {
	const nextAppointmentValue = nextAppointmentISO(patient)?.start_dt
	if (!!nextAppointmentValue) return friendlyDateTime(nextAppointmentValue)
}
