import { TextInput } from "flowbite-react"
import { useEffect, useRef, useState } from "react"

export const SearchableDropDown = ({
  options,
  getSearchValue = (option) => option,
  getSubmitValue = (option) => option,
  valueIsSelected,
  setValue,
  sortOptions = true,
  formatDisplay = (option) => option,
}) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  const sortedOptions = sortOptions ? [...options].sort() : options
  const filteredOptions = sortedOptions.filter(
    (option) =>
      getSearchValue(option)
        ?.toLowerCase()
        ?.includes(searchTerm?.toLowerCase()) &&
      !valueIsSelected(getSubmitValue(option)),
  )

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div className="relative" ref={dropdownRef}>
      <TextInput
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value)
          setIsOpen(true)
        }}
        onClick={() => setIsOpen(true)}
      />
      {isOpen && (
        <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
          {filteredOptions.map((option) => (
            <li
              key={getSubmitValue(option)}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => {
                setValue(getSubmitValue(option))
                setIsOpen(false)
              }}
              onKeyUp={() => setValue(getSubmitValue(option))}
            >
              {formatDisplay(option)}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
