import {
  AcademicCapIcon,
  CalendarIcon,
  UploadIcon,
  UsersIcon,
} from "@heroicons/react/outline"
export const dashboardActions = [
  {
    icon: UsersIcon,
    name: "Patient Directory",
    href: "/patients",
    iconForeground: "text-rose-700",
    iconBackground: "bg-rose-50",
    description: "Start with the patient directory",
  },
  {
    icon: AcademicCapIcon,
    name: "Screen by Study",
    href: "/studies",
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
    description: "View all the studies available to screen patients into",
  },
  {
    icon: CalendarIcon,
    name: "Calendar",
    href: "/calendar",
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
    description: "View all apppointments across the practice",
  },
  // {
  //   icon: UploadIcon,
  //   name: 'Uploader',
  //   href: '/uploads',
  //   iconForeground: 'text-teal-700',
  //   iconBackground: 'bg-teal-50',
  //   description: 'Upload qualified patients, appointments, and other data into Waypoints for screening',
  // },
]
