import { useAuth0 } from "@auth0/auth0-react"
import { useContext, useEffect, useState } from "react"
import {
  DragDropContext,
  type DropResult,
  type ResponderProvided,
} from "react-beautiful-dnd"
import { useSearchParams } from "react-router-dom"
import { AuthorizationContext } from "../../shared/authorization-provider"
import { statuses } from "../../shared/statuses"
import { useIndex, useUpdate } from "../../shared/use-rest"
import { Column } from "./column"

export function Kanban() {
  const { currentUser, isLoading: isLoadingCurrentUser } =
    useContext(AuthorizationContext)
  const { user } = useAuth0()
  const { practiceId } = useContext(AuthorizationContext)
  const [filter, setFilter] = useState({})

  useEffect(() => {
    const userFilter = {
      filtering: {
        link_operator: "and",
        filters: [
          {
            field: "assigned_to_user_id",
            operator_value: "equals",
            value: currentUser.id,
          },
        ],
      },
    }

    setFilter(userFilter)
    return () => {
      setFilter({})
    }
  }, [currentUser])

  const onDragEnd = (
    { source, destination, draggableId: screenId, reason }: DropResult,
    provided: ResponderProvided,
  ) => {
    if (!reason) return
    if (!source || !destination) return
    const destinationStatus = destination.droppableId
    optimisticallyUpdateScreen(screenId, destinationStatus)
  }
  const { getAccessTokenSilently } = useAuth0()
  const { data: patients, mutate: mutatePatients } = useIndex(
    "patients_filter_and_sort",
    `practice_id=${practiceId}&filter_and_sort=${JSON.stringify(filter)}`,
  )
  const { data: practiceStudies } = useIndex("practice_studies")

  const optimisticallyUpdateScreen = (screenId, status) => {
    const updatedPatient = patients.find((patient) =>
      patient?.screens.some((screen) => screen.id === screenId),
    )
    const nonUpdatedPatients = patients
      .slice(0)
      .filter((patient) => patient.id !== updatedPatient.id)
    // const nonUpdatedPatients = patients.filter((patient) => !patient.screens.some((screen) => screen.id == screenId))
    const updatedScreens = updatedPatient.screens
      .slice(0)
      .map((screen) =>
        screen.id === screenId ? { ...screen, status: status } : screen,
      )
    // console.dir(updatedPatient.id)
    // console.dir(updatedScreens)
    const newPatients = [
      ...nonUpdatedPatients,
      { ...updatedPatient, screens: updatedScreens },
    ]

    pivotPatientScreensByStatus(newPatients)
    useUpdate(
      "screens",
      screenId,
      { status: status },
      getAccessTokenSilently,
    ).then(() => {
      // why does this not work..
      // mutatePatients(newPatients, {revalidate: true}).then((patients) => console.dir(patients))
      mutatePatients()
    })
  }

  const [patientsByStatus, setPatientsByStatus] = useState([])
  const [localPatients, setLocalPatients] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [studyId, setStudyId] = useState(searchParams.get("studyId"))

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO: Refactor
  useEffect(() => {
    setStudyId(searchParams.get("studyId"))
    return () => {
      setStudyId("")
    }
  }, [practiceStudies])

  const handleChangeStudy = (event) => {
    setStudyId(event.target.value)
    setSearchParams(new URLSearchParams(`studyId=${event.target.value}`), {
      replace: true,
    })
  }

  // useEffect(() => {
  //   const newStudyId =
  //   console.dir(newStudyId)
  //   setStudyId(newStudyId)
  //   console.dir(studyId)
  //   pivotPatientScreensByStatus(patients)
  //   return () => {
  //     setStudyId('')
  //   }
  // }, [searchParams.get('studyId')])

  const filterPatientsByStatus = (status) => {
    const patientsToFilter =
      localPatients.length > 0 ? localPatients : patients?.slice(0) || []
    const filteredPatients = patientsToFilter.filter((patient) =>
      patient?.screens?.some(
        (screen) => screen.status === status && screen.study.id === studyId,
      ),
    )
    console.dir(status)
    console.dir(filteredPatients)
    return filteredPatients
  }

  const pivotPatientScreensByStatus = (patients) => {
    console.dir("pivoting")
    console.dir(studyId)
    console.dir(patients)
    const newPatientsByStatus = statuses.map((status) => ({
      status: status,
      patients: patients
        ?.slice(0)
        .filter((patient) =>
          patient?.screens?.some(
            (screen) => screen.status === status && screen.study.id === studyId,
          ),
        ),
    }))
    setPatientsByStatus(newPatientsByStatus)
    console.dir(patientsByStatus)
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: TODO: Refactor
  useEffect(() => {
    if (!patients || patients?.length === 0) {
      console.dir("zero")
      return
    }

    pivotPatientScreensByStatus(patients)

    return () => {
      setPatientsByStatus([])
    }
  }, [patients, studyId])

  return (
    <div className="overflow-auto">
      <div className="px-4.5 py-2 flex flex-col border-b border-gray-200 dark:border-gray-600 mb-4">
        <div className="flex items-center">
          <h1 className="p-4 text-xl font-bold">Kanban</h1>
          <div className="flex items-center">
            <div className="flex items-center px-4">
              {/* <span className="text-xs text-gray-500 dark:text-gray-300">Study</span> */}
              <div className="flex ml-2">
                <select
                  onChange={handleChangeStudy}
                  value={studyId}
                  className="text-xs focus:ring-transparent text-gray-800 h-6 bg-gray-100 dark:bg-gray-700 dark:text-gray-300 dark: rounded pr-4.5 bg-right pl-2 py-0 appearance-none focus:outline-none border-none"
                >
                  {!studyId && (
                    <option key={"select_a_study"} value="">
                      Select a Study
                    </option>
                  )}
                  {practiceStudies.map((practiceStudy) => (
                    <option
                      key={practiceStudy.study.id}
                      value={practiceStudy.study.id}
                    >
                      {practiceStudy.study.abbreviation}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex flex-row flex-1 pl-4">
          {patientsByStatus?.map((status, index) => (
            <Column
              key={status.status}
              status={status.status}
              patients={status.patients}
              studyId={studyId}
              statusIndex={index}
            />
          ))}
        </div>
      </DragDropContext>
    </div>
  )
}
