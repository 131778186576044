import { useState } from "react"

export default function DarkModeSelector() {
  const [currentMode, setCurrentMode] = useState(localStorage.theme || "auto")

  const handleClick = (mode) => {
    if (mode === "auto") localStorage.removeItem("theme")
    else localStorage.setItem("theme", mode)
    window.dispatchEvent(new Event("storage"))
    setCurrentMode(localStorage.theme || "auto")
    handleThemeChange()
  }

  const handleThemeChange = () => {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark")
      document.documentElement.classList.add("bg-gray-800")
    } else {
      document.documentElement.classList.remove("dark")
      document.documentElement.classList.remove("bg-gray-800")
    }
  }

  return (
    <span className="isolate inline-flex rounded-md px-2 my-2">
      <button
        type="button"
        className={`${
          currentMode === "dark" ? "dark:bg-gray-500 bg-gray-200" : ""
        } relative inline-flex items-center rounded-l-md border border-gray-300 px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-700 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-0 focus:ring-indigo-500`}
        onClick={() => handleClick("dark")}
      >
        Dark 🌚
      </button>
      <button
        type="button"
        className={`${
          currentMode === "light" ? "dark:bg-gray-500 bg-gray-200" : ""
        } relative -ml-px inline-flex items-center border border-gray-300 px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-700 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-0 focus:ring-indigo-500`}
        onClick={() => handleClick("light")}
      >
        Light 💡
      </button>
      <button
        type="button"
        className={`${
          currentMode === "auto" ? "dark:bg-gray-500 bg-gray-200" : ""
        } relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-700 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-0 focus:ring-indigo-500`}
        title="Follows setting set in System Preferences"
        onClick={() => handleClick("auto")}
      >
        Auto ⚙️
      </button>
    </span>
  )
}
