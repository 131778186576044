import { useContext, useState } from "react"
import Pluralize from "react-pluralize"
import { useParams } from "react-router-dom"
import { AuthorizationContext } from "../shared/authorization-provider"
import { useIndexQuery } from "../shared/use-rest-query"
import { TimelineEvent } from "./patient-timeline/timeline-event"

export function PatientTimeline() {
  const { patientId } = useParams()
  const { practiceId } = useContext(AuthorizationContext)
  const {
    data: { data: timelineEvents },
    isLoading,
    isError,
  } = useIndexQuery("patient_timeline_events", `patient_id=${patientId}`)

  const [filterModel, setFilterModel] = useState("all")

  const toolbarButtonStyle =
    "inline-flex items-center p-4 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-topo-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800"
  const toolbarButtonGroupStyle =
    "inline-flex items-center px-4 py-2 shadow-sm text-sm leading-4 font-medium [&.active]:bg-topo-navy [&.active]:text-white [&.active]:border-transparent hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-offset-2 focus:ring-blue-800"

  return (
    <>
      <div className="p-4 rounded-b-lg shadow-md bg-white dark:bg-transparent border-l border-r border-b border-gray-200">
        <div className="flex flex-grow">
          <div className="flex-grow">
            <div className="filter-bar flex items-center justify-between">
              <div className="button-group">
                <button
                  type="button"
                  className={`${toolbarButtonGroupStyle} rounded-l-md border ${
                    filterModel === "all"
                      ? "bg-topo-navy hover:bg-topo-navy text-gray-100"
                      : ""
                  }`}
                  onClick={() => setFilterModel("all")}
                >
                  <div
                    className={`rounded-lg  ${
                      filterModel === "all"
                        ? "text-gray-800 bg-white"
                        : "bg-gray-200"
                    } py-1 px-1.5 mr-2`}
                  >
                    {timelineEvents?.total_event_count}
                  </div>
                  All Changes
                </button>
                <button
                  type="button"
                  className={`${toolbarButtonGroupStyle} border-l-0 border ${
                    filterModel === "Contact"
                      ? "bg-topo-navy hover:bg-topo-navy text-gray-100"
                      : ""
                  }`}
                  onClick={() => setFilterModel("Contact")}
                >
                  <div
                    className={`rounded-lg  ${
                      filterModel === "Contact"
                        ? "text-gray-800 bg-white"
                        : "bg-gray-200"
                    } dark:text-gray-700 py-1 px-1.5 mr-2`}
                  >
                    {
                      timelineEvents?.events?.filter(
                        (event) => event.event_reference_model === "Contact",
                      ).length
                    }
                  </div>
                  <Pluralize
                    singular="Contact"
                    count={
                      timelineEvents?.events?.filter(
                        (event) =>
                          event.event_reference_model === "PatientComment",
                      ).length
                    }
                    showCount={false}
                  />
                </button>
                <button
                  type="button"
                  className={`${toolbarButtonGroupStyle} rounded-r-md border border-l-0 ${
                    filterModel === "PatientComment"
                      ? "bg-topo-navy hover:bg-topo-navy text-gray-100"
                      : ""
                  }`}
                  onClick={() => setFilterModel("PatientComment")}
                >
                  <div
                    className={`rounded-lg  ${
                      filterModel === "PatientComment"
                        ? "text-gray-800 bg-white"
                        : "bg-gray-200"
                    } dark:text-gray-700 py-1 px-1.5 mr-2`}
                  >
                    {
                      timelineEvents?.events?.filter(
                        (event) =>
                          event.event_reference_model === "PatientComment",
                      ).length
                    }
                  </div>
                  <Pluralize
                    singular="Comment"
                    count={
                      timelineEvents?.events?.filter(
                        (event) =>
                          event.event_reference_model === "PatientComment",
                      ).length
                    }
                    showCount={false}
                  />
                </button>
              </div>
              <div class="items-center">
                Viewing{" "}
                {
                  timelineEvents?.events?.filter(
                    (event) =>
                      event.event_reference_model === filterModel ||
                      filterModel === "all",
                  ).length
                }{" "}
                of{" "}
                <Pluralize
                  singular="change"
                  count={
                    timelineEvents?.events?.filter(
                      (event) =>
                        event.event_reference_model === filterModel ||
                        filterModel === "all",
                    ).length
                  }
                />
              </div>
            </div>
            <div className="grouping font-bold my-4 hidden">Today</div>
            <div class="mt-6 space-y-6 pl-1">
              {timelineEvents?.events
                ?.filter(
                  (event) =>
                    event.event_reference_model === filterModel ||
                    filterModel === "all",
                )
                .reduce((acc, event) => {
                  if (event?.event_description) {
                    acc.push({
                      event: event,
                    })
                  }
                  return acc
                }, [])
                .map((eventObj) => (
                  <TimelineEvent key={eventObj.id} event={eventObj.event} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
