import {
  DataGridPro,
  type GridCellParams,
  type GridColDef,
  type GridRowsProp,
  GridToolbar,
  type MuiEvent,
} from "@mui/x-data-grid-pro"
import { Tooltip } from "flowbite-react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ActionHeader } from "../../../components/action-header"
import { Button } from "../../../components/button"
import { ButtonLeadingIcon } from "../../../components/button-leading-icon"
import { HeroIcon } from "../../../components/hero-icon"
import { friendlyDateTime } from "../../../shared/friendly-dates"
import { useIndex } from "../../../shared/use-rest"
import { UserEditModal } from "./edit-modal"
import { UserNewModal } from "./new-modal"
import { PracticeTeamXrefs } from "./practice_team_xrefs"
import { PracticeUsers, PracticeUsersIndex } from "./practice_users"
import { PracticeUsersDisplay } from "./practice_users/display"
import { TeamXrefs } from "./team_xrefs"

export function Xrefs() {
  const { data, isLoading, isError, mutate } = useIndex("admin/users")
  const [newModalOpen, setNewModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [userId, setUserId] = useState("")
  const navigate = useNavigate()

  const handleShowUser = (userId) => navigate(`/admin/users/${userId}`)

  const rows: GridRowsProp = data
  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", width: 150 },
    { field: "email", headerName: "Email", width: 180 },
    { field: "role", headerName: "Role", width: 120 },
    {
      field: "task_user_xref_id",
      headerName: "Monday ID",
      width: 150,
    },
    {
      field: "phone_extension_xref_id",
      headerName: "Extension",
      width: 120,
    },
    {
      field: "created_at",
      headerName: "Created",
      width: 180,
      renderCell: (params) => friendlyDateTime(params.row.created_at),
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 180,
      renderCell: (params) => friendlyDateTime(params.row.updated_at),
    },
    {
      field: "deleted_at",
      headerName: "Deleted",
      width: 180,
      renderCell: (params) => friendlyDateTime(params.row.deleted_at),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      display: "flex",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => renderCellActions(params, handleShowUser),
    },
  ]

  const renderCellActions = (props: GridCellParams, handleShowUser) => {
    return (
      <button
        type="button"
        onClick={() => handleShowUser(props.row.id)}
        className="bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 text-white text-xs font-medium mr-6 px-2.5 py-1.5 rounded-md flex items-center justify-between"
      >
        Edit
        <HeroIcon icon="ArrowCircleRightIcon" className="ml-1 w-5 h-5 flex" />
      </button>
    )
  }

  return (
    <div className="pl-4 pr-4">
      <TeamXrefs />
      <PracticeTeamXrefs />
    </div>
  )
}
