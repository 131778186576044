import React, { useState } from "react"

const FileInput = ({
  type,
  accept,
  register,
  name,
  id,
  className,
  validateFileType,
  maxFileSize = 5 * 1024 * 1024, // 5MB default
  ...rest
}) => {
  const [error, setError] = useState(null)

  const handleFileChange = (event) => {
    const files = event.target.files
    let totalSize = 0

    // Calculate the total size of all selected files
    for (let i = 0; i < files.length; i++) {
      totalSize += files[i].size
    }

    // Check if the total size exceeds the limit
    if (totalSize > maxFileSize) {
      setError(
        `Total file size exceeds the limit of ${
          maxFileSize / (1024 * 1024)
        } MB.`,
      )
      event.target.value = null // Clear the selected files
    } else {
      setError(null) // No error, proceed with file upload
    }
  }

  return (
    <div>
      <input
        type={type}
        accept={accept}
        {...register(name, {
          validate: {
            fileType: validateFileType,
          },
        })}
        name={name}
        id={id}
        className={className}
        onChange={handleFileChange}
        {...rest}
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  )
}

export default FileInput
