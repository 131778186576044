import { useAuth0 } from "@auth0/auth0-react"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { HeroIcon } from "../components/hero-icon"
import { event } from "../shared/event"
import { friendlyDate } from "../shared/friendly-dates"
import { useDelete, useIndex } from "../shared/use-rest"
import { usePatientContext } from "./patient"

export function PatientMedications() {
  const { patientId } = useParams()
  const { currentStudyScreenId, setNewMedicationModalOpen } = usePatientContext()
  const { data, isLoading, isError, mutate } = useIndex('patient_medications', `patient_id=${patientId}`)
  const { getAccessTokenSilently } = useAuth0()

  const deleteMedication = async (id) => {
    await useDelete('patient_medications', id, getAccessTokenSilently)
    mutate()
  }

  useEffect(() => {
    event({eventName: 'PatientMedicationsViewStart', meta: {patient_id: patientId, screen_id: currentStudyScreenId }, getAccessTokenSilently})
    return () => {
      event({eventName: 'PatientMedicationsViewEnd', meta: {patient_id: patientId, screen_id: currentStudyScreenId }, getAccessTokenSilently})
    }
  }, [])
    return (
      <>
        <div className="p-2 bg-white rounded-lg border border-gray-200 shadow-md">
          <div className="flex justify-between items-center">
            <h3 className="p-4 mb-4 text-xl font-bold text-gray-900 dark:text-white">
              Medications
            </h3>
            <button
              onClick={() => setNewMedicationModalOpen(true)}
              className="h-10 text-white bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 rounded font-semibol text-xs px-2.5 py-1.5 text-center inline-flex items-center"
            >
              <HeroIcon
                icon="PlusCircleIcon"
                className="fill-white w-4 h-4 mr-2 rotate-90"
              />
              Add Medication
            </button>
          </div>
          {/* Table */}
          <div className="flex flex-col">
            <div className="overflow-x-auto rounded-lg">
              <div className="inline-block min-w-full align-middle">
                <div className="overflow-hidden shadow sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                    <thead className="bg-gray-50 dark:bg-gray-700">
                      <tr>
                        <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">
                          Medication
                        </th>
                        <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">
                          Amount
                        </th>
                        <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">
                          Condition
                        </th>
                        <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">
                          Started At
                        </th>
                        <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">
                          Ended At
                        </th>
                        {/* <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">
                          Recorded At
                        </th> */}
                        <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">
                          Created By
                        </th>
                        <th scope="col" className="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-gray-400">
                          
                        </th>

                      </tr>
                    </thead>
                    <tbody className="bg-white dark:bg-gray-800">
                      { data.map((medication, index) => (
                        <tr key={index} className={index % 2 != 0 ? 'bg-gray-50' : ''}>
                          <td className="p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                            <span
                              className="text-sm font-medium ">
                              { medication?.medication?.name }
                            </span>
                          </td>
                          <td className="p-4 text-sm whitespace-nowrap dark:text-gray-400">
                            { medication.amount } { medication.unit }
                          </td>

                          <td className="p-4 text-sm whitespace-nowrap dark:text-gray-400">
                            { medication?.condition?.name }
                          </td>
                          <td className="p-4 text-sm whitespace-nowrap dark:text-gray-400">
                            { friendlyDate(medication.started_at) }
                          </td>
                          <td className="p-4 text-sm whitespace-nowrap dark:text-gray-400">
                            { friendlyDate(medication.ended_at) }
                          </td>
                          {/* <td className="p-4 text-sm whitespace-nowrap dark:text-gray-400">
                            { friendlyDate(medication.recorded_at) }
                          </td> */}
                          <td className="p-4 text-sm whitespace-nowrap dark:text-gray-400">
                            { medication?.created_by?.name }
                          </td>
                          <td>
                            <div onClick={() => deleteMedication(medication.id)}><HeroIcon icon="TrashIcon" className='h-5 hover:text-red-500 hover:cursor-pointer' outline></HeroIcon></div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
      </div>
      </>
    )
}
