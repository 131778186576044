import { useAuth0 } from "@auth0/auth0-react"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { useNavigate, useParams } from "react-router-dom"
import { AuthorizationContext } from "../shared/authorization-provider"
import { useCreate, useIndex } from "../shared/use-rest"
import { HeroIcon } from "./hero-icon"

interface Props {
  // TODO types, moving quickly
  setOpen: Dispatch<SetStateAction<boolean>>
  setCurrentStudyScreenName: Dispatch<SetStateAction<string>>
  setCurrentStudyScreenId: Dispatch<SetStateAction<boolean>>
}

export function IneligibleModal(props: Props) {
  const { patientId } = useParams()
  const { practiceId } = useContext(AuthorizationContext)
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const { data: screens } = useIndex("screens", `patient_id=${patientId}`)
  const { data: practice_studies } = useIndex(
    "practice_studies",
    `practice_id=${practiceId}`,
  )
  const [unscreenedStudies, setUnscreenedStudies] = useState([])
  const { setCurrentStudyScreenName, setCurrentStudyScreenId } = props

  useEffect(() => {
    if (screens?.length === 0) {
      setUnscreenedStudies(practice_studies)
    } else {
      setUnscreenedStudies(
        practice_studies.filter(
          (practice_study) =>
            !screens?.some(
              (screen) => screen.study_id === practice_study.study_id,
            ),
        ),
      )
    }
  }, [practice_studies, screens])

  const handleScreenNavigate = (screen_id, screen_abbreviation = "") => {
    setCurrentStudyScreenName(screen_abbreviation)
    setCurrentStudyScreenId(screen_id)
    navigate(`/patients/${patientId}/screens/${screen_id}`)
    handleClose()
  }

  const handleScreenStart = async (study_id) => {
    if (!study_id) return
    const data = {
      study_id: study_id,
      practice_id: practiceId,
      patient_id: patientId,
      status: "chart_review",
    }
    const response = await useCreate("screens", data, getAccessTokenSilently)
    const id = response?.data?.data?.id
    if (id !== undefined) handleScreenNavigate(id)
    handleClose()
  }

  const handleClose = () => {
    props.setOpen(false)
  }

  return (
    <>
      <div
        className="fixed flex left-0 ml-10 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full"
        id="add-user-modal"
      >
        <div className="relative ml-10 w-full h-full max-w-2xl px-4 md:h-auto">
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-800 dark:text-gray-200">
            {/* <!-- Modal header --> */}
            <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
              <h3 className="text-xl font-semibold">
                This patient is not eligible
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={() => handleClose()}
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>X Button</title>
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="ml-6 mt-2 text-xs">
              Select another study below, or select resume screening to add more
              data.
            </div>
            {/* <!-- Modal body --> */}
            <div className="p-6 space-y-6">
              {screens?.map((screen, index) => (
                <div className="flex justify-between" key={screen?.study?.id}>
                  <div className="font-medium">
                    {screen.study?.abbreviation} - {screen.study?.name}
                  </div>
                  <button
                    type="button"
                    onClick={() =>
                      handleScreenNavigate(
                        screen.id,
                        screen.study?.abbreviation,
                      )
                    }
                    onKeyUp={() =>
                      handleScreenNavigate(
                        screen.id,
                        screen.study?.abbreviation,
                      )
                    }
                    className="bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 text-white text-xs font-medium mr-6 px-2.5 py-1.5 rounded-md flex items-center justify-between"
                  >
                    Continue Screen
                    <HeroIcon
                      icon="ArrowCircleRightIcon"
                      className="ml-1 w-5 h-5 flex"
                    />
                  </button>
                </div>
              ))}
              {unscreenedStudies?.map((practice_study, index) => (
                <div
                  className="flex justify-between"
                  key={practice_study?.study?.id}
                >
                  <div className="font-medium">
                    {practice_study.study?.abbreviation} -{" "}
                    {practice_study.study?.name}
                  </div>
                  <button
                    type="button"
                    onClick={() => handleScreenStart(practice_study?.study?.id)}
                    onKeyUp={() => handleScreenStart(practice_study?.study?.id)}
                    className="bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 text-white text-xs font-medium mr-6 px-2.5 py-1.5 rounded-md flex items-center justify-between"
                  >
                    Start Screen
                    <HeroIcon
                      icon="ArrowCircleRightIcon"
                      className="ml-1 w-5 h-5 flex"
                    />
                  </button>
                </div>
              ))}
            </div>

            {/* <!-- Modal footer --> */}
            <div className="items-center p-4 border-t border-gray-200 rounded-b">
              <button
                type="button"
                className="text-white bg-topo-blue hover:bg-blue-800 focus:ring-4 focus:ring-topo-green-600 font-medium rounded-lg text-sm mr-5 px-5 py-2.5 text-center"
                onClick={() => handleClose()}
                onKeyUp={() => handleClose()}
              >
                Resume Screening
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        id="modal-backdrop"
        className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
      />
    </>
  )
}
