import { useAuth0 } from "@auth0/auth0-react"
import { TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Link, useParams } from "react-router-dom"
import { ActionHeader } from "../../../components/action-header"
import { ButtonLeadingIcon } from "../../../components/button-leading-icon"
import { HeroIcon } from "../../../components/hero-icon"
import { useIndex, useShow, useUpdate } from "../../../shared/use-rest"

import { QueryClient, useQueryClient } from "@tanstack/react-query"
import { Badge, Card } from "flowbite-react"
import SplashScreen from "../../../components/splash-screen"
import { friendlyDateTime } from "../../../shared/friendly-dates"
import { useIndexQuery, useShowQuery } from "../../../shared/use-rest-query"
import { UserEditModal } from "./edit-modal"
import { PracticeUsersDisplay } from "./practice_users/display"

export function ShowUser() {
  const [editModalOpen, setEditModalOpen] = useState(false)
  const { userId } = useParams()
  const {
    data: { data: user },
    isFetching,
    isError,
  } = useShowQuery("admin/users", userId)

  const queryClient = useQueryClient()
  const mutateUsers = () => {
    queryClient.invalidateQueries({
      queryKey: ["admin/users", userId],
    })
  }

  return (
    <>
      {isFetching || isError ? (
        <SplashScreen isLoading={isFetching} isError={isError} />
      ) : (
        <div>
          <div className="flex justify-between items-center pr-4">
            <h2 className="text-2xl sm:text-3xl  p-2 mb-2 font-bold text-gray-900 dark:text-white">
              {user.name}
            </h2>
            <div
              onClick={() => setEditModalOpen(true)}
              onKeyUp={() => setEditModalOpen(true)}
            >
              <ButtonLeadingIcon
                icon="PencilIcon"
                text="Edit Details"
                className="inline-flex items-center justify-center h-10 w-1/2 px-3 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300 sm:w-auto dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              />
            </div>
          </div>
          <div className="pr-4 mb-2">
            <Card>
              <h3 className="font-bold text-lg mb-4">Details</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className="flex items-center mb-1">
                    <Badge color="gray" size="sm">
                      Prefix
                    </Badge>
                    <div className="ml-2">
                      {user.prefix || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center mb-1">
                    <Badge color="gray" size="sm">
                      Name
                    </Badge>
                    <div className="ml-2">
                      {user.name || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center mb-1">
                    <Badge color="gray" size="sm">
                      Email
                    </Badge>
                    <div className="ml-2">
                      {user.email || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center mb-1">
                    <Badge color="gray" size="sm">
                      Role
                    </Badge>
                    <div className="ml-2">
                      {user.role || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center mb-1">
                    <Badge color="gray" size="sm">
                      Monday.com User Id
                    </Badge>
                    <div className="ml-2">
                      {user.task_user_xref_id || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-center mb-1">
                    <Badge color="gray" size="sm">
                      Ring Central Extension
                    </Badge>
                    <div className="ml-2">
                      {user.phone_extension_xref_id || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center mb-1">
                    <Badge color="gray" size="sm">
                      Created At
                    </Badge>
                    <div className="ml-2">
                      {friendlyDateTime(user.created_at) || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center mb-1">
                    <Badge color="gray" size="sm">
                      Updated At
                    </Badge>
                    <div className="ml-2">
                      {friendlyDateTime(user.updated_at) || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center mb-1">
                    <Badge color="gray" size="sm">
                      Deleted At
                    </Badge>
                    <div className="ml-2">
                      {friendlyDateTime(user.deleted_at) || (
                        <span className="text-gray-400 italic">
                          Not provided
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <PracticeUsersDisplay />
        </div>
      )}
      {editModalOpen && userId && (
        <UserEditModal
          id={userId}
          setOpen={setEditModalOpen}
          refreshData={mutateUsers}
        />
      )}
    </>
  )
}
