// const himalayas = new URL('../images/himalayas.jpg', import.meta.url).toString()
import { usePrefersDarkMode } from "../../shared/use-prefers-dark-mode"
import { useShow } from "../../shared/use-rest"

export function UserDashboard() {
  const prefersDarkMode = usePrefersDarkMode()
  let options = ""
  if (prefersDarkMode) {
    options = "?theme=transparent"
  }

  const { data } = useShow("metabase_iframes", `crc_dashboard${options}`)

  return (
    <div className="h-screen">
      {data && (
        <iframe src={data} className="inset-0 w-full h-full" title="eyeFrame" />
      )}
    </div>
  )
}
