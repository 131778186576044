import { useAuth0 } from "@auth0/auth0-react"
import type { Dispatch, SetStateAction } from "react"
import { useForm } from "react-hook-form"
import type { KeyedMutator } from "swr"
import { NewSlideover } from "../../../components/new-slideover"
import { useCreate } from "../../../shared/use-rest"

interface Props {
  open?: boolean
  setOpen?: Dispatch<SetStateAction<boolean>>
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  refreshData?: KeyedMutator<{ data: any[] }>
}
export function PracticeNewSlideover(props: Props) {
  const { getAccessTokenSilently } = useAuth0()
  const { reset } = useForm()
  const onSubmit = async (data) => {
    await useCreate("admin/practices", data, getAccessTokenSilently)
    props.setOpen(false)
    props.refreshData()
    reset()
  }

  const entity = {
    label: "Practice",
    key: "practice",
  }
  const fields = [
    { label: "Name", key: "name" },
    { label: "Address", key: "address" },
  ]

  return (
    <NewSlideover
      entity={entity}
      fields={fields}
      onSubmit={onSubmit}
      open={props.open}
      setOpen={props.setOpen}
    />
  )
}
