import { useAuth0 } from "@auth0/auth0-react"
import TextField from "@mui/material/TextField"
import { Tooltip } from "flowbite-react"
import { type Dispatch, type SetStateAction, useContext, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import type { KeyedMutator } from "swr"
import validator from "validator"
import { AuthorizationContext } from "../shared/authorization-provider"
import { useCreate, useIndex } from "../shared/use-rest"
import { useKeyPress } from "../shared/useKeyPress"

interface Props {
  patientId: string
  setOpen: Dispatch<SetStateAction<boolean>>
  refreshData: KeyedMutator<{ data: unknown[] }>
}

export function NewVisitModal(props: Props) {
  const { practiceId } = useContext(AuthorizationContext)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { patient_id: props.patientId } })
  const { getAccessTokenSilently } = useAuth0()

  const [studyId, setStudyId] = useState()
  const {
    data: practiceStudies,
    isLoading: practiceStudiesLoading,
    isError: practiceStudiesError,
    mutate: practiceStudiesMutate,
  } = useIndex("practice_studies")
  const {
    data: practiceStudyVisits,
    isLoading: practiceStudyVisitsLoading,
    isError: practiceStudyVisitsError,
    mutate: practiceStudyVisitsMutate,
  } = useIndex("practice_study_visits", `study_id=${studyId}`)

  const closeModal = () => {
    props.setOpen(false)
  }

  const studyVisits = (studyId) => {
    setStudyId(studyId)
    practiceStudyVisitsMutate()
  }

  useKeyPress(["Escape"], closeModal)

  const validateURL = (inputURL) => {
    const urlPattern =
      /^https:\/\/app\.clinicalresearch\.io\/topography-health\/\w+\/study\/\d+\/subject\/\d+\/visit\/\d+$/
    if (validator.isURL(inputURL) && urlPattern.test(inputURL)) {
      return true
    }
    return "URL does not appear to be a valid visit url."
  }

  const onSubmit = (data) => {
    data.start_dt = new Date(data.start_dt).toISOString().slice(0, -1)
    data.practice_id = practiceId

    useCreate("on_demand_visits", data, getAccessTokenSilently).then(
      (response) => {
        props?.refreshData()
        closeModal()
      },
      (error) => {},
    )
  }

  return (
    <>
      <div
        className="fixed flex left-0 ml-10 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full"
        id="add-appointment-modal"
      >
        <div className="relative ml-10 w-full h-full max-w-2xl px-4 md:h-auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <!-- Modal content --> */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
              {/* <!-- Modal header --> */}
              <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
                <h3 className="text-xl font-semibold dark:text-white">
                  Add Today's Visit
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                  onClick={() => closeModal()}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Close modal</title>
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
                <p>
                  This form is intended for visits occurring today, that did not
                  get scheduled in CRIO and automatically created, prior to the
                  visit. Visits are automatically generated based on the
                  currently scheduled visits in CRIO. If the visit you wish to
                  add does not occur today, please allow the scheduled
                  automation to create the visit.
                </p>
              </div>
              {/* <!-- Modal body --> */}
              <div className="p-6 space-y-6">
                <div className="grid grid-cols-6 space-y-6">
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="study"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Study
                    </label>
                    <select
                      {...register("study")}
                      onChange={(e) => studyVisits(e.target.value)}
                      name="study"
                      id="study"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      // placeholder="NN"
                      required
                    >
                      <option />
                      {practiceStudies.map((practiceStudy) => (
                        <option
                          key={practiceStudy.id}
                          value={practiceStudy?.study?.id}
                        >
                          {practiceStudy?.study?.abbreviation}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="practice_study_visit_id"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Study Visit
                    </label>
                    <select
                      {...register("practice_study_visit_id")}
                      name="practice_study_visit_id"
                      id="practice_study_visit_id"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      // placeholder="NN"
                      required
                    >
                      <option />
                      {practiceStudyVisits.map((practiceStudyVisit) => (
                        <option
                          key={practiceStudyVisit.id}
                          value={practiceStudyVisit?.id}
                        >
                          {practiceStudyVisit?.study_visit?.visit_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="subject_study_visit_url"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      <Tooltip
                        content={`The url for the patient's visit, e.g. https://app.clinicalresearch.io/topography-health/practice/study/11111111/subject/22222222/visit/33333333`}
                      >
                        Subject Study Visit URL
                      </Tooltip>
                    </label>
                    {errors?.subject_study_visit_url?.message ?? (
                      <p>{errors?.subject_study_visit_url?.messagee}</p>
                    )}
                    <input
                      {...register("subject_study_visit_url", {
                        validate: validateURL,
                      })}
                      type="text"
                      name="subject_study_visit_url"
                      id="subject_study_visit_url"
                      className="shadow-sm bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-2 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="https://app.clinicalresearch.io/topography-health/practice/study/11111111/subject/22222222/visit/33333333`"
                      required
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="xref_patient_id"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Patient CRIO ID
                    </label>
                    {/* TODO: make the border not move the other elements around */}
                    <input
                      {...register("xref_patient_id")}
                      type="text"
                      name="xref_patient_id"
                      id="xref_patient_id"
                      className="shadow-sm bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-2 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      // placeholder="example@company.com"
                      required
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="start_dt"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Visit Start Time
                    </label>
                    <Controller
                      name="start_dt"
                      control={control}
                      defaultValue={""}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="datetime-local"
                          sx={{
                            width: 250,
                            boxShadow: "none",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <input
                      {...register("patient_id")}
                      type="hidden"
                      name="patient_id"
                      id="patient_id"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      // placeholder="example@company.com"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* <!-- Modal footer --> */}
              <div className="items-center p-4 border-t border-gray-200 dark:border-gray-700 rounded-b">
                <button
                  className="text-white bg-topo-green hover:bg-topo-green-700 focus:ring-4 focus:ring-topo-green-600 font-medium rounded-lg text-sm mr-5 px-5 py-2.5 text-center"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        id="modal-backdrop"
        className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
      />
    </>
  )
}
