const logoWhiteUrl = new URL("../images/logo-full-white.svg", import.meta.url)
const logoColorUrl = new URL("../images/logo-full-color.svg", import.meta.url)

export function LogoIcon({ className = "" }) {
  return (
    <>
      <img
        className={`${className} block dark:hidden p-0.5 h-12`}
        src={logoColorUrl}
        alt="logo"
      />
      <img
        className={`${className} dark:block hidden p-0.5 h-12`}
        src={logoWhiteUrl}
        alt="logo"
      />
    </>
  )
}
