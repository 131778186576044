import { TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import dayjs from "dayjs"
import { TextInput } from "flowbite-react"
import React from "react"
import { Controller } from "react-hook-form"

const CustomDatePicker = ({
  control,
  name,
  defaultValue = null,
  minDate = null,
  maxDate = null,
  getValue,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <>
          <DatePicker
            value={value}
            onChange={(date) => onChange(date)}
            minDate={minDate}
            maxDate={maxDate}
            slotProps={{
              textField: {
                sx: {
                  "& .MuiOutlinedInput-root": {
                    borderRadius: ".5rem",
                    backgroundColor: "#F9FAFB",
                    height: "42px",
                    "& .MuiOutlinedInput-input": {
                      padding: "10px",
                    },
                    "& .MuiInputAdornment-root": {
                      marginLeft: "0",
                    },
                  },
                  ".dark & .MuiOutlinedInput-root": {
                    borderRadius: ".5rem",
                    backgroundColor: "rgb(55 65 81)",
                    borderColor: "rgb(75 85 99)",
                    height: "42px",
                    ".dark & .MuiOutlinedInput-input": {
                      padding: "10px",
                      borderColor: "rgb(75 85 99)",
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgb(209, 213, 219)",
                  },
                  ".dark & .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgb(75 85 99)",
                  },
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "rgb(156, 163, 175)",
                    },
                },
              },
            }}
          />
        </>
      )}
    />
  )
}

export default CustomDatePicker
