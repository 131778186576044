import { titleCase } from "change-case-all"
import { Tooltip } from "flowbite-react"
import { useContext, useState } from "react"
import { Avatar } from "../../components/avatar"
import { HeroIcon } from "../../components/hero-icon"
import { AuthorizationContext } from "../../shared/authorization-provider"
import { dateTimeISO, friendlyRelative } from "../../shared/friendly-dates"
import { snakeToTitle } from "../../shared/text-utils"
import { useShowQuery } from "../../shared/use-rest-query"

export function TimelineEvent(props) {
  const { event } = props
  const [expanded, setExpanded] = useState(false)
  const [timelineEventId, setTimelineEventId] = useState("")
  const { practiceId } = useContext(AuthorizationContext)

  const {
    data: { data: timelineEventDetail },
    isLoading,
    isError,
  } = useShowQuery(
    "patient_timeline_events",
    timelineEventId,
    { params: `practice_id=${practiceId}` },
    { enabled: timelineEventId !== "" },
  )

  const handleExpandClick = () => {
    setTimelineEventId(event.id)
    setExpanded(true)
  }
  const handleCollapseClick = () => {
    setExpanded(false)
  }
  return (
    <div className="flex flex-row justify-between items-start border-b pb-4 flex-grow">
      <div className="flex items-center basis-1/5">
        <Tooltip content={event?.event_created_by_user_name || "Unknown User"}>
          {/* there's some jank here */}
          <Avatar name={event?.event_created_by_user_name} />
        </Tooltip>
        <div className="ml-2">
          <div className="font-bold">{event?.created_by_user_id}</div>
          <div className="font-semibold">
            {/* some jank here too */}
            {event?.event_created_by_user_name || "Unknown User"}
          </div>
          <Tooltip content={dateTimeISO(event?.event_created_at)}>
            <div className="text-xs">
              {titleCase(friendlyRelative(event?.event_created_at))}
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="font-normal text-sm flex-1 pr-4 pt-1">
        <div className="">{event?.event_description}</div>
        <div className="font-normal mt-2">
          {expanded &&
            timelineEventDetail &&
            Object.entries(timelineEventDetail).map(([key, value]) => (
              <div key={key}>
                {snakeToTitle(key)}: {value}
              </div>
            ))}
        </div>
      </div>
      {/* hiding for now until we decide on a detail view */}
      <div className="flex hidden">
        {!expanded && (
          <button
            type="button"
            className="hover:cursor-pointer rounded-xl pt-[0.5px] bg-gray-200 dark:text-gray-700"
            onClick={handleExpandClick}
            onKeyUp={handleExpandClick}
          >
            <HeroIcon icon="ChevronDownIcon" />
          </button>
        )}
        {expanded && (
          <button
            type="button"
            className="hover:cursor-pointer rounded-xl pt-[0.5px] bg-gray-200 dark:text-gray-700"
            onClick={handleCollapseClick}
            onKeyUp={handleCollapseClick}
          >
            <HeroIcon icon="ChevronUpIcon" />
          </button>
        )}
      </div>
    </div>
  )
}
