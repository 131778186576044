// TODO: Edit Visit
import { useAuth0 } from "@auth0/auth0-react"
import TextField from "@mui/material/TextField"
import { Tooltip } from "flowbite-react"
import {
  type Dispatch,
  type SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { Controller, useForm } from "react-hook-form"
import type { KeyedMutator } from "swr"
import validator from "validator"
import { AuthorizationContext } from "../shared/authorization-provider"
import { useCreate, useIndex, useShow, useUpdate } from "../shared/use-rest"
import { useKeyPress } from "../shared/useKeyPress"

interface Props {
  visitId: string
  setOpen: Dispatch<SetStateAction<boolean>>
  refreshData: KeyedMutator<{ data: unknown[] }>
}

export function EditVisitModal(props: Props) {
  const {
    data: visit,
    isLoading: visitIsLoading,
    isError: visitIsError,
    mutate: mutateVisit,
  } = useShow("visits", props.visitId)

  const { practiceId } = useContext(AuthorizationContext)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { visit_id: props.visitId, start_dt: visit?.start_dt ?? "" },
  })
  const { getAccessTokenSilently } = useAuth0()

  const closeModal = () => {
    props?.refreshData()
    props.setOpen(false)
  }

  useKeyPress(["Escape"], closeModal)

  const onSubmit = (data) => {
    data.start_dt = new Date(data.start_dt).toISOString().slice(0, -1)
    data.practice_id = practiceId

    useUpdate(
      "on_demand_visits",
      data.visit_id,
      {
        start_dt: data.start_dt,
      },
      getAccessTokenSilently,
    ).then(
      (response) => {
        closeModal()
      },
      (error) => {},
    )
  }

  return (
    <>
      <div
        className="fixed flex left-0 ml-10 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full"
        id="add-appointment-modal"
      >
        <div className="relative ml-10 w-full h-full max-w-2xl px-4 md:h-auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <!-- Modal content --> */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
              {/* <!-- Modal header --> */}
              <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
                <h3 className="text-xl font-semibold dark:text-white">
                  Reschedule Today's Visit
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                  onClick={() => closeModal()}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Close modal</title>
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
                <p>
                  This form is intended for visits occurring today, that did not
                  get rescheduled in CRIO and automatically updated, prior to
                  the visit. Visits are automatically generated and rescheduled
                  based on the currently scheduled visits in CRIO. If the visit
                  you wish to reschedule does not occur today, please allow the
                  scheduled automation to update the visit.
                </p>
              </div>
              {/* <!-- Modal body --> */}
              <div className="p-6 space-y-6">
                <div className="grid grid-cols-6 space-y-6">
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="study"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Study
                    </label>
                    <input
                      type="text"
                      name="study"
                      id="study"
                      className="shadow-sm bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-2 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={visit?.study?.abbreviation ?? ""}
                      disabled
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="practice_study_visit_id"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Study Visit
                    </label>
                    <input
                      type="text"
                      name="practice_study_visit_id"
                      id="practice_study_visit_id"
                      className="shadow-sm bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-2 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={
                        visit?.practice_study_visit?.study_visit?.visit_name ??
                        ""
                      }
                      disabled
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="subject_study_visit_url"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      <Tooltip
                        content={`The url for the patient's visit, e.g. https://app.clinicalresearch.io/topography-health/practice/study/11111111/subject/22222222/visit/33333333`}
                      >
                        Subject Study Visit URL
                      </Tooltip>
                    </label>
                    <input
                      type="text"
                      name="subject_study_visit_url"
                      id="subject_study_visit_url"
                      className="shadow-sm bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-2 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="https://app.clinicalresearch.io/topography-health/practice/study/11111111/subject/22222222/visit/33333333`"
                      value={visit?.xref_subject_study_url ?? ""}
                      disabled
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="xref_patient_id"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Patient CRIO ID
                    </label>
                    {/* TODO: make the border not move the other elements around */}
                    <input
                      type="text"
                      name="xref_patient_id"
                      id="xref_patient_id"
                      className="shadow-sm bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-2 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={visit?.xref_patient_id ?? ""}
                      disabled
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      htmlFor="start_dt"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Visit Start Time
                    </label>
                    <Controller
                      name="start_dt"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="datetime-local"
                          sx={{
                            width: 250,
                            boxShadow: "none",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <input
                      {...register("visit_id")}
                      type="hidden"
                      name="visit_id"
                      id="visit_id"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      // placeholder="example@company.com"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* <!-- Modal footer --> */}
              <div className="items-center p-4 border-t border-gray-200 dark:border-gray-700 rounded-b">
                <button
                  className="text-white bg-topo-green hover:bg-topo-green-700 focus:ring-4 focus:ring-topo-green-600 font-medium rounded-lg text-sm mr-5 px-5 py-2.5 text-center"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        id="modal-backdrop"
        className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
      />
    </>
  )
}
