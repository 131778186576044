import { useAuth0 } from "@auth0/auth0-react"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { mutate } from "swr"
import { AuthorizationContext } from "../../shared/authorization-provider"
import { snakeToTitle } from "../../shared/text-utils"
import { indexUrl, useCreate, useIndex } from "../../shared/use-rest"
import { useKeyPress } from "../../shared/useKeyPress"

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>
}

export function AppointmentFinderModal(props: Props) {
  const { getAccessTokenSilently } = useAuth0()
  const { practiceId } = useContext(AuthorizationContext)
  const {
    data: appointmentsIndex,
    isLoading: isLoadingAppointmentsIndex,
    mutate: mutateAppointmentsIndex,
  } = useIndex("appointment_imports", `practice_id=${practiceId}`)
  const [createdAppointments, setCreatedAppointments] = useState(null)
  const [creatingAppointments, setCreatingAppointments] = useState(false)

  const handleClose = () => {
    props.setOpen(false)
    mutate(indexUrl("uploads"))
  }

  useKeyPress(["Escape"], handleClose)

  const handleAppointmentFinderClick = async () => {
    setCreatingAppointments(true)
    setCreatedAppointments(null)
    const response = await useCreate(
      "appointment_imports",
      { practice_id: practiceId },
      getAccessTokenSilently,
    )
    setCreatingAppointments(false)
    setCreatedAppointments(response?.data)
    mutateAppointmentsIndex()
  }

  return (
    <>
      <div
        className="fixed flex left-0 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full"
        id="add-user-modal"
      >
        <div className="relative w-full h-full max-w-2xl px-4 md:h-auto">
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
            {/* <!-- Modal header --> */}
            <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
              <h3 className="text-xl font-semibold dark:text-white">
                AppointmentFinder™️
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={() => handleClose()}
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>X Button</title>
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            {/* <!-- Modal body --> */}
            <div className="p-6 space-y-6">
              {!createdAppointments && (
                <>
                  <div className="font-semibold">
                    Is there a file ready? {isLoadingAppointmentsIndex && "..."}{" "}
                    {appointmentsIndex?.[0] ? "Yes ✅" : "No ❌"}
                  </div>
                  <button
                    className="flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:bg-red-200 disabled:text-red-700"
                    type="submit"
                    disabled={creatingAppointments || !appointmentsIndex?.[0]}
                    onClick={() => handleAppointmentFinderClick()}
                  >
                    {!creatingAppointments ? "Load Appointments" : "Loading..."}
                  </button>
                </>
              )}
              {creatingAppointments && <div className="text-2xl">🔜</div>}
              {createdAppointments && (
                <div className="font-semibold">Success 🎉</div>
              )}
              <div className="">
                {createdAppointments &&
                  Object.entries(createdAppointments.data).map(
                    ([key, value]) => (
                      <div>{`${snakeToTitle(key)}: ${value}`} </div>
                    ),
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="modal-backdrop"
        className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
      />
    </>
  )
}
