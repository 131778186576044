
export function FourOhOne() {

  return (
    <section className="bg-white dark:bg-gray-900 h-full">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center">
              <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-white">401</h1>
              <p className=" text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Where there's a will,</p>
              <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">there's a Waypoints.</p>
              <p className=" text-lg font-light text-gray-500 dark:text-gray-400">You are not authorized to access this instance of Waypoints.</p>
              <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Please ask your manager to add you.</p>
              <p className='text-white text-xs'>
                <a target="_blank" href="https://http.cat/401">
                  Until then, please enjoy this cat.
                </a>
              </p>
              <a href="/logout" className="inline-flex text-gray-500 dark dark:text-gray-200 ring-1 ring-black dark:ring-gray-200 bg-primary-600 hover:bg-primary-800 hover:dark:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">Try Again</a>
          </div>
      </div>
  </section>
  )
}
