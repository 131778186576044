import { useAuth0 } from "@auth0/auth0-react"
import { titleCase } from "change-case-all"
import { Dispatch, SetStateAction, useState } from "react"
import { useDropzone } from "react-dropzone"
import { mutate } from "swr"
import { HeroIcon } from "../../components/hero-icon"
import {
  AxiosBase,
  indexUrl,
  useCreate,
  useUpdate,
} from "../../shared/use-rest"
import { useKeyPress } from "../../shared/useKeyPress"

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>
}

export function UploadNewModal(props: Props) {
  // const { data: study_data } = useIndex('studies')
  const [uploadType, setUploadType] = useState("")
  const [dragActive, setDragActive] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [s3Url, setS3Url] = useState("")
  const [uploadId, setUploadId] = useState("")
  const { getAccessTokenSilently } = useAuth0()

  const createUpload = (type) => {
    useCreate(
      "uploads",
      {
        status: "new",
        upload_type: type,
      },
      getAccessTokenSilently,
    ).then((response) => {
      setS3Url(response.data.data.s3_presigned_url)
      setUploadId(response.data.data.id)
      mutate(indexUrl("/uploads"))
    })
  }

  const handleUploadType = (uploadType) => {
    setUploadType(uploadType)
    createUpload(uploadType)
  }

  const handleDrop = (files) => {
    const s3File = files[0]
    const s3Headers = {
      headers: {
        "Content-Type": s3File.type,
      },
    }
    AxiosBase.put(s3Url, s3File, s3Headers).then((response) => {
      setUploading(true)
      useUpdate(
        "uploads",
        uploadId,
        {
          status: "pending",
        },
        getAccessTokenSilently,
      )
    })
  }
  // const handleChange = function(e) {
  //   console.log('handleChange')
  //   e.preventDefault()
  //   setDragActive(false)
  //   if (e.target.files && e.target.files[0]) {
  //     // at least one file has been selected so do something
  //     handleFiles(e.target.files)
  //   }
  // }

  // const handleFiles = function(files) {
  //   setUploading(true)
  // }

  const handleClose = () => {
    props.setOpen(false)
    mutate(indexUrl("uploads"))
  }

  useKeyPress(["Escape"], handleClose)

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: handleDrop,
  })

  return (
    <>
      <div
        className="fixed flex left-0 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full"
        id="add-user-modal"
      >
        <div className="relative w-full h-full max-w-2xl px-4 md:h-auto">
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
            {/* <!-- Modal header --> */}
            <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
              <h3
                className="text-xl font-semibold dark:text-white"
                title={s3Url}
              >
                {!uploadType
                  ? "What type of data are you adding?"
                  : `Upload ${titleCase(uploadType)} Data`}
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={() => handleClose()}
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>X Button</title>
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            {/* <!-- Modal body --> */}
            <div className="p-6 space-y-6 dark:text-white">
              {/* {props.uploadId} */}
              {!uploadType && !uploading && (
                <>
                  <div className="text-sm">
                    NB: Dates must be in ISO format: (YYYY-MM-DD) or
                    (YYYY-MM-DDTHH:mm:SS)
                  </div>
                  <div className="text-sm">
                    NB: To associate data with an existing patient, you may
                    include: <br /> MRN or patient_id (waypoints patient
                    identifier){" "}
                  </div>
                  <div
                    onClick={() => handleUploadType("QualifiedPatient")}
                    onKeyUp={() => handleUploadType("QualifiedPatient")}
                    className="rounded shadow-md p-5 text-center items-center align-middle flex flex-col hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                  >
                    <HeroIcon
                      icon="UserGroupIcon"
                      outline
                      className="mb-1 h-10 w-10 text-blue"
                    />
                    Qualified Patients
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    {/* TODO map through an object to generate these? */}
                    <div
                      onClick={() => handleUploadType("Patient")}
                      onKeyUp={() => handleUploadType("Patient")}
                      className="rounded shadow-md p-5 text-center items-center align-middle flex flex-col hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                    >
                      <HeroIcon
                        icon="UserCircleIcon"
                        outline
                        className="mb-1 h-10 w-10 text-blue"
                      />
                      Demographic / Contact Info
                    </div>
                    <div
                      onClick={() => handleUploadType("PatientAppointment")}
                      onKeyUp={() => handleUploadType("PatientAppointment")}
                      className="rounded shadow-md p-5 text-center items-center align-middle flex flex-col hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                    >
                      <HeroIcon
                        icon="CalendarIcon"
                        outline
                        className="mb-1 h-10 w-10 text-blue"
                      />
                      Appointments
                    </div>
                    <div
                      onClick={() => handleUploadType("PatientCondition")}
                      onKeyUp={() => handleUploadType("PatientCondition")}
                      className="rounded shadow-md p-5 text-center items-center align-middle flex flex-col hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                    >
                      <HeroIcon
                        icon="BeakerIcon"
                        outline
                        className="mb-1 h-10 w-10 text-blue"
                      />
                      Conditions
                    </div>
                    <div
                      onClick={() => handleUploadType("PatientMedication")}
                      onKeyUp={() => handleUploadType("PatientMedication")}
                      className="rounded shadow-md p-5 text-center items-center align-middle flex flex-col hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                    >
                      <HeroIcon
                        icon="ClipboardIcon"
                        outline
                        className="mb-1 h-10 w-10 text-blue"
                      />
                      Medications
                    </div>
                    {/* <div onClick={() => handleUploadType('crosswalk') } className='rounded shadow-md p-5 text-center items-center align-middle flex flex-col hover:bg-gray-100 cursor-pointer'><HeroIcon icon='CogIcon' outline className='mb-1 h-10 w-10 text-blue' />Crosswalk</div> */}
                  </div>
                </>
              )}
              {uploadType && !uploading && (
                <div className="">
                  <div className="flex justify-between">
                    <button
                      type="button"
                      className="flex items-center mb-4 hover:text-blue-800"
                      onClick={() => setUploadType("")}
                      onKeyUp={() => setUploadType("")}
                    >
                      <HeroIcon icon="ArrowLeftIcon" className="h-4 w-4 mr-1" />
                      Back
                    </button>
                    <a
                      className="flex items-center mb-4 hover:text-blue-800"
                      href={`${process.env.API_URL}/upload_templates/${uploadType}`}
                    >
                      <HeroIcon
                        icon="DocumentDownloadIcon"
                        className="h-5 w-5"
                      />{" "}
                      Download CSV Template{" "}
                    </a>
                  </div>
                  <form
                    id="form-file-upload"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <label
                      className={`flex justify-center w-full transition ${
                        dragActive
                          ? "bg-gray-300 dark:bg-gray-700"
                          : "bg-white dark:bg-gray-800"
                      } border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none`}
                    >
                      {/* <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} className="hidden" /> */}
                      <span className="flex items-center space-x-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-gray-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <title>X Button</title>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          />
                        </svg>
                        <div {...getRootProps({ className: "dropzone p-10" })}>
                          <p>
                            Drop a file here to upload, or click to select a
                            file
                          </p>
                          <input {...getInputProps()} />
                        </div>
                        {/* <span className="font-medium text-gray-600">
                              Drop files here to upload, or
                              <span onClick={onButtonClick} className="text-blue-600 underline"> click here to browse</span>
                          </span> */}
                      </span>
                    </label>
                  </form>
                </div>
              )}
              {uploading && (
                <div className="">
                  We're uploading your file! Click the close button to check on
                  the progress.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        id="modal-backdrop"
        className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
      />
    </>
  )
}
