import { useAuth0 } from "@auth0/auth0-react"
import LinearProgress from "@mui/material/LinearProgress"
import {
  DataGridPro,
  type GridCallbackDetails,
  type GridCellParams,
  type GridColDef,
  type GridColumnVisibilityModel,
  type GridComparatorFn,
  type GridEventListener,
  type GridFilterModel,
  type GridRowHeightParams,
  type GridSortModel,
  type GridValueGetterParams,
  type MuiEvent,
  getGridStringOperators,
  gridFilterModelSelector,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from "@mui/x-data-grid-pro"
import dayjs from "dayjs"
import { Badge, Button, Progress, ToggleSwitch, Tooltip } from "flowbite-react"
import {
  type MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import resolveConfig from "tailwindcss/resolveConfig"
import type { v4 as uuidv4 } from "uuid"
import isUUID from "validator/lib/isUUID"
import tailwindConfig from "../../../tailwind.config.js"
import { BulkAssignModal } from "../../components/bulk-assign-modal.js"
import { HeroIcon } from "../../components/hero-icon.js"
import { PatientQuickAdd } from "../../components/patients/patient-quick-add.js"
import { StudyScreenStatusChip } from "../../components/patients/study-screen-status-chip.js"
import { isAdmin } from "../../components/protected-admin.js"
import { AuthorizationContext } from "../../shared/authorization-provider.js"
import { dobCalculator } from "../../shared/dob-calculator.js"
import {
  FeatureFlagContext,
  featureFlagEnabled,
} from "../../shared/feature-flag-provider"
import {
  dateTimeISO,
  dateTimeISOWithoutTimezone,
  friendlyDateTime,
} from "../../shared/friendly-dates.js"
import { safelyParseJSON } from "../../shared/json-utils.js"
import { snakeToTitle } from "../../shared/text-utils.js"
import {
  useCreate,
  useGet,
  useGetIndexInfinite,
  useShow,
  useUpdate,
} from "../../shared/use-rest.js"
import {
  ToggleSwitchTheme,
  TooltipTheme,
} from "../../styles/flowbite-themes.js"
import { ModalType } from "../patient.js"
import { BookmarkModal } from "../patients/bookmark-modal.js"
import { CalendarScreeningScreens } from "./calendar-screening-screens.js"
import { ToolbarCalendarScreening } from "./toolbar-calendar-screening.js"

const fullConfig = resolveConfig(tailwindConfig)
const DEFAULT_FILTER_AND_SORT = (practiceId) => {
  return {
    sorting: [
      {
        field: "appointment_start_dt",
        sort: "asc",
      },
    ],
    filtering: {
      filters: [
        {
          field: "dob",
          operator_value: "onOrBefore",
          value: dobCalculator(18),
        },
        {
          field: "dob",
          operator_value: "onOrAfter",
          value: dobCalculator(99),
        },
        {
          field: "appointment_start_dt",
          operator_value: "onOrAfter",
          value: dayjs().toDate().toISOString().slice(0, -1),
        },
        {
          field: "appointment_start_dt",
          operator_value: "onOrBefore",
          value: dayjs().add(2, "week").toDate().toISOString().slice(0, -1),
        },
        {
          field: "screen_count",
          operator_value: "gt",
          value: 0,
        },
      ],
      link_operator: "and",
    },
    search: "",
    practice_id: practiceId,
  }
}

export function Week() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { practiceId, changePractice, authorizedPractices } =
    useContext(AuthorizationContext)
  const limit =
    searchParams.get("limit") || process.env.PATIENT_DIRECTORY_LIMIT || 5000
  const pageSize = 50
  const [myWeekBookmarkId, setBookmarkId] = useState(
    searchParams.get("myWeekBookmarkId")
      ? searchParams.get("myWeekBookmarkId")
      : localStorage.getItem("myWeekBookmarkId"),
  )
  const [initialState, setInitialState] = useState(true)
  const [bookmarkPracticeId, setBookmarkPracticeId] = useState(undefined)
  const [filterAndSort, setFilterAndSort] = useState(undefined)
  const [search, setSearch] = useState("")
  const defaultWidth = 150
  const apiRef = useGridApiRef()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const rowNumber = queryParams.get("rowNumber") //Get the row number from query params
  const { getAccessTokenSilently } = useAuth0()
  const { currentUser, isLoading: isLoadingCurrentUser } =
    useContext(AuthorizationContext)
  const {
    data: patientsData,
    isValidating: isValidatingPatients,
    error: isErrorPatients,
    mutate: mutatePatients,
    size: pagePatients,
    setSize: setPagePatients,
  } = useGetIndexInfinite(
    myWeekBookmarkId &&
      practiceId &&
      `calendar_led_filter_and_sort/${myWeekBookmarkId}`,
    `practice_id=${practiceId}`,
    { pageSize: pageSize },
  )

  const { data: patientQueryCount } = useShow(
    myWeekBookmarkId && practiceId && "calendar_led_filter_and_sort_count",
    myWeekBookmarkId,
    `practice_id=${practiceId}`,
  )

  const { practiceName } = useContext(AuthorizationContext)

  useEffect(() => {
    console.dir("isErrorPatients")
    console.dir(isErrorPatients)
    // if (isErrorPatients) {
    //   localStorage.removeItem("myWeekBookmarkId")
    // }
    return () => {}
  }, [isErrorPatients])

  const [patients, setPatients] = useState([])
  const [selection, setSelection] = useState(false)
  const selectionEnabled = () => isAdmin(currentUser)
  const [modalOpen, setModalOpen] = useState<ModalType>()

  useEffect(() => {
    if (!patientsData) return

    const newPatients = patientsData.flatMap((data) => data.data)
    setPatients([...newPatients])
    return () => setPatients([])
  }, [patientsData])

  const handleScroll = () => {
    if (patients.length > 0) {
      setPagePatients(pagePatients + 1)
    }
  }
  const isLoadingPatients = () => patients.length === 0

  useEffect(() => {
    if (rowNumber !== null) {
      const rowIndex = Number.parseInt(rowNumber) // Convert the rowNumber to integer
      // Scroll to the row
      apiRef.current.scrollToIndexes({ rowIndex, colIndex: 0 })
      if (rowIndex < patients.length) {
        // make sure the rowIndex exists in the patients array
        const rowIdToExpand = patients[rowIndex].id // get the id of the row to expand
        // Expand the row
        apiRef.current.setExpandedDetailPanels([rowIdToExpand])
        // apiRef.current.setRowExpanded(rowIdToExpand, true)
      }
    }
  }, [rowNumber, apiRef, patients])

  const columnDefaults: Partial<GridColDef> = {
    width: defaultWidth,
    getApplyQuickFilterFn: undefined,
    display: "flex",
  }

  const columns: GridColDef[] = [
    {
      ...columnDefaults,
      field: "review_status",
      headerName: "Review Status",
      width: 175,
      renderCell: (params) =>
        renderCellReviewStatuses(params, currentUser?.id, mutatePatients),
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "full_name",
      headerName: "Name",
      width: 200,
      valueGetter: valueGetterFullName,
    },
    {
      ...columnDefaults,
      field: "mrn",
      headerName: "MRN",
      width: defaultWidth,
      valueGetter: valueGetterMRN,
    },
    {
      ...columnDefaults,
      field: "appointment_start_dt",
      type: "dateTime",
      headerName: "Appointment",
      width: 200,
      valueGetter: valueGetterAppointment,
      valueFormatter: valueFormatterAppointment,
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "next_appointment_location",
      headerName: "Appointment Location",
      width: 225,
      valueGetter: valueGetterAppointmentLocation,
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "referring_provider",
      headerName: "Provider",
      width: defaultWidth,
      valueGetter: valueGetterProvider,
      getApplyQuickFilterFn: undefined,
    },
    // {
    //   ...columnDefaults,
    //   field: "indication",
    //   headerName: "Indications",
    //   width: 175,
    //   renderCell: (params) => renderCellIndications(params),
    //   getApplyQuickFilterFn: undefined,
    // },
    {
      ...columnDefaults,
      field: "screen_review_status",
      headerName: "Completed Reviews",
      width: 200,
      renderCell: (params) => renderCellScreenReviewStatuses(params),
      getApplyQuickFilterFn: undefined,
    },
  ]
  const customSortableColumns = [
    "updated_at",
    "created_at",
    "next_appointment",
    "dob",
  ]
  const [quickAddOpen, setQuickAddOpen] = useState(
    searchParams.get("quick_add") === "open",
  )
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const [disableColumnFilter, setDisableColumnFilter] = useState(false)
  const navigate = useNavigate()

  const getRowHeight = useCallback(
    ({ model, densityFactor }: GridRowHeightParams) => {
      // Leaving this here as a reminder: Don't fight the framework :)
      // return model.screens.length * 20 * densityFactor + 20
      return "auto"
    },
    [],
  )

  // Init Columns from Localstorage
  useEffect(() => {
    for (const key of searchParams.keys()) {
      if (key.includes("filter_") || key.includes("sort_")) {
        searchParams.delete(key)
      }
    }

    apiRef.current.subscribeEvent("filterModelChange", handleFilterModelChange)
    apiRef.current.subscribeEvent("sortModelChange", handleSortModelChange)
    apiRef.current.subscribeEvent(
      "columnVisibilityModelChange",
      handleColumnVisibilityChange,
    )

    const newColumnVisibilityModelRaw = localStorage.getItem(
      "columnVisibilityModel",
    )
    if (newColumnVisibilityModelRaw !== null) {
      const newColumnVisibilityModel = JSON.parse(newColumnVisibilityModelRaw)
      apiRef.current.setColumnVisibilityModel(newColumnVisibilityModel)
    }
  }, [
    searchParams,
    apiRef.current.subscribeEvent,
    apiRef.current.setColumnVisibilityModel,
  ])

  // Update Columns when they change
  const handleColumnVisibilityChange: GridEventListener<
    "columnVisibilityModelChange"
  > = (
    gridVisibilityModel: GridColumnVisibilityModel,
    _event, // MuiEvent<{}>
    _details, // GridCallbackDetails
  ) =>
    localStorage.setItem(
      "columnVisibilityModel",
      JSON.stringify(gridVisibilityModel),
    )

  useEffect(() => {
    if (!filterAndSort && myWeekBookmarkId && practiceId) {
      ;(async () => {
        try {
          const { data, status } = await useGet(
            "filter_and_sort_bookmark",
            `${myWeekBookmarkId}`,
            getAccessTokenSilently,
          )
          const content = data?.data?.content
            ? JSON.parse(data?.data?.content)
            : undefined
          if (
            content.practice_id === practiceId ||
            content.practice_id === undefined
          ) {
            setFilterAndSort(content ?? content)
            setSearch(content?.search ?? "")
            content.search = undefined
          } else {
            setBookmarkPracticeId(content.practice_id)
            setModalOpen(ModalType.Bookmark)
          }
        } catch (error) {
          console.dir(error)
          setFilterAndSort(DEFAULT_FILTER_AND_SORT)
          return
        }
      })()
    }
  }, [practiceId, myWeekBookmarkId, filterAndSort, getAccessTokenSilently])

  useEffect(() => {
    if (
      myWeekBookmarkId &&
      searchParams.get("myWeekBookmarkId") !== myWeekBookmarkId &&
      isUUID(myWeekBookmarkId)
    ) {
      searchParams.set("myWeekBookmarkId", myWeekBookmarkId)
      setSearchParams(searchParams)
    }
  }, [myWeekBookmarkId, searchParams, setSearchParams])

  useEffect(() => {
    if (!practiceId) return
    if (myWeekBookmarkId && isUUID(myWeekBookmarkId)) {
      localStorage.setItem("myWeekBookmarkId", myWeekBookmarkId)
    } else {
      ;(async () => {
        console.dir("useEffectPracticeID")
        console.dir(practiceId)
        const response = await useCreate(
          "filter_and_sort_bookmark",
          {
            filter_and_sort: DEFAULT_FILTER_AND_SORT(practiceId),
          },
          getAccessTokenSilently,
        )
        setBookmarkId(response?.data?.data?.id)
      })()
    }
  }, [myWeekBookmarkId, getAccessTokenSilently, practiceId])

  useEffect(() => {
    if (!filterAndSort || !practiceId) {
      return
    }

    if (initialState) {
      const newGridFilterLinkOperator =
        filterAndSort?.filtering?.linkOperator || "and"
      const newGridSortModel: GridSortModel = filterAndSort?.sorting || []
      const newGridFilterModel: GridFilterModel = {
        items: filterAndSort?.filtering?.filters.map(
          ({ field, operator_value, value }) => ({
            id: field,
            field: field,
            operator: operator_value,
            value,
          }),
        ),
      }

      apiRef.current.setFilterModel(newGridFilterModel, "upsertFilterItems")
      apiRef.current.setSortModel(newGridSortModel)
      apiRef.current.setFilterLogicOperator(newGridFilterLinkOperator)
      setInitialState(false)
    } else {
      ;(async () => {
        const data = {
          filter_and_sort: {
            ...filterAndSort,
            search,
            practice_id: practiceId,
          },
        }
        const response = await useCreate(
          "filter_and_sort_bookmark",
          data,
          getAccessTokenSilently,
        )
        setBookmarkId(response?.data?.data?.id)
      })()
    }
  }, [
    filterAndSort,
    search,
    apiRef.current.setFilterModel,
    apiRef.current.setSortModel,
    apiRef.current.setFilterLogicOperator,
    practiceId,
    initialState,
    getAccessTokenSilently,
  ])

  const handleSortModelChange = (gridSortModel) => {
    setFilterAndSort((filterAndSort) => ({
      filtering: filterAndSort?.filtering ?? {
        filters: [],
        link_operator: "and",
      },
      sorting: gridSortModel,
    }))
  }

  const handleFilterModelChange = (gridFilterModel, details) => {
    if (details.reason === undefined || filterAndSort === undefined) return
    const filters = gridFilterModel.items?.map(({ field, operator, value }) => {
      if (field === "screens") field = "study"

      return {
        id: field,
        field: field,
        operator_value: operator,
        value,
      }
    })

    const selections = ((filterAndSort) => {
      return (
        filterAndSort?.filtering?.filters?.filter(
          (filterToCheck) =>
            ["study", "status", "assigned"].includes(filterToCheck?.field) &&
            !filters.find((filter) => filter.field === filterToCheck?.field),
        ) || []
      )
    })(filterAndSort)

    const filtering = {
      link_operator: gridFilterModel?.logicOperator || "and",
      filters: selections ? filters.concat(selections) : filters,
    }
    setFilterAndSort((filterAndSort) => ({
      sorting: filterAndSort?.sorting ?? [],
      filtering,
    }))
  }

  const handleColumnVisibilityModelChange = (
    model: GridColumnVisibilityModel,
    details: GridCallbackDetails<unknown>,
  ) => {
    setColumnVisibilityModel(model)
    localStorage.setItem("columnVisibilityModel", JSON.stringify(model))
  }

  const handleSelectionModelChange = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel)
    if (newRowSelectionModel?.length > 0) {
      setModalOpen(ModalType.BulkAssign)
    } else if (newRowSelectionModel?.length === 0) {
      setModalOpen(null)
    }
  }

  const handleAssign = (newAssignedToUserId: uuidv4) => {
    const payload = { assigned_to_user_id: newAssignedToUserId }
    // TODO: figure out how to get screen_ids from directory, only if it's filtered by study
    return useCreate(
      "bulk_requests",
      { model: "Screen", payload: payload, entities: [] },
      getAccessTokenSilently,
    )
  }

  const handleArchive = async () => {
    const archiveResponse = await useCreate(
      "bulk_patient_deletion_requests",
      { patients: rowSelectionModel },
      getAccessTokenSilently,
    )
    mutatePatients()
    return archiveResponse
  }

  const columnVisibilityModelInitial = () => {
    const columnVisibilityModelDefault = {
      // Hide these columns by default, the other columns will remain visible
      study: false,
      status: false,
      source: false,
      dob: false,
      referring_provider: false,
      phone: false,
      can_contact: false,
      next_appointment_type: false,
      last_appointment_type: false,
      contact_count_phone: false,
      preferred_language: false,
      preferred_contact_time: false,
      updated_at: false,
      created_at: false,
    }
    return localStorage.getItem("columnVisibilityModel") !== null
      ? safelyParseJSON(localStorage.getItem("columnVisibilityModel"))
      : columnVisibilityModelDefault
  }

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    columnVisibilityModelInitial(),
  )

  return (
    <>
      {isErrorPatients ? "Failed to load patients. Is your VPN connected?" : ""}
      <PatientQuickAdd
        open={quickAddOpen}
        setOpen={setQuickAddOpen}
        refreshData={mutatePatients}
      />
      {modalOpen === ModalType.Bookmark && (
        <BookmarkModal
          setOpen={setModalOpen}
          setDefaultFilterAndSort={() =>
            setFilterAndSort(DEFAULT_FILTER_AND_SORT)
          }
          changePractice={() => changePractice(bookmarkPracticeId)}
        />
      )}
      <div className="flex h-full py-5 bg-gray-100 text-black dark:bg-gray-800 dark:text-gray-100">
        <div className="flex-grow ml-3">
          <DataGridPro
            apiRef={apiRef}
            rows={patients}
            rowCount={patientQueryCount.count}
            columns={columns}
            disableDensitySelector={true}
            disableColumnFilter={false}
            getRowHeight={getRowHeight}
            initialState={{
              density: "comfortable", // contact
            }}
            filterMode="server"
            sortingMode="server"
            onFilterModelChange={handleFilterModelChange}
            onSortModelChange={handleSortModelChange}
            loading={isValidatingPatients}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
            onRowsScrollEnd={handleScroll}
            localeText={{ toolbarColumns: "Details" }}
            getDetailPanelContent={({ row }) => (
              <CalendarScreeningScreens
                screenReviews={row?.patient_appointment_screen_reviews}
                mutatePatients={mutatePatients}
              />
            )}
            onCellDoubleClick={(
              params: GridCellParams,
              event: MuiEvent<MouseEvent>,
            ) => {
              event.defaultMuiPrevented = true
              const patientPath = `/patients/${params.row.patient_id}`
              navigate(`${patientPath}/screens`)
            }}
            sx={{
              border: "none",
              height: "85%",
              width: "99%",
              "& .MuiDataGrid-columnHeaderTitle": {
                textTransform: "capitalize",
                fontFamily: "Inter",
                fontWeight: "700",
                fontSize: "12pt",
                color: "black",
              },
              ".dark & .MuiDataGrid-columnHeaderTitle": {
                color: fullConfig.theme.colors.gray[200],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "white",
                borderBottom: "none",
              },
              ".dark & .MuiDataGrid-columnHeaders": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              ".dark & .MuiDataGrid-columnHeader": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              "& .MuiDataGrid-columnHeader--moving": {
                backgroundColor: "white",
              },
              ".dark & .MuiDataGrid-columnHeader--moving": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              "& .MuiDataGrid-toolbarContainer": {
                "& .MuiButton-root": {
                  color: fullConfig.theme.colors["topo-blue"],
                },
              },
              "& .MuiButtonBase-root": {
                color: fullConfig.theme.colors.gray[700],
              },
              ".dark & .MuiButtonBase-root": {
                color: fullConfig.theme.colors.gray[100],
              },
              "&": {
                display: "flex-grow",
                height: "100%",
                justifyContent: "flex-end",
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: fullConfig.theme.colors.gray[100],
              },
              ".dark & .MuiDataGrid-row:hover": {
                backgroundColor: fullConfig.theme.colors.gray[600],
              },
              ".dark & .MuiDataGrid-filler": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              "& .MuiDataGrid-virtualScrollerContent": {
                backgroundColor: "white",
              },
              ".dark & .MuiDataGrid-virtualScrollerContent": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              ".dark & .MuiDataGrid-virtualScroller": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: "white",
                borderBottomLeftRadius: ".5rem",
                borderBottomRightRadius: ".5rem",
              },
              ".dark & .MuiDataGrid-footerContainer": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              "& .MuiDataGridPro-toolbarContainer": {
                justifyContent: "flex-end",
              },
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "4px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "7px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "7px",
              },
              "&.MuiDataGrid-filterFormValueInput": {
                backgroundColor: "red",
              },
              "& .MuiDataGridPro-cell": {
                height: "100% !important", // Make button full height
                width: "100%", // Make button full width
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
              "& .MuiDataGrid-detailPanelToggleCell": {
                height: "100% !important", // Make button full height
                width: "100%", // Make button full width
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
            slots={{
              toolbar: ToolbarCalendarScreening,
              loadingOverlay: LinearProgress,
            }}
            slotProps={{
              filterPanel: {
                sx: {
                  minWidth: "50vw",
                },
                filterFormProps: {
                  valueInputProps: {
                    sx: {
                      width: "inherit",
                    },
                  },
                },
              },
              toolbar: {
                currentUser,
                setQuickAddOpen,
                navigate,
                filtersOpen,
                setFiltersOpen,
                customSortableColumns,
                columns,
                disableColumnFilter,
                setDisableColumnFilter,
                filterAndSort,
                setFilterAndSort,
                search,
                printOptions: { disableToolbarButton: true },
                setSearch,
                isValidatingPatients,
                // printOptions: { disableToolbarButton: true },
              },
            }}
          />
        </div>
      </div>
    </>
  )
}

const sortScreens = (screens) =>
  screens
    .slice()
    .sort(
      (v1, v2) =>
        new Date(v2.updated_at).getTime() - new Date(v1.updated_at).getTime(),
    )
// .filter((screen) => screen.status && screen.status != 'not_started' && screen.status != 'disqualified')

// const valueGetterContactsPhone = (value, row, column, apiRef) =>
//   row.contact_count?.["Phone Call"]
const valueGetterFullName = (value, row, column, apiRef) => {
  const suffix = row.suffix?.length > 0 ? `, ${row.suffix}` : ""
  return `${row?.patient?.given_name} ${row?.patient?.family_name}${suffix}`
}
const valueGetterMRN = (value, row, column, apiRef) => {
  return `${row?.patient?.mrn}`
}
//TODO: There's a bug here.  return something that can be filtered on (3 item array)
const valueGetterScreens = (value, row, column, apiRef) =>
  sortScreens(row.screens)

const singleStudyView = (filters) =>
  !!filters?.find((item) => item.field === "study")?.value

export const valueGetterScreenValue = (
  value,
  row,
  column,
  apiRef,
  filterAndSort,
  screenField: string,
) => {
  if (!singleStudyView(filterAndSort?.filtering?.filters))
    return "Select a Study"
  const screenFilterValue = filterAndSort?.filtering?.filters?.find(
    (filterModelValue) => filterModelValue.field === "study",
  )?.value
  const currentlyFilteredScreens = sortScreens(row.screens)?.filter(
    (screen) =>
      !screenFilterValue || screen?.study?.abbreviation === screenFilterValue,
  )
  return currentlyFilteredScreens?.[0]?.[screenField]
}

const valueGetterAppointment = (value, row, column, apiRef) => {
  return dateTimeISOWithoutTimezone(row?.start_dt)
}

const valueFormatterAppointment = (value, row, column, apiRef) =>
  dateTimeISO(new Date(value).toJSON())

const valueGetterAppointmentLocation = (value, row, column, apiRef) =>
  row?.appointment_location

const valueGetterProvider = (value, row, column, apiRef) =>
  row?.patient?.referring_provider

const renderCellIndications = (params: GridCellParams) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const indications = [
    ...new Set(
      params?.row?.patient_appointment_screen_reviews?.map(
        (review) => review?.study?.indication,
      ),
    ),
  ]

  return (
    <div className="flex-col w-full align-baseline">
      {(isExpanded || indications.length <= 2) &&
        indications.map((indication) => (
          <span
            className="bg-green-100 text-green-800 text-xs font-medium m-1 px-2.5 py-0.5 rounded-md block"
            key={indication}
          >
            {indication}
          </span>
        ))}
      {!isExpanded && indications.length > 2 && (
        <Tooltip content={indications.join(", ")} placement="right">
          <span
            className="bg-green-100 text-green-800 text-xs font-medium m-1 px-2.5 py-0.5 rounded-md block cursor-pointer"
            onClick={() => setIsExpanded(true)}
            onKeyUp={() => setIsExpanded(true)}
          >
            2+
          </span>
        </Tooltip>
      )}
    </div>
  )
}
const renderCellScreenReviewStatuses = (params: GridCellParams) => {
  const reviewStatuses =
    params?.row?.patient_appointment_screen_reviews?.map(
      (patient_appointment_screen_review) =>
        patient_appointment_screen_review?.status,
    ) || [] // Added default case

  const totalCount = reviewStatuses.length

  const reviewedCount = reviewStatuses.filter(
    (status) => status === "reviewed",
  ).length

  // Avoid division by zero
  const percentReviewed =
    totalCount > 0 ? Math.round((reviewedCount / totalCount) * 100) : 0

  return (
    <Tooltip
      content="The number of screens that have been reviewed for this patient's upcoming appointment."
      className="z-50"
      placement="left"
      theme={TooltipTheme}
    >
      <div className="w-full">
        <div className="w-full align-baseline">
          <div className="flex justify-between mb-1">
            <span className="text-base font-sm text-gray-700 dark:text-white">
              {`${reviewedCount} of ${totalCount}`}
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div
              className="bg-blue-600 h-2.5 rounded-full"
              style={{ width: `${percentReviewed}%` }}
            />
          </div>
        </div>
      </div>
    </Tooltip>
  )
}
const renderCellReviewStatuses = (
  params: GridCellParams,
  currentUserId,
  mutatePatients,
) => {
  const { getAccessTokenSilently } = useAuth0()
  const statusIsReviewed = params?.row?.review_status === "reviewed"
  const statusToDisplay = statusIsReviewed ? "reviewed" : "unreviewed"
  const statusToUpdate = statusIsReviewed ? "unreviewed" : "reviewed"
  const badgeColor = statusIsReviewed ? "success" : "failure"

  const reviewStatuses =
    params?.row?.patient_appointment_screen_reviews?.map(
      (patient_appointment_screen_review) =>
        patient_appointment_screen_review?.status,
    ) || [] // Added default case

  const isReviewed = statusToDisplay === "reviewed"
  const totalCount = reviewStatuses.length

  const reviewedCount = reviewStatuses.filter(
    (status) => status === "reviewed",
  ).length

  // Avoid division by zero
  const percentReviewed =
    totalCount > 0 ? (reviewedCount / totalCount) * 100 : 0
  const disableToggle =
    reviewedCount !== totalCount && !isReviewed && totalCount !== 0

  const onToggle = () => {
    useUpdate(
      "patient_appointments",
      params?.row?.id,
      {
        review_status: statusToUpdate,
        review_dt: new Date().toISOString().slice(0, -1),
        reviewed_by_user_id: currentUserId,
      },
      getAccessTokenSilently,
    ).then(() => {
      mutatePatients()
    })
  }
  return (
    <>
      <div className="flex justify-between w-full">
        <Tooltip
          placement="left"
          content="Click to toggle review status. This will not change if there are unreviewed screens."
          className="z-50 relative"
          theme={TooltipTheme}
        >
          <ToggleSwitch
            checked={isReviewed}
            onChange={() => onToggle()}
            theme={ToggleSwitchTheme}
            disabled={disableToggle || false}
          />
        </Tooltip>
        <span
          className={`${
            badgeColor === "success"
              ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300"
              : "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300"
          } text-xs font-medium me-2 px-2.5 py-0.2 rounded flex justify-center items-center`}
        >
          {statusToDisplay === "reviewed" ? "Reviewed" : "Unreviewed"}
        </span>
      </div>
    </>
  )
}
