import { useAuth0 } from "@auth0/auth0-react"

import { snakeCase } from "change-case-all"
import { type Dispatch, type SetStateAction, useState } from "react"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"

import { type QueryClient, useQueryClient } from "@tanstack/react-query"
import { Tooltip } from "flowbite-react"
import { HeroIcon } from "../../../../components/hero-icon"
import SubmitButton from "../../../../components/submit-button"
import { useCreate } from "../../../../shared/use-rest"
import { useIndexQuery } from "../../../../shared/use-rest-query"
import { useKeyPress } from "../../../../shared/useKeyPress"

interface Props {
  userId: string
  setOpen: Dispatch<SetStateAction<boolean>>
  refreshData: QueryClient["invalidateQueries"]
}

export function PracticeUserNewModal(props: Props) {
  const { userId } = useParams()

  const {
    data: { data: practice_users },
    isFetching,
    isError,
  } = useIndexQuery("admin/practice_users", `user_id=${userId}`)
  const queryClient = useQueryClient()
  const mutatePracticeUsers = () => {
    queryClient.invalidateQueries({
      queryKey: ["admin/practice_users", `user_id=${userId}`],
    })
  }
  const [isLoading, setIsLoading] = useState(false)

  const {
    data: { data: practices },
  } = useIndexQuery("admin/practices")
  const {
    data: { data: practiceUsers },
  } = useIndexQuery(`admin/practice_users?user_id=${userId}`)

  const practiceOptions = practices
    .map((practice) => {
      if (
        !practice_users.some(
          (practiceUser) => practiceUser.practice_id === practice.id,
        )
      ) {
        return {
          key: practice.id,
          label: `${practice.name} (${practice.abbreviation})`,
        }
      }
      return {
        key: practice.id,
        label: `${practice.name} (${practice.abbreviation})`,
        disabled: true,
      }
    })
    .filter((practice) => practice !== undefined)

  const { register, handleSubmit, reset } = useForm({
    defaultValues: { user_id: props.userId },
  })
  const { getAccessTokenSilently } = useAuth0()

  const onKeyPress = (event) => {
    props.setOpen(false)
  }

  useKeyPress(["Escape"], onKeyPress)

  const onSubmit = (data) => {
    setIsLoading(true)
    data.user_id = userId
    useCreate("admin/practice_users", data, getAccessTokenSilently).then(
      (response) => {
        props.setOpen(false)
        props?.refreshData()
        reset()
      },
    )
    setIsLoading(false)
  }

  const entity = {
    label: "Practice User",
    key: "practice_user",
  }
  const fields = [
    {
      label: "Practice",
      key: "practice_id",
      required: true,
      options: practiceOptions,
    },
    {
      label: "Role",
      key: "role",
      options: [
        {
          label: "User",
          key: "User",
          description: "Access to the site's Waypoints.",
        },
        {
          label: "QA User",
          key: "QaUser",
          description:
            "User permissions and able to perform QA on other user's charts in Waypoints.",
        },
        {
          label: "Manager",
          key: "Manager",
          description:
            "View and manage all User metrics and QA responses in Waypoints.",
        },
        {
          label: "Study Admin",
          key: "StudyAdmin",
          description: "View and manage all Studies across sites in Waypoints.",
        },
        {
          label: "Admin",
          key: "Admin",
          description: "Ability to manage an entire site in Waypoints.",
        },
        {
          label: "Provider",
          key: "Provider",
          description: "Ability to view the site in Provider Dashboard.",
        },
        {
          label: "Provider Admin",
          key: "ProviderAdmin",
          description:
            "Ability to view and manage the site in Provider Dashboard.",
        },
      ],
    },
    {
      label: "Position",
      key: "position",
      tooltip: "This is the position of the user with respect to the practice.",
      role: true,
    },
    { label: "Phone", key: "phone" },
    { label: "Email", key: "email" },
  ]

  return (
    <>
      <div
        className="fixed flex left-0 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full"
        id={`new-${snakeCase(entity.key)}-modal`}
      >
        <div className="relative w-full h-full max-w-2xl px-4 md:h-auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <!-- Modal content --> */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
              {/* <!-- Modal header --> */}
              <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
                <h3 className="text-xl font-semibold dark:text-white">
                  New {entity.label}
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                  onClick={() => props.setOpen(false)}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>New {entity.label}</title>
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              {/* <!-- Modal body --> */}
              <div className="p-6 space-y-6">
                <div className="grid grid-cols-6 gap-6">
                  {fields.map((field) => (
                    <div key={field.key} className="col-span-6 sm:col-span-6">
                      <div className="inline-flex">
                        <label
                          htmlFor={field.key}
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          {field.label}
                        </label>
                        {field.tooltip && (
                          <Tooltip content={field.tooltip}>
                            <HeroIcon
                              icon="QuestionMarkCircleIcon"
                              className="h-4 ml-2 rounded-md"
                            />
                          </Tooltip>
                        )}
                      </div>
                      {!field.options && (
                        <input
                          {...register(field.key)}
                          type="text"
                          name={field.key}
                          id={field.key}
                          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      )}
                      {field.options && (
                        <select
                          {...register(field.key)}
                          name={field.key}
                          id={field.key}
                          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option key="default" value="" selected disabled>
                            Select an Option
                          </option>
                          {field.options.map((option) => (
                            <option
                              key={option?.label}
                              value={option?.key}
                              disabled={option?.disabled || false}
                            >
                              {option?.index}
                              {option?.label}{" "}
                              {option?.description &&
                                `- ${option?.description}`}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  ))}
                </div>
                {/* <!-- Modal footer --> */}
                <div className="items-center pt-4 border-t border-gray-200 rounded-b dark:border-gray-700">
                  {isLoading ? (
                    <SubmitButton />
                  ) : (
                    <button
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      type="submit"
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        onClick={() => props.setOpen(false)}
        onKeyUp={() => props.setOpen(false)}
        id="modal-backdrop"
        className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
      />
    </>
  )
}
