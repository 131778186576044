import { createContext, useContext } from "react"
import { useSearchParams } from "react-router-dom"

export const FeatureFlagContext = createContext([])

export const featureFlagEnabled = (flag: string): boolean => {
  const featureFlagContext = useContext(FeatureFlagContext)
  return featureFlagContext.includes(flag)
}

export const FeatureFlagProvider = ({ children }) => {
  let featureFlags = JSON.parse(localStorage.getItem("featureFlags")) || []

  const [searchParams, setSearchParams] = useSearchParams()
  const newFeatureFlag = searchParams.get("featureFlag")

  if (newFeatureFlag && !featureFlags.includes(newFeatureFlag)) {
    featureFlags = [...featureFlags, newFeatureFlag]
    localStorage.setItem("featureFlags", JSON.stringify(featureFlags))
  }

  if (searchParams.get("featureFlagReset")) {
    localStorage.removeItem("featureFlags")
    featureFlags = []
  }

  return (
    <FeatureFlagContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagContext.Provider>
  )
}

export const messagingEnabled = (practiceName) => {
  return (
    (featureFlagEnabled("messaging") &&
      ["Woodholme", "Huron Gastro"].includes(practiceName)) ||
    process.env.ENV === "dev"
  )
}
