import { LicenseInfo } from "@mui/x-license"
import * as Sentry from "@sentry/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { useEffect } from "react"
import {
  RouterProvider,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"
import { appRouter } from "./app-router"
import { AppWrapper } from "./app-wrapper"
import { Auth0ProviderCustom } from "./shared/auth0-provider-custom"
import { AuthorizationProvider } from "./shared/authorization-provider"
import { muiCustomTheme } from "./shared/mui-custom-theme"
import { muiPublicLicenseKey } from "./shared/mui-public-license-key"
import { usePrefersDarkMode } from "./shared/use-prefers-dark-mode"

export function App() {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    // Sentry recommends adjusting this value in production,
    // or using tracesSampler for finer control
    tracesSampleRate: Number.parseFloat(
      process.env.SENTRY_TRACES_SAMPLE_RATE || "1.0",
    ),
  })

  // TODO: Move this to Authorization Provider, into a useEffect [] (init), import * as Sentry from "@sentry/react"
  // TODO: Slugify the practice name that comes back from the /authorizations response for the practice_slug
  Sentry.setContext("practice", {
    practice_id: process.env.PRACTICE_ID,
    practice_slug: process.env.PRACTICE_SLUG,
  })

  LicenseInfo.setLicenseKey(muiPublicLicenseKey)

  const prefersDarkMode = usePrefersDarkMode()
  const theme = muiCustomTheme(prefersDarkMode)
  const queryClient = new QueryClient()

  return (
    <>
      <Auth0ProviderCustom>
        <AuthorizationProvider>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={appRouter}>
              <AppWrapper />
            </RouterProvider>
          </QueryClientProvider>
        </AuthorizationProvider>
      </Auth0ProviderCustom>
    </>
  )
}
