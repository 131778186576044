import { Button } from '../../components/button'

export function Tasks() {
  return (
    <>
      <div className="px-4 sm:px-6 md:px-0 mb-2">
        <h1 className="text-2xl font-semibold text-gray-900">Tasks</h1>
      </div>
      <h3>🚧 Coming soon 🚧</h3>
      {/* <div className='mb-2'><Button>Assign New Task</Button></div>
      <div className='mb-2'><Button>View All Tasks</Button></div>
      <div className='mb-2'><a href="/tasks">Task Metrics</a></div>
      <div className='mb-2'><a href="/tasks">Task Templates</a></div> */}
    </>
  )
}
