import { createTheme, useMediaQuery } from "@mui/material";
import type {} from '@mui/x-date-pickers/themeAugmentation'
import { useEffect, useMemo, useState } from "react";

export function muiCustomTheme (prefersDarkMode) {
  return useMemo(() => createTheme({
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
    },
    components: {
      MuiInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              input: {
                "box-shadow": "none"
              }
            }
          }
        }
      },
      MuiDatePicker: {
        styleOverrides: {
          root: {
            // backgroundColor: 'red',
          },
        },
      },
    },
    typography: {
      fontFamily: [
        '"Inter var"',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        "Helvetica Neue",
        'Arial',
        '"Noto Sans"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ].join(','),
    },
  }), [prefersDarkMode])
}
