import { useEffect, useState } from "react"

const localStorageSnow = () =>
  "snow" in localStorage && localStorage.snow === "true"

export function usePrefersSnow() {
  const [prefersSnow, setPrefersSnow] = useState(localStorageSnow())

  useEffect(() => {
    function handleChangeStorage() {
      setPrefersSnow(localStorageSnow())
    }

    window.addEventListener("storage", handleChangeStorage)
    return () => window.removeEventListener("storage", handleChangeStorage)
  }, [])

  return prefersSnow
}
