import LinearProgress from "@mui/material/LinearProgress"
import {
  DataGridPro,
  type GridCellParams,
  type GridColDef,
  type MuiEvent,
} from "@mui/x-data-grid-pro"
import { useNavigate } from "react-router-dom"
import type { Props } from "react-select"
import { HeroIcon } from "../../components/hero-icon"
import { dateTimeISO } from "../../shared/friendly-dates"

export function MessagesTable(props: Props) {
  const navigate = useNavigate()
  const columnDefaults: Partial<GridColDef> = {
    width: 150,
    headerClassName: "bg-gray-100 text-gray-500",
    display: "flex",
  }

  const valueFormatterDate = (value, row, column, apiRef) =>
    dateTimeISO(new Date(value).toJSON())

  const handleOpenMessage = (patientId) => {
    props.setPatientId(patientId)
    props.setChatDrawerIsOpen(true)
  }
  const columns: GridColDef[] = [
    {
      field: "given_name",
      headerName: "First Name",
      ...columnDefaults,
      width: 225,
    },
    {
      field: "family_name",
      headerName: "Last Name",
      ...columnDefaults,
    },
    {
      field: "next_visit",
      headerName: "Next Visit",
      ...columnDefaults,
    },
    {
      field: "last_message_delivered_dt",
      headerName: "Last Message Date/Time",
      valueFormatter: valueFormatterDate,
      ...columnDefaults,
      width: 215,
    },
    {
      field: "outbound",
      headerName: "Last Message Direction",
      ...columnDefaults,
      width: 200,
      renderCell: (params) => (
        <div
        // className={classNames(
        //   params.row.outbound
        //     ? "text-green-500"
        //     : "text-red-500 font-semibold",
        // )}
        >
          {params.row.outbound ? "Outbound" : "Inbound"}
        </div>
      ),
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      ...columnDefaults,
    },
    {
      field: "actions",
      headerName: "Actions",
      ...columnDefaults,
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => renderCellActions(params, handleOpenMessage),
    },
  ]

  const renderCellActions = (props: GridCellParams, handleOpenMessage) => {
    return (
      <button
        type="button"
        onClick={() => handleOpenMessage(props.row.patient_id)}
        className="bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 text-white text-xs font-medium mr-6 px-2.5 py-1.5 rounded-md flex items-center justify-between"
      >
        Open
        <HeroIcon icon="ArrowCircleRightIcon" className="ml-1 w-5 h-5 flex" />
      </button>
    )
  }

  return (
    <div className="flex h-screen">
      <div className="flex-grow">
        <DataGridPro
          rows={props.messages}
          getRowId={(row) => row.patient_id + row.phone_number}
          columns={columns}
          pagination
          disableDensitySelector={true}
          loading={props.isLoading}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          onCellDoubleClick={(
            params: GridCellParams,
            event: MuiEvent<MouseEvent>,
          ) => {
            event.defaultMuiPrevented = true
            navigate(`/patients/${params.row.patient_id}`)
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
            filter: {
              filterModel: {
                items: [],
              },
            },
            sorting: {
              sortModel: [{ field: "last_message_delivered_dt", sort: "desc" }],
            },
            columns: {
              columnVisibilityModel: {
                // Hide these columns by default, the other columns will remain visible
                indications: false,
              },
            },
            density: "comfortable", // contact
          }}
          sx={{
            "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            ".dark & .MuiDataGrid-columnHeader": {
              backgroundColor: "rgb(55 65 81)",
              color: "#FFFFFF",
            },
          }}
        />
      </div>
    </div>
  )
}
