import {
  DataGridPro,
  type GridCellParams,
  type GridColDef,
  type GridRowsProp,
  type MuiEvent,
} from "@mui/x-data-grid-pro"
import { parseISO } from "date-fns"
import { type MouseEvent, useContext, useState } from "react"
import { ActionHeader } from "../../components/action-header"
import { ButtonLeadingIcon } from "../../components/button-leading-icon"
import { AuthorizationContext } from "../../shared/authorization-provider"
import { useIndex } from "../../shared/use-rest"
import { AppointmentFinderModal } from "./appointment-finder-modal"
import { UploadNewModal } from "./new-modal"
import { PatientFinderModal } from "./patient-finder-modal"
import { UploadShowModal } from "./show-modal"

export function Uploads() {
  const { practiceId } = useContext(AuthorizationContext)
  const { data, isLoading, isError, mutate } = useIndex("uploads")
  const [newModalOpen, setNewModalOpen] = useState(false)
  const [showModalOpen, setShowModalOpen] = useState(false)
  const [patientFinderModalOpen, setPatientFinderModalOpen] = useState(false)
  const [appointmentFinderModalOpen, setAppointmentFinderModalOpen] =
    useState(false)
  const [uploadId, setUploadId] = useState("")

  const dateValueGetter = (value, row, column, apiRef) => parseISO(value)
  const rows: GridRowsProp = data
  const columns: GridColDef[] = [
    { field: "upload_type", headerName: "Type", width: 160 },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "created_by_user_id",
      headerName: "Created By User Id",
      width: 150,
    },
    { field: "created_count", headerName: "Created", width: 100 },
    { field: "skipped_count", headerName: "Skipped", width: 100 },
    { field: "duplicate_count", headerName: "Duplicate", width: 100 },
    { field: "failed_count", headerName: "Failed", width: 100 },
    // { field: 's3_bucket', headerName: 'S3 Bucket', width: 150 },
    // { field: 's3_key', headerName: 'S3 Key', width: 150 },
    {
      field: "updated_at",
      type: "dateTime",
      valueGetter: dateValueGetter,
      headerName: "Updated At",
      width: 250,
    },
    {
      field: "uploaded_at",
      type: "dateTime",
      valueGetter: dateValueGetter,
      headerName: "Uploaded At",
      width: 250,
    },
  ]

  return (
    <>
      <ActionHeader title="Uploads">
        <div className="flex space-x-2">
          <div className="" onClick={() => mutate()} onKeyUp={() => mutate()}>
            <ButtonLeadingIcon
              bgColorClass="bg-topo-blue"
              bgHoverColorClass="hover:bg-blue-400"
              focusRingColorClass="focus:ring-topo-blue-500"
              icon="RefreshIcon"
              text="Refresher™️"
            />
          </div>
          <div
            className=""
            onClick={() => setPatientFinderModalOpen(true)}
            onKeyUp={() => setPatientFinderModalOpen(true)}
          >
            <ButtonLeadingIcon
              bgColorClass="bg-cyan-500"
              bgHoverColorClass="hover:bg-cyan-800"
              focusRingColorClass="focus:ring-cyan-500"
              icon="DatabaseIcon"
              text={"PatientFinder™️"}
            />
          </div>
          <div
            className=""
            onClick={() => setAppointmentFinderModalOpen(true)}
            onKeyUp={() => setAppointmentFinderModalOpen(true)}
          >
            <ButtonLeadingIcon
              bgColorClass="bg-orange-500"
              bgHoverColorClass="hover:bg-orange-700"
              focusRingColorClass="focus:ring-orange-500"
              icon="CalendarIcon"
              text={"AppointmentFinder™️"}
            />
          </div>
          <div
            className=""
            onClick={() => setNewModalOpen(true)}
            onKeyUp={() => setNewModalOpen(true)}
          >
            <ButtonLeadingIcon icon="DatabaseIcon" text="CSV Uploader™️" />
          </div>
        </div>
      </ActionHeader>
      {isLoading ? "Loading.." : ""}
      {isError ? "Failed to load" : ""}
      <div className="p-2 mt-2 flex h-screen">
        <div className="flex-grow">
          <DataGridPro
            rows={rows}
            columns={columns}
            initialState={{
              sorting: {
                sortModel: [{ field: "updated_at", sort: "desc" }],
              },
              filter: {
                filterModel: {
                  items: [
                    {
                      field: "status",
                      operator: "isAnyOf",
                      value: ["new", "complete", "processing"],
                    },
                  ],
                },
              },
            }}
            onCellDoubleClick={(
              params: GridCellParams,
              event: MuiEvent<MouseEvent>,
            ) => {
              event.defaultMuiPrevented = true
              setUploadId(params.row.id)
              setShowModalOpen(true)
            }}
          />
        </div>
      </div>
      {newModalOpen && <UploadNewModal setOpen={setNewModalOpen} />}
      {showModalOpen && uploadId && (
        <UploadShowModal uploadId={uploadId} setOpen={setShowModalOpen} />
      )}
      {patientFinderModalOpen && (
        <PatientFinderModal setOpen={setPatientFinderModalOpen} />
      )}
      {appointmentFinderModalOpen && (
        <AppointmentFinderModal setOpen={setAppointmentFinderModalOpen} />
      )}
    </>
  )
}
