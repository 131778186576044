import { Menu, Transition } from "@headlessui/react"
import { Fragment, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { AuthorizationContext } from "../shared/authorization-provider"
import { classNames } from "../shared/class-names"
import { HeroIcon } from "./hero-icon"

export function TenantSelector() {
  const { authorizedPractices, changePractice, practiceName, practiceId } =
    useContext(AuthorizationContext)

  const navigate = useNavigate()

  const selectPractice = async (practiceId) => {
    await changePractice(practiceId)
    navigate("/home")
  }

  // TODO: Move this to authzprovider?
  const singleTenant =
    process.env.PRACTICE_ID || authorizedPractices.length <= 1

  if (singleTenant)
    return (
      <div
        className="text-sm font-medium pr-2 text-gray-700 dark:text-gray-200 cursor-default"
        title={practiceId}
      >
        {practiceName}
      </div>
    )

  return (
    <Menu as="div" className="relative inline-block text-left ml-4">
      <div>
        <Menu.Button className="flex items-center rounded-full hover:cursor-pointer text-gray-700 dark:text-gray-300 hover:text-gray-600 focus:outline-none active:ring-0 active:text-gray-800 active:ring-offset-0 active:ring-offset-gray-100">
          <span className="sr-only">Open options</span>
          <div className="text-sm font-medium pr-2" title={practiceName}>
            {practiceName}
          </div>
          <HeroIcon
            icon="SwitchHorizontalIcon"
            className="h-5 w-5"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 w-56 rounded-md bg-white shadow-lg  focus:outline-none">
          <div className="py-1">
            {!singleTenant &&
              authorizedPractices?.map((practice) => (
                <Menu.Item key={practice?.id}>
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={() => selectPractice(practice?.id)}
                      onKeyDown={() => selectPractice(practice?.id)}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm w-full text-left",
                      )}
                    >
                      {practice?.name}
                    </button>
                  )}
                </Menu.Item>
              ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
