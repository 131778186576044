import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"
import { useEffect } from "react"

export const ProtectedRoute = ({ component }) => {
  const { user, error, logout } = useAuth0()
  useEffect(() => {
    if (error) {
      localStorage.removeItem("practiceId")
      logout()
    }
  }, [error, logout])

  useEffect(() => {
    if (user) {
    }
  }, [user])

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="p-4 text-bold dark:text-gray-200">Authorizing...</div>
    ),
  })

  return <Component />
}
